import { Object3D, Mesh, Vector2, Vector3, Raycaster, FrontSide, DoubleSide, Material, Box3, Line3, BufferAttribute } from "three";
import { Line2 } from "../math/Line2";
import * as slugid from "slugid";
import { getObjectParameters, setObjectParameters, objectHasParameter } from "@/three/ThreeJsHelpers";
import { PlanarSurface, SURFACE_TYPE } from "../objects/PlanarSurface";
import { OpeningGizmo } from "../objects/OpeningGizmo";
import { Apartment } from "./Apartment";
import { Room } from "./Room";

const RAYCAST_DISTANCE_LIMIT = 1.5;
const NEW_INSIDE_WALL_DEPTH = 0.05;

export enum OPENING_TYPE {
  door = "door",
  window = "window",
  opening = "opening",
}

export class Opening extends Object3D {
  public sourceMesh?: Mesh;
  public gizmos: OpeningGizmo[] = [];

  public isEntrance = false;

  private _boundsBySurfaceId: Map<string, Line2[]> = new Map<string, Line2[]>();

  public openingType: OPENING_TYPE = OPENING_TYPE.opening;
  readonly type = "Opening";
  public guid = slugid.v4();
  public globalId = "";
  public doorType?: string;
  public operationType?: string;
  public doorOpen?: string;
  public doorAngle?: number;

  private _traversableRoomsTotal = -1;
  /** The amount of rooms that can be accessed via this opening from the point of view of the apartment entrance. */
  public get traversableRoomsTotal(): number { return this._traversableRoomsTotal }
  public set traversableRoomsTotal(value: number) {
    this._traversableRoomsTotal = value;
    const openingParameters = getObjectParameters(this);
    openingParameters.set("traversableRoomsTotal", value.toString());
    setObjectParameters(openingParameters, this);
  }

  public get openingsIsTraversable(): boolean {
    return this.openingType == OPENING_TYPE.door || this.openingType == OPENING_TYPE.opening
  }

  public isExterior = false;
  public isBalcony = false;
  public source?: string;

  public spaceIds: string[] = [];
  public surfaceIds: string[] = [];

  private _openingPolygon?: Mesh;
  public get openingPolygon(): Mesh | undefined { return this._openingPolygon }

  public rooms: Room[] = [];

  /** surfaces that are being cut by this opening. */
  readonly linkedSurfacesByIds: Map<number, PlanarSurface> = new Map();

  /** surfaces that are tangent to this opening i.e. connected by the sides to this opening if it isn't cased. */
  private _tangentialSurfaces: PlanarSurface[] = [];

  public get tangentialSurfaces(): PlanarSurface[] {
    return [...this._tangentialSurfaces]
  }

  public getBounds(margin = 0.0001): Box3 {
    const scaleWithMargin = this.scale.clone().addScalar(margin * 0.5);
    const minCorner = this.position.clone().add(scaleWithMargin.clone().multiplyScalar(-0.5));
    const maxCorner = this.position.clone().add(scaleWithMargin.multiplyScalar(0.5));
    return new Box3(minCorner, maxCorner);
  }

  public get UVsTraverseOpening(): boolean {
    if (this.openingType == OPENING_TYPE.window && this.operationType != "NOTDEFINED") return false;
    if (this.openingType == OPENING_TYPE.door && this.doorType != "none") return false;

    return true;
  }

  public getBoundsBySurface(surface: PlanarSurface): Line2[] {
    const guid = surface.guid;
    return this._boundsBySurfaceId.has(guid) ? this._boundsBySurfaceId.get(guid)! : ([] as Line2[]);
  }

  private _setBoundsFromSurface(newSurface: PlanarSurface) {
    let newBounds = this.getBoundsBySurface(newSurface);
    newBounds = newSurface.addOutlineToOpeningBounds(newSurface.worldOutline, newBounds);
    this._boundsBySurfaceId.set(newSurface.guid, newBounds);

    const linkedSurfaces = [...this.linkedSurfacesByIds.values()];

    linkedSurfaces.forEach((thisSurface) => {
      linkedSurfaces.forEach((thatSurface) => {
        if (thisSurface.id != thatSurface.id) {
          let theseBounds = this.getBoundsBySurface(thisSurface);
          theseBounds = thisSurface.addOutlineToOpeningBounds(thatSurface.worldOutline, theseBounds);

          this._boundsBySurfaceId.set(thisSurface.guid, theseBounds);
        }
      });
    });
  }

  public addRoom(room: Room) {
    this.rooms.push(room);
    this.rooms = [...new Set(this.rooms)];
  }

  public removeRoom(room: Room) {
    if (!this.rooms.includes(room)) return;
    this.rooms = this.rooms.filter((rm) => rm !== room);
  }

  public getRooms(): Room[] {
    return this.rooms;
  }

  private setSurfaceIds(surfaceIds: string[]) {
    this.surfaceIds = this.surfaceIds.concat(surfaceIds);
    this.surfaceIds = [...new Set(this.surfaceIds)];

    const openingParameters = getObjectParameters(this);
    openingParameters.set("surfaceIds", `['${this.surfaceIds.join("', '")}']`);
    setObjectParameters(openingParameters, this);
  }

  private setSpaceIds(spaceIds: string[]) {
    this.spaceIds = this.spaceIds.concat(spaceIds);
    this.spaceIds = [...new Set(this.spaceIds)];

    const openingParameters = getObjectParameters(this);
    openingParameters.set("spaceIds", `['${this.spaceIds.join("', '")}']`);
    setObjectParameters(openingParameters, this);
  }

  public isSurfaceLinked(surface: PlanarSurface): boolean {
    return this.linkedSurfacesByIds.has(surface.id);
  }

  public getLinkedSurfaces(): PlanarSurface[] {
    return [...this.linkedSurfacesByIds.values()];
  }

  private linkSurface(surface: PlanarSurface) {
    if (this.isSurfaceLinked(surface)) {
      return;
    }

    this.setSpaceIds([surface.room.roomId])
    this.linkedSurfacesByIds.set(surface.id, surface);
    this.setSurfaceIds([surface.guid]);
    this._setBoundsFromSurface(surface);
    this._addToLinkedApartments(surface.apartment);
  }

  public containsPoint(point: Vector3): boolean {
    return this.getBounds().containsPoint(point);
  }

  public edgeIsCutByOpening(line: Line3, margin = 0.035): boolean {

    const lineDirection = line.delta(new Vector3()).normalize();
    const openingForward = this.getWorldDirection(new Vector3());
    const product = Math.abs(lineDirection.dot(openingForward))

    const isPerpendicular = product < margin;

    if (!isPerpendicular) return false;

    const extentValue = 0.5 + margin;
    const lineCenterInLocalSpace = this.worldToLocal(line.getCenter(new Vector3()));

    const vertPos = Math.abs(lineCenterInLocalSpace.y);
    if (vertPos > extentValue) {
      return false;
    }

    const horPos = Math.abs(lineCenterInLocalSpace.x);
    if (horPos > extentValue) {
      return false;
    }

    const depthPos = Math.abs(lineCenterInLocalSpace.z);
    if (depthPos > extentValue) {
      return false;
    }

    const localStart = this.worldToLocal(line.start.clone());
    const localEnd = this.worldToLocal(line.end.clone());

    const localLength = localStart.distanceTo(localEnd);
    const isSameLength = Math.abs(localLength - 1) < margin;

    if (isSameLength) {
      return true;
    }

    return false;
  }

  private _linkedApartments: Apartment[] = [];

  public getLinkedApartments(): Apartment[] {
    return this._linkedApartments;
  }

  private _addToLinkedApartments(apartment: Apartment) {
    this._linkedApartments.push(apartment);
    this._linkedApartments = [...new Set(this._linkedApartments)];
  }

  private constructor(apartment: Apartment) {
    super();

    apartment.openingsNode.add(this);
    if (!apartment.openings.includes(this)) {
      apartment.openings.push(this);
    }
  }

  public static replaceNode(node: Object3D, apartment: Apartment): Opening {

    const nodeParameters = getObjectParameters(node);
    const opening = new Opening(apartment);
    const openingPolygon = node.children.find((child) => objectHasParameter(child, "type", "OpeningPolygon"));
    if (openingPolygon && openingPolygon instanceof Mesh) {
      opening.add(openingPolygon);
      opening._openingPolygon = openingPolygon as Mesh;
      openingPolygon.visible = false;
    }

    nodeParameters.forEach((value, key) => {
      if (key == "surfaceIds") {
        nodeParameters.delete(key);
      }
    });

    opening._setOpeningParameters(nodeParameters);
    opening.rotation.copy(node.rotation);
    opening.position.copy(node.position);
    opening.scale.copy(node.scale);
    opening.scale.z = node.scale.z < 0.0001 ? 0.0001 : node.scale.z;
    opening.updateMatrixWorld();

    const nodeParent = node.parent;
    node.removeFromParent();

    nodeParent?.add(opening);
    return opening;
  }

  public static fromPositionAndDirections(
    position: Vector3,
    normal: Vector3,
    bitangent: Vector3,
    apartment: Apartment,
    depth = NEW_INSIDE_WALL_DEPTH
  ): Opening {
    const opening = new Opening(apartment);
    opening.openingType = OPENING_TYPE.opening;

    opening.globalId = slugid.v4();
    opening._setOpeningParameters();

    const insideWallPosition = position.clone().add(normal.clone().multiplyScalar(-depth));

    opening.position.copy(insideWallPosition);
    opening.scale.set(0.01, 0.01, 1);
    opening.quaternion.lookRotation(normal, bitangent);

    opening.updateMatrixWorld();

    return opening;
  }

  public assignTraversableRooms(excludeRooms: Room[] = []): void {

    // TODO: Fix bug where two openings are connected by a single surface and the traversable rooms total is incorrect

    if (!this.openingsIsTraversable) return;

    this.traversableRoomsTotal = this.getTraversableRooms(excludeRooms).length;

    const openingRooms = this.getRooms().filter((room) => !excludeRooms.includes(room))

    const newExcludeRooms = [...new Set(excludeRooms.concat(openingRooms))];

    const connectedOpenings = openingRooms.flatMap((room) => room.openings).filter((opening) => opening !== this);

    connectedOpenings.forEach((opening) => {
      opening.assignTraversableRooms(newExcludeRooms);
    });
  }

  private getTraversableRooms(excludeRooms: Room[] = [], traversedRooms: Room[] = []): Room[] {

    if (!this.openingsIsTraversable) return [];

    const untraversedRooms = this.getRooms().filter((room) => !traversedRooms.includes(room) && !excludeRooms.includes(room));
    if (!untraversedRooms.length) {
      return traversedRooms;
    }

    traversedRooms.push(...untraversedRooms)

    const untraversedOpenings = [...new Set(untraversedRooms.flatMap((room) => room.openings).filter((opening) => opening !== this))];

    untraversedOpenings.forEach((opening) => {
      traversedRooms.push(...opening.getTraversableRooms(excludeRooms, traversedRooms));
    });

    return [...new Set(traversedRooms)];
  }

  public dispose() {
    const linkedSurfaces = [...this.linkedSurfacesByIds.values()];

    this._linkedApartments.forEach((apartment) => {
      apartment.openings = apartment.openings.filter((opening) => opening !== this);
    });

    linkedSurfaces.forEach((surface) => {
      surface.removeOpening(this);
    });

    this._removeOpeningFromTangentialSurfaces();

    this.sourceMesh?.geometry.dispose();
    this.sourceMesh?.parent?.remove(this.sourceMesh);
    this.gizmos.forEach((gizmo) => {
      gizmo.dispose();
      this.remove(gizmo);
    });
    this.sourceMesh = undefined;
    this.gizmos = [];


    this.updateLinkedSurfaces();

    this.clearMoveEvents();

    this.parent?.remove(this);
  }

  public edgesOnSurface(surface: PlanarSurface): Line2[] {
    const edges: Line2[] = [];
    const corners = this.cornersOnSurface2D(surface);

    edges[0] = new Line2(
      new Vector2(corners[0].x, corners[0].y),
      new Vector2(corners[1].x, corners[1].y)
    );
    edges[1] = new Line2(
      new Vector2(corners[1].x, corners[1].y),
      new Vector2(corners[2].x, corners[2].y)
    );
    edges[2] = new Line2(
      new Vector2(corners[2].x, corners[2].y),
      new Vector2(corners[3].x, corners[3].y)
    );
    edges[3] = new Line2(
      new Vector2(corners[3].x, corners[3].y),
      new Vector2(corners[0].x, corners[0].y)
    );

    return edges;
  }

  private _localBoundsCorners?: Vector3[];
  private _getBoundsCorners(flippedX = false): Vector3[] {
    if (!this._localBoundsCorners)
      this._localBoundsCorners = this._cornersInLocalSpace(true);
    return flippedX ? this._localBoundsCorners.map(corner => corner.clone()).reverse() : this._localBoundsCorners.map(corner => corner.clone())
  }

  private _localCorners?: Vector3[];
  private _getLocalCorners(flippedX = false): Vector3[] {
    if (!this._localCorners)
      this._localCorners = this._cornersInLocalSpace();
    return flippedX ? this._localCorners.map(corner => corner.clone()).reverse() : this._localCorners.map(corner => corner.clone());
  }

  private _cornersInLocalSpace(boundsCorners = false): Vector3[] {
    const corners: Vector3[] = [];

    if (!boundsCorners && this.openingPolygon) {
      const positions = (this.openingPolygon.geometry.attributes.position as BufferAttribute).array as Float32Array;

      for (let i = 0; i < positions.length; i += 3)
        corners.push(new Vector3(positions[i], positions[i + 1], 0));
    } else {
      const halfScale = 0.5
      corners[0] = new Vector3(halfScale, -halfScale, 0);
      corners[1] = new Vector3(halfScale, halfScale, 0);
      corners[2] = new Vector3(-halfScale, halfScale, 0);
      corners[3] = new Vector3(-halfScale, -halfScale, 0);
    }

    const windingOrder = this.windingOrder(corners);

    if (windingOrder > 0) {
      corners.reverse();
    }

    return corners;
  }

  /** algorithm to determine the winding order of a polygon @param points - the points of the polygon @returns 1 if the winding order is clockwise, -1 if it is counter-clockwise */
  public windingOrder(points: Vector3[]): number {
    let area = 0;
    const n = points.length;
    points.forEach((point, index) => {
      const x1 = point.x
      const y1 = point.y

      const nextIndex = (index + 1) % n;
      const x2 = points[nextIndex].x
      const y2 = points[nextIndex].y

      area += x1 * y2 - y1 * x2
    });
    if (area > 0)
      return -1;
    else
      return 1;
  }

  public cornersInWorldSpace(flippedX = false, boundsCorners = false): Vector3[] {
    if (boundsCorners)
      return this._getBoundsCorners(flippedX).map((corner) => this.localToWorld(corner));
    else
      return this._getLocalCorners(flippedX).map((corner) => this.localToWorld(corner));
  }

  public cornersOnSurface(surface: PlanarSurface): Vector3[] {
    const isMirrored = surface.normal.dot(this.getWorldDirection(new Vector3())) > 0;
    return this.cornersInWorldSpace(isMirrored).map((corner) => surface.worldToLocal(corner));
  }

  public boundsCornersOnSurface(surface: PlanarSurface): Vector3[] {
    const isMirrored = surface.normal.dot(this.getWorldDirection(new Vector3())) > 0;
    return this.cornersInWorldSpace(isMirrored, true).map((corner) => surface.worldToLocal(corner));
  }

  public cornersOnSurface2D(surface: PlanarSurface): Vector2[] {
    return this.cornersOnSurface(surface).map((corner) => new Vector2(corner.x, corner.y));
  }

  private _setOpeningParameters(parameters?: Map<string, string>) {
    const openingParameters = parameters ? parameters : getObjectParameters(this);

    const cleanArrayString = (str: string) => {
      return str.replace(' ', '').replace('[', '').replace(']', '').replaceAll("'", "");
    }

    this.globalId = openingParameters.has("GlobalId") ? openingParameters.get("GlobalId")! : "Generated Opening";
    this.doorType = openingParameters.has("doorType") ? openingParameters.get("doorType")! : "none";
    this.operationType = openingParameters.has("operationType") ? openingParameters.get("operationType")! : "NOTDEFINED";
    const typeString = openingParameters.get("type") || OPENING_TYPE[OPENING_TYPE.opening];
    this.openingType = OPENING_TYPE[typeString as keyof typeof OPENING_TYPE];
    this.doorOpen = openingParameters.has("doorOpen") ? openingParameters.get("doorOpen")! : undefined;
    this.doorAngle = openingParameters.has("doorAngle") ? parseFloat(openingParameters.get("doorAngle")!) : undefined;
    this.isExterior = openingParameters.has("isExterior") && openingParameters.get("isExterior") == "true";
    this.isBalcony = openingParameters.has("isBalcony") && openingParameters.get("isBalcony") == "true";
    this.source = openingParameters.has("source") ? openingParameters.get("source")! : "webbuilder";

    this.spaceIds = openingParameters.has("spaceIds") ? cleanArrayString(openingParameters.get("spaceIds")!).split(",") : [];
    this.surfaceIds = openingParameters.has("surfaceIds") ? cleanArrayString(openingParameters.get("surfaceIds")!).split(",") : [];

    this.isEntrance = openingParameters.has("isEntrance") && openingParameters.get("isEntrance") == "true";
    if (!this.isExterior) {
      this.isExterior = this.isEntrance;
    }

    this.openingType && openingParameters.set("type", OPENING_TYPE[this.openingType]);
    this.doorType && openingParameters.set("doorType", this.doorType);
    this.operationType && openingParameters.set("operationType", this.operationType);
    this.doorOpen && openingParameters.set("doorOpen", this.doorOpen);
    this.doorAngle && openingParameters.set("doorAngle", this.doorAngle.toString());

    this.isEntrance && openingParameters.set("isEntrance", this.isEntrance.toString());
    this.isExterior && openingParameters.set("isExterior", this.isExterior.toString());
    this.isBalcony && openingParameters.set("isBalcony", this.isBalcony.toString());
    this.source && openingParameters.set("source", this.source);

    this.spaceIds && openingParameters.set("spaceIds", `['${this.spaceIds.join("', '")}']`);
    this.surfaceIds && openingParameters.set("surfaceIds", `['${this.surfaceIds.join("', '")}']`);

    this.guid && openingParameters.set("id", this.guid);
    this.globalId && openingParameters.set("GlobalId", this.globalId);
    setObjectParameters(openingParameters, this);
  }

  public scaleFromOutline(outline: Vector3[]): Vector3 {
    const x = outline[2].x - outline[0].x;
    const y = outline[2].y - outline[0].y;
    return new Vector3(x, y, this.scale.z);
  }

  public appendSurfaceToOpening(surface: PlanarSurface): void {
    this.linkSurface(surface);

    if (!surface.apartment.openings.includes(this)) {
      surface.apartment.openings.push(this);
    }

    if (this.linkedSurfacesByIds.size < 3) {
      this.gizmos.push(new OpeningGizmo(this, surface));
    } else {
      console.error("Attempting to attach more than 3 surfaces to an opening");
    }

    this.setupMoveEvents();
  }

  private setupMoveEvents() {
    if (!this.hasEventListener("moved", this.updateLinkedSurfacesFactory))
      this.addEventListener("moved", this.updateLinkedSurfacesFactory);
  }

  private clearMoveEvents() {
    if (this.hasEventListener("moved", this.updateLinkedSurfacesFactory))
      this.removeEventListener("moved", this.updateLinkedSurfacesFactory);
  }

  private updateLinkedSurfacesFactory = () => {
    this.updateTangentialSurfaces();
    this.updateLinkedSurfaces();
  }

  private _removeOpeningFromTangentialSurfaces() {
    this._tangentialSurfaces.forEach((surface) => {
      surface.tangentialOpenings = surface.tangentialOpenings.filter((opening) => opening !== this)
    });

    this._tangentialSurfaces.forEach((surface) => surface.connectTangentialSurfaces());
    this._tangentialSurfaces.forEach((surface) => surface.updatePlanarGeometry());

    this._tangentialSurfaces = [];
  }

  private _addOpeningToTangentialSurfaces() {
    const newTangentialSurfaces: PlanarSurface[] = this.getTangentialSurfaces();

    newTangentialSurfaces.forEach((surface) => {
      if (surface.tangentialOpenings.includes(this)) return;
      surface.tangentialOpenings.push(this);
    });

    this._tangentialSurfaces = newTangentialSurfaces;

    this._tangentialSurfaces.forEach((surface) => surface.connectTangentialSurfaces());
    this._tangentialSurfaces.forEach((surface) => surface.updatePlanarGeometry());
  }

  public updateTangentialSurfaces() {
    this._removeOpeningFromTangentialSurfaces();
    this._addOpeningToTangentialSurfaces();
  }

  private getTangentialSurfaces(): PlanarSurface[] {

    const newTangentialSurfaces: PlanarSurface[] = [];

    if (!this.UVsTraverseOpening) return newTangentialSurfaces;

    const linkedSurfaces = [...this.linkedSurfacesByIds.values()];

    linkedSurfaces.forEach((surface) => {
      const edges = this.edgesOnSurface(surface);
      const currTangentialSurfaces = surface.intersectingNeighborsByPlanarEdges(edges);

      currTangentialSurfaces.forEach((surface) => {
        if (newTangentialSurfaces.includes(surface)) return;
        newTangentialSurfaces.push(surface);
      });
    });

    return newTangentialSurfaces;
  }

  public updateLinkedSurfaces() {
    [...this.linkedSurfacesByIds.values()].forEach((surface) => surface.updatePlanarGeometry());
  }

  public attachToHitSurfaces(planarSurfaces: PlanarSurface[]): PlanarSurface[] {
    planarSurfaces.forEach((planarSurface) => {
      planarSurface.material.side = DoubleSide;
    });

    let hitSurfaces: PlanarSurface[] = [];

    const raycaster = new Raycaster();

    const raycastDirection1 = this.getWorldDirection(new Vector3());
    const raycastDirection2 = raycastDirection1.clone().multiplyScalar(-1);

    const startRayPosition = this.position.clone();
    startRayPosition.addScaledVector(raycastDirection1, RAYCAST_DISTANCE_LIMIT / 2);

    raycaster.set(startRayPosition, raycastDirection2);
    let intersections = raycaster.intersectObjects(planarSurfaces);

    intersections.length = 2;
    intersections = intersections
      .filter((intersection) => intersection)
      .filter((intersection) => intersection.distance < RAYCAST_DISTANCE_LIMIT);

    if (intersections.length) {
      const hitPositions = intersections.map((intersection) => intersection.point).filter((position) => position) as Vector3[];

      hitSurfaces = intersections.map((intersection) => intersection.object as PlanarSurface).filter((surface) => surface);

      if (hitPositions.length == 2) {
        // Between 2 surfaces opening
        const newOpeningPosition = hitPositions[0].clone().add(hitPositions[1]).divideScalar(2);

        const zScale = hitPositions[0].distanceTo(hitPositions[1]);
        this.position.copy(newOpeningPosition);
        this.scale.z = zScale < 0.00001 ? 0.00001 : zScale;
      } else if (hitPositions.length == 1) {
        // Outer wall opening
        const innerHitPosition = hitPositions[0].clone();
        const hullMesh = hitSurfaces[0].apartment?.hullMesh;

        if (hullMesh) {
          (hullMesh.material as Material).side = DoubleSide;

          const hullIntersections = raycaster
            .intersectObjects([hullMesh])
            .filter((intersection) => intersection)
            .filter((intersection) => intersection.distance < RAYCAST_DISTANCE_LIMIT);

          if (hullIntersections.length) {
            const outerHitPosition = hullIntersections[0].point;
            const newOpeningPosition = outerHitPosition.clone().add(innerHitPosition).divideScalar(2);

            this.position.copy(newOpeningPosition);
            const distance = outerHitPosition.distanceTo(innerHitPosition);
            // Make sure hullMesh is actually some distance away from wall
            // This is because the hull mesh isn't guaranteed to be extruded by walls
            this.scale.z = distance < 0.00001 ? 0.00001 : distance;

            this.isExterior = true;
          } else {
            this.scale.z = 0.00001;
            this.isExterior = true;
          }

          (hullMesh.material as Material).side = FrontSide;
        }
      } else {
        console.error("No ray hit positions found for opening: ", this.globalId);
      }

      hitSurfaces
        .filter((surf) => surf)
        .filter((surf) => surf.type === "PlanarSurface")
        .forEach((surface: PlanarSurface) => surface.attachOpening(this));
    }

    planarSurfaces.forEach((planarSurface) => {
      planarSurface.material.side = FrontSide;
    });

    this._setOpeningParameters();

    return hitSurfaces;
  }

  public showGizmos(enable = true) {
    this.gizmos.forEach((gizmo) => gizmo.setVisible(enable));
  }
}
