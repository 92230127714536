import {
  Vector3,
  Object3D,
  Euler,
  Raycaster,
  Vector2,
  Intersection,
  MathUtils,
  LineSegments,
  EdgesGeometry,
  BoxGeometry,
  LineBasicMaterial,
  Group,
  Mesh,
} from "three";
import { Object3DEventType, ThreeJsContext } from "@/three/ThreeJsContext";
import { Asset } from "@/interfaces/Asset";
import { getObjectParameters, setObjectParameters, getParentOfType } from "../ThreeJsHelpers";
import { PlanarSurface } from "../objects/PlanarSurface";

const rotationFactor = 0.025;

export class AssetDragController {
  public lod = 1;
  public onSelectOn: ((assetId: string | undefined) => void) | undefined;
  public onSelectOff: (() => void) | undefined;
  private enabled = false;
  private isClick = false;
  private selectedObject?: Object3D;
  private draggedObject?: Object3D;
  private hoveredObject?: Object3D;
  private assetPlaceholder: Group;
  private dragAndDropObject?: Object3D;
  private dragAndDropAssetData?: Asset;
  private raycaster = new Raycaster();
  private raycastPoint = new Vector3();
  private pointer = new Vector2();
  private intersections: Intersection[] = [];
  private threeJsContext: ThreeJsContext;
  private dragOverFactory: (event: DragEvent) => void;
  private dropFactory: (event: DragEvent) => void;
  private onPointerMoveFactory: (event: PointerEvent) => void;
  private onPointerDownFactory: (event: PointerEvent) => void;
  private onPointerUpFactory: (event: PointerEvent) => void;
  private onPointerCancelFactory: (event: PointerEvent) => void;
  private onMouseWheelFactory: (event: WheelEvent) => void;

  constructor(threeJsContext: ThreeJsContext) {
    this.threeJsContext = threeJsContext;
    this.assetPlaceholder = new Group();
    const lineSegments = new LineSegments(
      new EdgesGeometry(new BoxGeometry(1, 1, 1)),
      new LineBasicMaterial({
        color: 0x00ff00,
        depthTest: false,
        toneMapped: false,
      })
    );
    this.assetPlaceholder.renderOrder = 1;
    this.assetPlaceholder.add(lineSegments);
    lineSegments.position.set(0, 0.5, 0);

    threeJsContext.scene.add(this.assetPlaceholder);

    this.assetPlaceholder.visible = false;

    this.dragOverFactory = (event: DragEvent) => {
      this.onDragOver(event);
    };
    this.dropFactory = (event: DragEvent) => {
      this.onDrop(event);
    };
    this.onPointerMoveFactory = (event: PointerEvent) => {
      this.onPointerMove(event);
    };
    this.onPointerDownFactory = (event: PointerEvent) => {
      this.onPointerDown(event);
    };
    this.onPointerUpFactory = (event: PointerEvent) => {
      this.onPointerUp(event);
      this.onCancel();
    };
    this.onPointerCancelFactory = (event: PointerEvent) => {
      this.onCancel();
    };
    this.onMouseWheelFactory = (event: WheelEvent) => {
      this.onMouseWheel(event);
    };
  }

  public unselectObjects(): void {
    this.selectedObject = undefined;
    this.threeJsContext.outlinePass.selectedObjects = [];
  }

  public setSelectedAsset(asset: Asset) {
    this.dragAndDropAssetData = asset;
  }

  public startReplaceSelectedAsset(): string | undefined {
    if (this.selectedObject) {
      const assetNode = getParentOfType(this.selectedObject, "asset");
      if (assetNode) {
        const assetParameters = getObjectParameters(assetNode);
        const assetId = assetParameters.has("assetId") ? assetParameters.get("assetId") : "";
        return assetId;
      }
    }
  }

  public replaceSelectedAsset(assetData: Asset, currentAssetTypeId: string) {
    if (!this.selectedObject) return;
    const assetNode = getParentOfType(this.selectedObject, "asset");
    if (!assetNode) return;

    const currentAssetParameters = getObjectParameters(assetNode);
    const currentId = currentAssetParameters.has("assetId") ? currentAssetParameters.get("assetId") : "";

    if (currentAssetTypeId === currentId) return;

    const models = assetData.models as any[];
    const lod = models.length > this.lod ? this.lod : models.length - 1;
    const url = models.length && models[lod]?.downloadUrl

    if (!url) return;

    const assetId = assetData.id;
    const assetTitle = assetData.title;

    currentAssetParameters.set("assetId", assetId);
    currentAssetParameters.set("assetTitle", assetTitle);
    setObjectParameters(currentAssetParameters, assetNode);

    this.assetPlaceholder.visible = true;

    this.assetPlaceholder.scale.set(
      assetData.width ? assetData.width : 1,
      assetData.height ? assetData.height : 1,
      assetData.depth ? assetData.depth : 1
    );

    assetNode.add(this.assetPlaceholder);

    this.removeSelectedAsset(false);;

    this.threeJsContext.placeGltf(url, assetNode.position.clone(), new Euler(), assetNode).finally(() => {
      this.onLoadedGltfAsset(assetNode, assetNode.position);
    });
  }

  private onLoadedGltfAsset(assetNode: Object3D, position?: Vector3): void {
    assetNode.position.copy(position ? position : this.raycastPoint);
    assetNode.children.forEach((child) => {
      child.position.copy(new Vector3());
    });
    this.assetPlaceholder.visible = false;
    this.assetPlaceholder.removeFromParent();
    this.selectOn(assetNode);
  }

  public removeSelectedAsset(removeAssetNode = false): void {
    if (!this.selectedObject) return;
    const assetNode = getParentOfType(this.selectedObject, "asset");
    assetNode?.children[0]?.removeFromParent();

    if (removeAssetNode) {
      assetNode?.removeFromParent();
      this.selectedObject?.dispatchEvent({
        type: Object3DEventType.Destroyed,
      });
      this.selectOff();
    }

    this.selectedObject = undefined;
    this.draggedObject = undefined;
    this.hoveredObject = undefined;

    if (removeAssetNode) {
      this.onCancel();
    }
  }

  public activate() {
    this.threeJsContext.domElement.addEventListener("dragover", this.dragOverFactory);
    this.threeJsContext.domElement.addEventListener("drop", this.dropFactory);
    this.threeJsContext.domElement.addEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.addEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.addEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.addEventListener("pointerleave", this.onPointerCancelFactory);
    this.threeJsContext.domElement.addEventListener("wheel", this.onMouseWheelFactory);
    this.enabled = true;
  }

  public deactivate() {
    this.threeJsContext.domElement.style.cursor = "auto";
    this.threeJsContext.domElement.removeEventListener("dragover", this.dragOverFactory);
    this.threeJsContext.domElement.removeEventListener("drop", this.dropFactory);
    this.threeJsContext.domElement.removeEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.removeEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.removeEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.removeEventListener("pointerleave", this.onPointerCancelFactory);
    this.threeJsContext.domElement.removeEventListener("wheel", this.onMouseWheelFactory);
    this.enabled = false;
  }

  private onPointerDown(event: PointerEvent): void {
    if (!this.enabled || event.button !== 0) return;
    this.isClick = true;
    this.updatePointer(event);

    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.raycaster.intersectObjects(this.getIntersectableAssets(), true, this.intersections);

    if (this.intersections.length) {
      const object = this.intersections[0]?.object;

      if (object.parent) {
        this.draggedObject = getParentOfType(object, "asset");

        if (this.draggedObject) {
          this.selectedObject !== this.draggedObject && this.selectOn(this.draggedObject);

          this.threeJsContext.orbitControls.enabled = false;
        }
      }
    }
  }

  private onPointerUp(event: PointerEvent): void {
    if (!this.enabled) return;
    if (!this.isClick || event.button !== 0) return;
    this.updatePointer(event);

    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.raycaster.intersectObjects(this.getIntersectableAssets(), true, this.intersections);

    if (!this.intersections.length) {
      this.selectOff();
    }
  }

  private onPointerMove(event: PointerEvent): void {
    if (!this.enabled) return;

    this.updatePointer(event);

    const apartmentSurfaces = this.getIntersectableSurfaces();
    if (apartmentSurfaces.length == 0) return;

    if (this.draggedObject) {
      this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);

      this.intersections.length = 0;

      this.raycaster.intersectObjects(apartmentSurfaces, false, this.intersections);

      this.raycastPoint = this.intersections[0]?.point;
      const normal = (this.intersections[0].object as PlanarSurface).normal;

      if (this.raycastPoint && normal) {
        this.dragAssetObject(this.raycastPoint, normal);
      }

      return;
    }

    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    const allIntersectable = this.getIntersectableAssets().concat(apartmentSurfaces);
    this.raycaster.intersectObjects(allIntersectable, true, this.intersections);

    if (this.intersections.length == 0) return;

    const object = this.intersections[0]?.object;

    if (object.parent && this.hoveredObject !== object.parent) {
      this.hoverOff();
      const assetNode = getParentOfType(object, "asset");
      assetNode && this.hoverOn(object.parent);
    }
  }

  private onDragOver(event: DragEvent) {
    // onDragOver has to return false in order for the onDrop event to be fired by the browser.
    if (!this.enabled || !this.dragAndDropAssetData) return false;
    event.preventDefault();

    this.updatePointer(event);

    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);

    const apartmentSurfaces = this.getIntersectableSurfaces();

    this.intersections.length = 0;
    this.raycaster.intersectObjects(apartmentSurfaces, false, this.intersections);

    if (!this.intersections.length) return false;
    this.raycastPoint = this.intersections[0].point;
    const normal = (this.intersections[0].object as PlanarSurface).normal;

    if (this.dragAndDropObject && normal) {
      this.dragAssetObject(this.raycastPoint, normal);
    } else {
      const models = this.dragAndDropAssetData?.models as any[];
      if (!models || !models.length) return;

      const lod = models.length > this.lod ? this.lod : models.length - 1;
      const url = models[lod]?.downloadUrl;
      const anchorPoint = this.dragAndDropAssetData?.anchorpoint;

      if (this.dragAndDropAssetData && url) {
        const currentApartments = this.threeJsContext.currentApartments;
        if (!currentApartments.length) return false;

        const currentApartment = currentApartments.find(
          (apt) => !!apt.surfaces.find((surface) => surface == this.intersections[0].object)
        );
        if (!currentApartment) return false;

        const assetId = this.dragAndDropAssetData.id;
        const assetTitle = this.dragAndDropAssetData.title;

        const assetNode = new Object3D();
        const assetNodeParameters = new Map<string, string>();
        assetNodeParameters.set("type", "asset");
        assetNodeParameters.set("assetId", assetId);
        assetNodeParameters.set("title", assetTitle);
        anchorPoint ? assetNodeParameters.set("anchorpoint", anchorPoint) : assetNodeParameters.set("anchorpoint", "Floor");

        setObjectParameters(assetNodeParameters, assetNode);

        currentApartment.assetsNode.add(assetNode);

        this.assetPlaceholder.visible = true;

        this.assetPlaceholder.scale.set(
          this.dragAndDropAssetData.width ? this.dragAndDropAssetData.width : 1,
          this.dragAndDropAssetData.height ? this.dragAndDropAssetData.height : 1,
          this.dragAndDropAssetData.depth ? this.dragAndDropAssetData.depth : 1
        );

        assetNode.add(this.assetPlaceholder);

        this.dragAndDropObject = assetNode;
        this.draggedObject = assetNode;

        this.threeJsContext.placeGltf(url, this.raycastPoint, new Euler(), assetNode).finally(() => {
          this.onLoadedGltfAsset(assetNode);
        });
      }
    }
    return false;
  }

  private onDrop(event: DragEvent) {
    if (!this.enabled) return;

    event.preventDefault();
    this.dragAndDropObject && this.selectOn(this.dragAndDropObject);
    this.dragAndDropObject = undefined;
    this.draggedObject = undefined;
    this.dragAndDropAssetData = undefined;
  }

  private onCancel() {
    if (!this.enabled) return;
    this.threeJsContext.orbitControls.enabled = true;

    this.draggedObject = undefined;
    this.dragAndDropObject = undefined;
    this.dragAndDropAssetData = undefined;
  }

  private hoverOn(hovered: Object3D): void {
    this.threeJsContext.domElement.style.cursor = "pointer";
    this.hoveredObject = hovered;
  }

  private hoverOff(): void {
    this.threeJsContext.domElement.style.cursor = "auto";
    this.hoveredObject = undefined;
  }

  private selectOn(object: Object3D): void {
    this.selectedObject = object;
    const assetParameters = getObjectParameters(this.selectedObject);
    const assetId = assetParameters.has("assetId") ? assetParameters.get("assetId") : "";
    this.threeJsContext.outlinePass.selectedObjects = [...this.selectedObject.children];
    const anchorpoint = assetParameters.get("anchorpoint");
    this.setObjectUserDataAngle(this.selectedObject, anchorpoint);
    this.onSelectOn && this.onSelectOn(assetId);
  }

  private setObjectUserDataAngle(object: Object3D, anchorpoint?: string): void {
    const angleExists = !!(object.userData as any).currentRotationAngle;
    if (anchorpoint !== "Wall") {
      !angleExists && ((object.userData as any).currentRotationAngle = object.rotation.y);
    } else {
      (object.userData as any).currentRotationAngle = 0;
    }
  }

  private selectOff(): void {
    this.onSelectOff && this.onSelectOff();
  }

  private dragAssetObject(newPosition: Vector3, surfaceNormal: Vector3): void {
    if (this.draggedObject) {
      const anchorpoint = getObjectParameters(this.draggedObject).get("anchorpoint");
      if (!(this.draggedObject.userData as any).currentRotationAngle) {
        this.setObjectUserDataAngle(this.draggedObject, anchorpoint);
      }

      (this.draggedObject.userData as any).currentSurfaceAxis = surfaceNormal;

      this.draggedObject.position.copy(newPosition);

      this.setDraggedRotation(anchorpoint);
    }
  }

  private updatePointer(event: PointerEvent | DragEvent): void {
    const rect = this.threeJsContext.domElement.getBoundingClientRect();

    const oldX = this.pointer.x;
    const oldY = this.pointer.y;

    this.pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;

    if (this.isClick) {
      const delta = new Vector2(this.pointer.x - oldX, this.pointer.y - oldY);
      if (Math.abs(delta.x) > 0.003 || Math.abs(delta.y) > 0.003) this.isClick = false;
    }
  }

  private onMouseWheel(event: WheelEvent): void {
    if (!this.enabled) return;

    event.preventDefault();

    if (this.draggedObject) {
      (this.draggedObject.userData as any).currentRotationAngle -= MathUtils.DEG2RAD * event.deltaY * rotationFactor;

      const anchorpoint = getObjectParameters(this.draggedObject).get("anchorpoint");

      this.setDraggedRotation(anchorpoint);
    }
  }

  private setDraggedRotation(anchorpoint?: string): void {
    if (this.draggedObject) {
      const currentRotationAngle = (this.draggedObject.userData as any).currentRotationAngle;
      const currentSurfaceAxis = (this.draggedObject.userData as any).currentSurfaceAxis;
      if (anchorpoint === "Wall") {
        const target = this.draggedObject.position.clone().add(currentSurfaceAxis);

        this.draggedObject.lookAt(target);

        this.draggedObject.rotateOnWorldAxis(currentSurfaceAxis, currentRotationAngle);
      } else {
        this.draggedObject.rotation.y = currentRotationAngle;
      }
    }
  }

  private getIntersectableSurfaces(): Mesh[] {
    const currentApartments = this.threeJsContext.currentApartments;
    return currentApartments.flatMap((apartment) => apartment.surfaces);
  }

  private getIntersectableAssets(): Object3D[] {
    const currentApartments = this.threeJsContext.currentApartments;
    const assetsNodes: Object3D[] = currentApartments.map((apartment) => apartment.assetsNode);
    if (!assetsNodes.length) return [];

    let assets: Array<Object3D | undefined> = assetsNodes.flatMap((assetNode) => assetNode.children);
    assets = assets.filter((asset) => asset) as Object3D[];
    assets = assets.map((asset: any) => (asset.children.length > 0 ? asset.children[0] : undefined));
    return assets.filter((asset) => asset) as Object3D[];
  }
}
