








import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import LoadingCursor from "@/components/LoadingCursor.vue";
import { debugMode, lod, setThreeJsReady, threeJsReady } from "@/store";
import { isAnAssetSelected, setCurrentAssetTypeId, setIsAnAssetSelected } from "@/store/assets";
import { isAnSurfaceSelected } from "@/store/surfaces";
import { isAWaypointSelected, setIsAWaypointSelected } from "@/store/waypoints";

export default Vue.extend({
  name: "ThreeJsContainer",
  components: { LoadingCursor },
  async mounted() {
    // setup three js context
    this.threeJsContext.renderToDom(this.$refs["canvas-container"] as HTMLElement);
    await this.threeJsContext.initialize();
    // setup asset drag controller
    this.assetDragController.onSelectOn = (assetId) => {
      this.setCurrentAssetTypeId(assetId);
      if (!this.isAnAssetSelected) {
        this.setIsAnAssetSelected(true);
      }
    };
    this.assetDragController.onSelectOff = () => {
      this.setIsAnAssetSelected(false);
    };
    // setup waypoint drag controller
    this.cameraDragController.onSelectOff = () => {
      this.setIsAWaypointSelected(false);
    };
    // mark three js as ready
    this.setThreeJsReady(true);
  },
  computed: {
    ...mapGetters([threeJsReady, isAnAssetSelected, isAnSurfaceSelected, isAWaypointSelected, lod, debugMode]),
  },
  watch: {
    isAnAssetSelected() {
      if (!this.isAnAssetSelected) {
        this.assetDragController.unselectObjects();
      }
    },
    isAnSurfaceSelected() {
      if (!this.isAnSurfaceSelected) {
        this.threeJsContext.outlinePass.selectedObjects = [];
      }
    },
    isAWaypointSelected() {
      if (!this.isAWaypointSelected) {
        this.cameraDragController.unselectCameraWaypoint();
      }
    },
    lod() {
      this.assetDragController.lod = this.lod;
    },
    debugMode() {
      if (this.debugMode) {
        this.openingsDragControls.activate();
      } else {
        this.openingsDragControls.deactivate();
      }
      this.ifcSceneExporter.debugMode = this.debugMode;
      this.objectDebugger.enable(this.debugMode);
    },
  },
  methods: {
    ...mapMutations([setThreeJsReady, setCurrentAssetTypeId, setIsAnAssetSelected, setIsAWaypointSelected]),
  },
  destroyed() {
    this.threeJsContext.dispose();
  },
});
