import { Vector2 } from "three";

const DEFAULT_PRECISION = 1;
const DEFAULT_MARGIN = 0.0001;

declare module "three/src/math/Vector2.js" {
    interface Vector2 {
        /** Approximate this vector to given decimal places. */
        approximate: (this: Vector2, places: number) => Vector2;
        /** Returns a string where x and y are represented either in exponential or fixed-point notation with a specified number of digits. */
        toFixed: (this: Vector2, precision?: number) => string;
        /** Returns a Vector2 from a string representing a value converted to string via the toPrecision function. */
        fromFixed: (this: Vector2, serializedValue: string) => Vector2;
        /** Checks for approximate equality of this vector and other. */
        hashEquals: (this: Vector2, other: Vector2, hashPlaces?: number) => boolean;
        /** Checks for approximate equality of this vector and other. */
        isApproximate: (this: Vector2, other: Vector2, precision?: number) => boolean;
        /** Checks if this vector is numerically close to other. */
        isClose: (this: Vector2, other: Vector2, precision?: number) => boolean;
    }
}

Vector2.prototype.toFixed = function (this: Vector2, precision = DEFAULT_PRECISION): string {
    const approximated = this.clone().approximate(precision);
    return `${approximated.x.toFixed(precision)},${approximated.y.toFixed(precision)}`;
};

Vector2.prototype.fromFixed = function (this: Vector2, serializedValue: string): Vector2 {
    const [x, y, z] = serializedValue.split(",");
    this.x = parseFloat(x);
    this.y = parseFloat(y);

    return this;
};

Vector2.prototype.approximate = function (this: Vector2, precision: number): Vector2 {
    const power = Math.pow(10, precision);
    const factor = 1 / power;
    this.x = Math.round((this.x + Number.EPSILON) * power) * factor;
    this.y = Math.round((this.y + Number.EPSILON) * power) * factor;

    return this;
};

Vector2.prototype.hashEquals = function (this: Vector2, other: Vector2, precision = DEFAULT_PRECISION): boolean {
    return this.toFixed(precision) === other.toFixed(precision);
};

Vector2.prototype.isApproximate = function (this: Vector2, other: Vector2, precision = DEFAULT_PRECISION): boolean {
    return this.approximate(precision).equals(other.approximate(precision));
}

Vector2.prototype.isClose = function (this: Vector2, other: Vector2, margin = DEFAULT_MARGIN): boolean {
    const subtracted = this.clone().sub(other);
    return subtracted.x < margin && subtracted.y < margin;
}