import { AssetDragController } from "@/three/controllers/AssetDragController";
import { OpeningDragController } from "@/three/controllers/OpeningDragController";
import { SurfaceDragController } from "@/three/controllers/SurfaceDragController";
import { WaypointDragController } from "@/three/controllers/WaypointDragController";
import { SceneExporter } from "@/three/tools/SceneExporter";
import { ObjectDebugger } from "@/three/helpers/ObjectDebugger";
import { ThreeJsContext } from "@/three/ThreeJsContext";
import Vue from "vue";

Vue.use({
  install(_Vue: typeof Vue) {
    const threeJsContext = new ThreeJsContext();
    _Vue.prototype.threeJsContext = threeJsContext;
    _Vue.prototype.openingsDragControls = new OpeningDragController(threeJsContext);
    _Vue.prototype.surfaceDragController = new SurfaceDragController(threeJsContext);
    _Vue.prototype.assetDragController = new AssetDragController(threeJsContext);
    _Vue.prototype.cameraDragController = new WaypointDragController(threeJsContext);
    _Vue.prototype.ifcSceneExporter = new SceneExporter(threeJsContext);
    _Vue.prototype.objectDebugger = new ObjectDebugger();
  },
});

declare module "vue/types/vue" {
  interface Vue {
    threeJsContext: ThreeJsContext;
    openingsDragControls: OpeningDragController;
    surfaceDragController: SurfaceDragController;
    assetDragController: AssetDragController;
    cameraDragController: WaypointDragController;
    ifcSceneExporter: SceneExporter;
    objectDebugger: ObjectDebugger;
  }
}
