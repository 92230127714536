































import { isAnAssetSelected, setCurrentAssetTypeId, setIsAnAssetSelected } from "@/store/assets";
import Vue from "vue";
import { mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  name: "AssetMenu",
  methods: {
    ...mapMutations([setIsAnAssetSelected, setCurrentAssetTypeId]),
    addNewAsset(): void {
      this.$router.push(`/${this.$route.params.project}/${this.$route.params.apartment}/asset-work`);
      this.setIsAnAssetSelected(false);
    },
    replaceAsset(): void {
      const assetId = this.assetDragController.startReplaceSelectedAsset();
      if (assetId) {
        this.setCurrentAssetTypeId(assetId);
      }
    },
    removeAsset(): void {
      this.assetDragController.removeSelectedAsset(true);
    },
  },
  computed: {
    ...mapGetters([isAnAssetSelected]),
  },
  mounted() {
    this.assetDragController.activate();
  },
  beforeDestroy() {
    this.assetDragController.deactivate();
  },
});
