




















import Vue from "vue";

import { backend } from "@/lib/backend";

export default Vue.extend({
  name: "FileUpload",
  data(): {
    files: File[];
    dialog: boolean;
    draggedOver: boolean;
    dragDrop: boolean;
    isLoading: boolean;
  } {
    return {
      files: [],
      dialog: true,
      draggedOver: false,
      dragDrop: true,
      isLoading: false,
    };
  },
  methods: {
    input(files: File[]) {
      this.files = files
    },
    async uploadFiles(files: File[]): Promise<any> {

      this.isLoading = true;

      const glbFiles = files.filter((file) => file.name.endsWith(".glb"));
      const jsonFiles = files.filter((file) => file.name.endsWith(".json"));
      const svgFiles = files.filter((file) => file.name.endsWith(".svg"));

      if (glbFiles.length) {
        const response = await backend.postGlb(this.$route.params.project, glbFiles[0]);
        console.log(response)
      }

      if (jsonFiles.length) {


        
        const response = await backend.postBuildingUnitMetadata(jsonFiles[0]);
        console.log(response)
      }

      if (svgFiles.length) {
        const responses = await backend.postFloorPlans(this.$route.params.project, svgFiles);
        responses.forEach((response) =>
          console.log(response)
        );
      }

      this.isLoading = false;

      return Promise.resolve();
    },
  },
});
