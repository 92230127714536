
































import Vue from "vue";

export default Vue.extend({
  name: "ThumbnailGallery",
  props: {
    currentResourceId: String,
    resources: [],
  },
  methods: {
    selectResource(resourceId: string): void {
      this.$emit("selectResource", resourceId);
    },
    dragStart(event: DragEvent, index: number) {
      this.$emit("dragStart", event);
      const img = this.$refs.images![index].$el as HTMLImageElement;
      event.dataTransfer?.setDragImage(img, 0, 0);
    },
    getThumbnailUrl(resource: any): string {
      return resource.thumbnailUrl ? resource.thumbnailUrl : require("../assets/empty.png");
    },
  },
});
