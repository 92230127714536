import { Vector2 } from "three";
import { Line2 } from "../math/Line2";
import * as clipperLib from "js-angusj-clipper/web";
import { clipper } from "../ThreeJsContext";
import * as Triangle from "triangle-wasm";
import * as unflat from "array-unflat";

export const CLIPPER_SCALE_FACTOR = 100000000;

interface Poly {
  pointlist: number[];
  numberofpoints: number;
  segmentlist: number[];
  numberofsegments: number;
  holelist: number[];
  numberofholes: number;
}

/** Triangulated mesh data with the corner positions and indices, as well as the index offset if part of a more complex mesh */
export interface TriangulatedData {
  corners: Vector2[];
  indices: number[];
  offset: number;
}

/** A polygon and its respective holes */
export interface PolygonHolePair {
  polygon: Vector2[];
  holes: Vector2[][];
}

/** Triangulate a shape with holes. The total corner positions are also returned. An optional offset can be given if the mesh that needs to be triangulated is composed of many polygons */
export function triangulate(corners: Vector2[], holes: Vector2[][] = [], indexOffset = 0, id: string = ""): TriangulatedData {
  try {
    const poly: Poly = polyFromShapeHoles(corners, holes);

    const input = Triangle.makeIO(poly);
    const output = Triangle.makeIO();

    Triangle.triangulate(null, input, output);

    corners = unflat(output.pointlist, 2).map(point => new Vector2(point[0], point[1]));
    const indices = output.trianglelist.map(index => index + indexOffset)
    const offset = indexOffset + output.numberofpoints;

    Triangle.freeIO(input, true);
    Triangle.freeIO(output);

    return { corners, indices, offset };

  } catch (err) {
    console.error(`Unable to triangulate. GlobalId: ${id}`);
    console.log(`Corners:`);
    corners.forEach((corner) => console.log(corner));
    holes.length && console.log(`Holes:`);
    holes.forEach((hole) => hole.forEach((point) => console.log(point)));
    console.error(err);
    return { corners: [], indices: [], offset: indexOffset };
  }
}

/** Do a merge operation between two sets of lines. Only one set of lines is returned */
export function mergeLineChunks(linesChunks: Line2[][], shouldDebug = false): Line2[][] {
  const pointChunks = linesChunks.map((lines) => lines.map((line) => line.start));
  const mergedChunks = mergePointChunks(pointChunks, true, shouldDebug);

  return mergedChunks.map((points) => {
    return points.map((point, index) => {
      const nextIndex = (index + 1) % points.length;
      const nextPoint = points[nextIndex];
      return new Line2(point, nextPoint);
    });
  });
}

/** Do a merge operation between two sets of points */
export function mergePointChunks(pointChunks: Vector2[][], filterDegenerate = true, decimate = false): Vector2[][] {
  if (!clipper) throw new Error("clipper is not initialized yet");

  if (filterDegenerate) {
    const pointWidthsHeights = pointChunks.reduce((acc, points) => {
      const width = Math.max(...points.map((point) => point.x)) - Math.min(...points.map((point) => point.x));
      const height = Math.max(...points.map((point) => point.y)) - Math.min(...points.map((point) => point.y));
      acc.push({ width, height });
      return acc;
    }, [] as { width: number; height: number }[]);

    pointChunks = pointChunks.filter((points, index) => {
      const { width, height } = pointWidthsHeights[index];
      return width > 0 && height > 0;
    });
  }

  const allPolys = pointChunks.map((points) => [
    points.map((point) => {
      return { x: Math.trunc(point.x * CLIPPER_SCALE_FACTOR), y: Math.trunc(point.y * CLIPPER_SCALE_FACTOR) };
    }),
  ]);

  const subjectInputs = allPolys.map((poly) => {
    return { data: poly, closed: true };
  });

  if (subjectInputs.length === 0) return [];

  let resultPaths = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Union,
    subjectInputs: subjectInputs,
    subjectFillType: clipperLib.PolyFillType.NonZero,
  });

  pointChunks = resultPaths.map((path) =>
    path.map((point) => new Vector2(point.x / CLIPPER_SCALE_FACTOR, point.y / CLIPPER_SCALE_FACTOR))
  );

  if (decimate) {
    let lineChunks = pointChunks.map((chunk) => Line2.convertPointsToLines(chunk));
    lineChunks = lineChunks.map((lines) => Line2.adjustSegmentsBasedOnAngle(lines, 0.01));
    pointChunks = lineChunks.map((lines) => Line2.convertLinesToPoints(lines));
  }

  return pointChunks;
}

/** Do a boolean intersection between two sets of lines. Only one set of lines is returned */
export function intersectionLineChunks(baseLines: Line2[], newLines: Line2[]): Line2[] {
  if (!clipper) throw new Error("clipper is not initialized yet");

  const basePoints = baseLines.map((line) => line.start);
  const newPoints = newLines.map((line) => line.start);

  const resultPoints = intersectionPointChunks(basePoints, newPoints);

  return resultPoints.map((point, index) => {
    const nextIndex = (index + 1) % resultPoints.length;
    const nextPoint = resultPoints[nextIndex];
    return new Line2(point, nextPoint);
  });
}

/** Do a boolean intersection between two sets of points. Only one set of points is returned */
export function intersectionPointChunks(
  basePoints: Vector2[],
  newPoints: Vector2[],
  clipperScaleFactor = CLIPPER_SCALE_FACTOR
): Vector2[] {
  if (!clipper) throw new Error("clipper is not initialized yet");

  const basePoly = basePoints.map((point) => {
    return { x: Math.trunc(point.x * clipperScaleFactor), y: Math.trunc(point.y * clipperScaleFactor) };
  });
  const newPoly = newPoints.map((point) => {
    return { x: Math.trunc(point.x * clipperScaleFactor), y: Math.trunc(point.y * clipperScaleFactor) };
  });

  const polyInput = [{ data: [basePoly], closed: true }];
  const newInputs = [{ data: newPoly, closed: true }];

  const resultPaths = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Intersection,
    clipInputs: newInputs,
    subjectInputs: polyInput,
    subjectFillType: clipperLib.PolyFillType.NonZero,
  });

  const points: Vector2[] = [];

  resultPaths.forEach((path) => {
    const point = path.map((point) => new Vector2(point.x / CLIPPER_SCALE_FACTOR, point.y / CLIPPER_SCALE_FACTOR));
    points.push(...point);
  });

  return points;
}

/** Do a boolean intersection between an outline and holes. Because of possibility of bisection by holes a list of polygon hole pairs is returned */
export function differencePolygonHolePairs(holeChunks: Vector2[][], outline: Vector2[]): PolygonHolePair[] {
  if (!clipper) throw new Error("clipper is not initialized yet");

  const outlinePoly = outline.map((point) => {
    return { x: Math.trunc(point.x * CLIPPER_SCALE_FACTOR), y: Math.trunc(point.y * CLIPPER_SCALE_FACTOR) };
  });
  const holePolys = holeChunks.map((points) =>
    points.map((point) => {
      return { x: Math.trunc(point.x * CLIPPER_SCALE_FACTOR), y: Math.trunc(point.y * CLIPPER_SCALE_FACTOR) };
    })
  );

  const polyInput = [{ data: [outlinePoly], closed: true }];

  const holeInputs = holePolys.map((poly) => {
    return { data: poly, closed: true };
  });

  const resultTree: clipperLib.PolyTree = clipper.clipToPolyTree({
    clipType: clipperLib.ClipType.Difference,
    clipInputs: holeInputs,
    subjectInputs: polyInput,
    subjectFillType: clipperLib.PolyFillType.NonZero,
  });

  return getPolygonHolePairsFromPolyTree(resultTree);
}

function getPolygonHolePairsFromPolyTree(polyTree: clipperLib.PolyTree): PolygonHolePair[] {

  const polyChildren = polyTree.childs.filter((child) => !child.isHole);
  if (polyChildren.length === 0) {
    return [];
  }

  const pairsSoFar: PolygonHolePair[] = [];

  const shapeChildren = polyTree.childs.filter((child) => !child.isHole);
  shapeChildren.forEach(shapeChild => {
    getPolygonHolePairsFromPolyNode(shapeChild, pairsSoFar);
  });

  return pairsSoFar;
}

function getPolygonHolePairsFromPolyNode(polyNode: clipperLib.PolyNode, pairsSoFar: PolygonHolePair[]): PolygonHolePair[] {

  const hasContour = polyNode.contour.length > 0;
  const isOpen = polyNode.isOpen;
  if (!hasContour || isOpen) {
    console.error("Invalid Clipper polygon. This should never happen.");
    return pairsSoFar;
  }

  const points = polyNode.contour.map((point: clipperLib.IntPoint) => new Vector2(point.x / CLIPPER_SCALE_FACTOR, point.y / CLIPPER_SCALE_FACTOR));
  const pair: PolygonHolePair = { polygon: points, holes: [] };

  const hasChildren = polyNode.childs.length > 0;
  if (!hasChildren) {
    pairsSoFar.push(pair);
    return pairsSoFar;
  }

  const holeChildren = polyNode.childs.filter((child) => child.isHole);
  holeChildren.forEach(holeChild => {
    const holePoints = holeChild.contour.map((point: clipperLib.IntPoint) => new Vector2(point.x / CLIPPER_SCALE_FACTOR, point.y / CLIPPER_SCALE_FACTOR));
    pair.holes.push(holePoints);
  });

  const shapeChildren = polyNode.childs.filter((child) => !child.isHole);
  shapeChildren.forEach(shapeChild => {
    getPolygonHolePairsFromPolyNode(shapeChild, pairsSoFar);
  });

  pairsSoFar.push(pair);
  return pairsSoFar;
}

/** Do a difference boolean operation between surface and holes. Multiple surface outlines can be returned if a hole bisects the surface */
export function differencePointChunks(
  holeChunks: Vector2[][],
  outline: Vector2[],
  clipperScaleFactor = CLIPPER_SCALE_FACTOR
): [Vector2[][], Vector2[][]] {
  if (!clipper) throw new Error("clipper is not initialized yet");

  const outlinePoly = outline.map((point) => {
    return { x: Math.trunc(point.x * clipperScaleFactor), y: Math.trunc(point.y * clipperScaleFactor) };
  });
  const holePolys = holeChunks.map((points) =>
    points.map((point) => {
      return { x: Math.trunc(point.x * clipperScaleFactor), y: Math.trunc(point.y * clipperScaleFactor) };
    })
  );

  const polyInput = [{ data: [outlinePoly], closed: true }];

  const holeInputs = holePolys.map((poly) => {
    return { data: poly, closed: true };
  });

  const resultPaths = clipper.clipToPaths({
    clipType: clipperLib.ClipType.Difference,
    clipInputs: holeInputs,
    subjectInputs: polyInput,
    subjectFillType: clipperLib.PolyFillType.NonZero,
  });

  const shapeChunks: Vector2[][] = [];
  holeChunks = [];

  resultPaths.forEach((path) => {
    const points = path.map((point) => new Vector2(point.x / CLIPPER_SCALE_FACTOR, point.y / CLIPPER_SCALE_FACTOR));
    if (clipper!.orientation(path)) {
      shapeChunks.push(points);
    } else {
      holeChunks.push(points);
    }
  });

  return [holeChunks, shapeChunks];
}

function segmentListFromPoints(points: Vector2[], offset = 0) {
  const segmentList: number[] = [];
  points.forEach((point, index) => {
    segmentList.push(offset + index);
    segmentList.push(offset + index);
  });
  const firstSegment = segmentList.shift()!;
  segmentList.push(firstSegment);
  return segmentList;
}

function polyFromShapeHoles(shape: Vector2[], holes: Vector2[][] = []): Poly {
  const pointlist: number[] = [];
  shape.forEach((point) => {
    pointlist.push(point.x);
    pointlist.push(point.y);
  });

  holes.forEach((hole) => {
    hole.forEach((point) => {
      pointlist.push(point.x);
      pointlist.push(point.y);
    });
  });

  const numberofpoints = shape.length + holes.length;
  let segmentlist: number[] = segmentListFromPoints(shape);

  holes.forEach((hole) => {
    const offset = segmentlist.length / 2;
    segmentlist = segmentlist.concat(segmentListFromPoints(hole, offset));
  });

  const numberofsegments = segmentlist.length / 2;

  const holelist: number[] = [];
  holes.forEach((hole) => {
    const center = new Vector2();
    hole.forEach((point) => {
      center.add(point);
    });
    center.divideScalar(hole.length);
    holelist.push(center.x);
    holelist.push(center.y);
  });

  const numberofholes = holes.length;

  return {
    pointlist,
    numberofpoints,
    segmentlist,
    numberofsegments,
    holelist,
    numberofholes,
  };
}
