import { Object3D } from "three";
import { Storey } from "./Storey";
import { Apartment } from "./Apartment";
import { getObjectParameters } from "@/three/ThreeJsHelpers";

export class Building {
  public name?: string;
  public globalId?: string;
  public object: Object3D;
  public storeys: Storey[] = [];

  constructor(object: Object3D) {
    const parameters = getObjectParameters(object);
    const name = parameters.get("Name") || parameters.get("LongName") || "";

    const globalId = parameters.get("GlobalId");
    if (!globalId) throw new Error("Unable to find building globalId in ifcParameter");

    this.name = name;
    this.globalId = globalId;
    this.object = object;

    this.appendStoreys();
  }

  public getApartments(): Apartment[] {
    return this.storeys.flatMap((storey) => storey.apartments);
  }

  public getApartment(name: string): Apartment | undefined {
    return this.storeys.map((storey) => storey.apartments.find((apt) => apt.currentName() === name))[0];
  }

  private appendStoreys(): void {
    this.object.children.map((child) => {
      const storey = new Storey(child);
      this.storeys.push(storey);
    });

    const storeyWithChildren = this.storeys.find((storey) => storey.storeyNode.children.length > 0);
    if (this.storeys.length <= 0 || !storeyWithChildren) {
      console.error("No storeys in IFC Gltf file");
    }
  }
}
