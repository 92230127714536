import { Line, Vector3, Line3, Mesh, LineBasicMaterial, BufferGeometry } from "three";
import { PlanarSurface, SURFACE_TYPE } from "./PlanarSurface";
import { setObjectParameters, camelize, createSphereHelper, createLinesHelper } from "@/three/ThreeJsHelpers";
import { Opening } from "../building-objects/Opening";
import * as slugid from "slugid";

import { moldingsPolygonMerger } from "./LineMerger";

let sphereHelpers: Mesh[] = [];

class MoldingLineSegment {
  public startOpening?: Opening;
  public endOpening?: Opening;
  public lines: Line3[] = [];
  public shouldDebug: boolean;
  public splittingHasFailed = false;

  constructor(lines: Line3[], startOpening?: Opening, endOpening?: Opening, shouldDebug = false) {
    this.lines = lines;
    this.startOpening = startOpening;
    this.endOpening = endOpening;
    this.shouldDebug = shouldDebug;
  }

  public splitByOpenings(openings: Opening[]): MoldingLineSegment[] {
    if (!openings.length) return [this];

    let newMoldingSegments: MoldingLineSegment[] = [this];
    while (openings.length) {
      const opening = openings.pop()!;
      newMoldingSegments = MoldingLineSegment.splitByOpening(opening, newMoldingSegments);
    }

    const output = newMoldingSegments.filter((segment) => segment.lines.length);

    return output;
  }

  public static splitByOpening(opening: Opening, segments: MoldingLineSegment[], iteration = 0): MoldingLineSegment[] {
    iteration++;
    if (iteration > 100) {
      console.error(`Infinite loop detected when creating moldings. This should never happen. Please report this issue.`);
      segments.forEach((segment) => segment.splittingHasFailed = true);
      return segments;
    }
    for (const segment of segments) {
      for (const [linesIndex, line] of segment.lines.entries()) {
        if (!opening.edgeIsCutByOpening(line)) continue;

        const nextLinesIndex = (linesIndex + 1) % segment.lines.length;

        const startSplit = segment.lines.slice(0, linesIndex);
        const endSplit = segment.lines.slice(nextLinesIndex, segment.lines.length);

        const segmentStart = segment.lines[0].start;
        const segmentEnd = segment.lines[segment.lines.length - 1].end;
        const linesAreClosed = segments.length == 1 && segmentStart.toFixed(4) === segmentEnd.toFixed(4);

        segments.splice(segments.indexOf(segment), 1);

        if (linesAreClosed) {
          const lines = endSplit.concat(startSplit);
          segments.push(new MoldingLineSegment(lines, opening, opening, segment.shouldDebug));
        } else {
          segments.push(new MoldingLineSegment(startSplit, segment.startOpening, opening, segment.shouldDebug));
          segments.push(new MoldingLineSegment(endSplit, opening, segment.endOpening, segment.shouldDebug));
        }

        return MoldingLineSegment.splitByOpening(opening, segments, iteration);
      }
    }

    return segments;
  }

  private _debugSplitLines(start: Line3[], end: Line3[]) {
    sphereHelpers.forEach((sphereHelper) => sphereHelper.parent?.remove(sphereHelper));
    sphereHelpers = [];

    const startSplitCenters = start.map((line) => line.getCenter(new Vector3()));
    const endSplitCenters = end.map((line) => line.getCenter(new Vector3()));

    startSplitCenters.forEach((center) => {
      const startSphereHelper = createSphereHelper(center, 0xff0000, true, 0.01);
      sphereHelpers.push(startSphereHelper);
    });
    endSplitCenters.forEach((center) => {
      const endSphereHelper = createSphereHelper(center, 0x00ff00, true, 0.01);
      sphereHelpers.push(endSphereHelper);
    });
  }
}

export class SurfaceMoldings extends Line {
  private _startOpening?: Opening;
  private _endOpening?: Opening;

  public get linesVisible() {
    return this.visible;
  }
  public set linesVisible(show: boolean) {
    this.visible = show;
  }

  constructor(
    moldingEdgelines: Line3[],
    surfaceType: SURFACE_TYPE,
    spaceIds: string[],
    startOpening?: Opening,
    endOpening?: Opening
  ) {
    super();
    this.type = "SurfaceMoldings";

    this._startOpening = startOpening;
    this._endOpening = endOpening;

    const parameters = new Map<string, string>();
    parameters.set("type", camelize(this.type));
    parameters.set("surfaceType", surfaceType);
    parameters.set("id", slugid.v4());
    parameters.set("spaceIds", `['${spaceIds.join("', '")}']`);
    this._startOpening && parameters.set("startOpeningGlobalId", this._startOpening.globalId);
    this._endOpening && parameters.set("endOpeningGlobalId", this._endOpening.globalId);

    setObjectParameters(parameters, this);

    const points = moldingEdgelines.map((line) => line.start).concat(moldingEdgelines[moldingEdgelines.length - 1].end);

    this.geometry = new BufferGeometry().setFromPoints(points);
    (this.material! as LineBasicMaterial).color.setHex(0x000000);
  }

  public static clearSurfaceMoldings(surfaceMoldings: SurfaceMoldings[]): SurfaceMoldings[] {
    surfaceMoldings.forEach((surfaceMolding) => {
      surfaceMolding.disposeEdges();
      surfaceMolding.removeFromParent();
    });
    return [];
  }

  private static _cornersToSurfaceMoldings(
    polygon: Line3[],
    spaceIds: string[],
    openings: Opening[],
    surfaceType: SURFACE_TYPE,
    shouldDebug = false
  ): SurfaceMoldings[] {

    const segment = new MoldingLineSegment(polygon, undefined, undefined, shouldDebug);
    const moldingSegments: MoldingLineSegment[] = segment.splitByOpenings(openings);

    moldingSegments.forEach((segment) => {
      if (segment.splittingHasFailed) {
        console.error(`${surfaceType} moldings splitting has failed for spaces: ${spaceIds.join(", ")}`);
      }
    });

    const surfaceMoldings = moldingSegments.map((segment) => {
      return new SurfaceMoldings(segment.lines, surfaceType, spaceIds, segment.startOpening, segment.endOpening);
    });

    return surfaceMoldings;
  }

  private static _openingsFromSurfaces(surfaces: PlanarSurface[]): Opening[] {
    const wallNeighbors = surfaces.flatMap((surface) => surface.getNeighborSurfaces(SURFACE_TYPE.wall));

    return wallNeighbors
      .flatMap((wall) => wall.linkedOpenings)
      .reduce((unique, item) => {
        return unique.includes(item) ? unique : [...unique, item];
      }, [] as Opening[]);
  }

  public static createSurfaceMoldings(surfaces: PlanarSurface[]): SurfaceMoldings[] {
    if (surfaces.length === 0) return [];

    const spaceIds = [...new Set(surfaces.map((surface) => surface.room.roomId))];
    const surfaceType = surfaces[0].surfaceType;
    const isCeiling = surfaceType === SURFACE_TYPE.ceiling;

    const openings: Opening[] = this._openingsFromSurfaces(surfaces);

    const filteredOpenings = openings.filter((opening) => {
      if (opening.UVsTraverseOpening) return true;

      const tangentialSurfaces = opening.tangentialSurfaces.filter((surface) => surface.surfaceType === surfaceType);
      if (!tangentialSurfaces.length) return true;

      const openingHasTwoSurfaces = tangentialSurfaces.length === 2;
      if (!openingHasTwoSurfaces) return true;

      const openingHasBothTangentSurfacesInTheseSurfaces = tangentialSurfaces.every((tangentSurface) => surfaces.includes(tangentSurface));
      if (openingHasBothTangentSurfacesInTheseSurfaces) {
        // opening is not needed since both molding polygons will be merged
        return false;
      }

      return true;
    });

    let cornerChunks = surfaces.map(surface => surface.planarCorners.map((point) => surface.localToWorld(point.clone())));
    cornerChunks = isCeiling ? cornerChunks.map(corners => corners.reverse()) : cornerChunks;

    cornerChunks.map((chunk) => {
      chunk = chunk.filter((point, index) => {
        const nextIndex = (index + 1) % chunk.length;
        const start = point;
        const end = chunk[nextIndex];
        const distanceSq = start.distanceToSquared(end);
        if (distanceSq < 0.0001) return false;
        return true;
      });
    });

    let moldingPolygons: Line3[][] = cornerChunks.map((corners) => {
      const moldingLines: Line3[] = [];
      corners.forEach((point, index) => {
        const nextIndex = (index + 1) % corners.length;
        moldingLines.push(new Line3(point, corners[nextIndex]));
      });
      return moldingLines;
    });

    const debugIds = ["0HoWPQFXH05gX8X_dw_I4V"];

    const shouldDebug = spaceIds.some((spaceId) => debugIds.includes(spaceId));

    if (shouldDebug) {
      console.log(`Debugging moldings for spaces: ${spaceIds.join(", ")}`);
    }

    moldingPolygons = moldingsPolygonMerger(moldingPolygons, spaceIds, shouldDebug);

    const surfaceMoldings = moldingPolygons.flatMap((polygon) =>
      this._cornersToSurfaceMoldings(polygon, spaceIds, filteredOpenings, surfaceType, shouldDebug)
    );

    // TODO: Look at the subsequent code and see if the assumptions still hold true

    let holeCornerChunks = surfaces.flatMap((surface) =>
      surface.primordialHoles.map((hole) => hole.map((point) => surface.localToWorld(new Vector3(point.x, point.y, 0))))
    );

    if (holeCornerChunks.length === 0) return surfaceMoldings;

    holeCornerChunks = isCeiling ? holeCornerChunks : holeCornerChunks.map((corners) => corners.reverse());

    const holePolygons = holeCornerChunks.map((corners) => {
      const moldingLines: Line3[] = [];
      corners.forEach((point, index) => {
        const nextIndex = (index + 1) % corners.length;
        moldingLines.push(new Line3(point, corners[nextIndex]));
      });
      return moldingLines;
    });

    const holeSurfaceMoldings = holePolygons.flatMap((holePolygon) =>
      this._cornersToSurfaceMoldings(holePolygon, spaceIds, [], surfaceType)
    );

    return surfaceMoldings.concat(holeSurfaceMoldings);
  }

  public disposeEdges(): void {
    this.geometry.dispose();
  }
}
