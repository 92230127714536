// from: https://answers.unity.com/questions/467614/what-is-the-source-code-of-quaternionlookrotation.html

import { Quaternion, Vector3 } from "three";

declare module "three/src/math/Quaternion.js" {
  interface Quaternion {
    /** Creates a rotation with the specified forward and upwards directions. Given directions are assumed to be normalized */
    lookRotation: (this: Quaternion, forward: Vector3, upward: Vector3) => Quaternion;
  }
}

Quaternion.prototype.lookRotation = function (this: Quaternion, forward: Vector3, upward: Vector3): Quaternion {
  const rightward = new Vector3().crossVectors(upward, forward).normalize();

  const m00 = rightward.x;
  const m01 = rightward.y;
  const m02 = rightward.z;
  const m10 = upward.x;
  const m11 = upward.y;
  const m12 = upward.z;
  const m20 = forward.x;
  const m21 = forward.y;
  const m22 = forward.z;

  const num8 = m00 + m11 + m22;
  if (num8 > 0) {
    let num = Math.sqrt(num8 + 1);
    this.w = num * 0.5;
    num = 0.5 / num;
    this.x = (m12 - m21) * num;
    this.y = (m20 - m02) * num;
    this.z = (m01 - m10) * num;
    return this;
  }
  if (m00 >= m11 && m00 >= m22) {
    const num7 = Math.sqrt(1 + m00 - m11 - m22);
    const num4 = 0.5 / num7;
    this.x = 0.5 * num7;
    this.y = (m01 + m10) * num4;
    this.z = (m02 + m20) * num4;
    this.w = (m12 - m21) * num4;
    return this;
  }
  if (m11 > m22) {
    const num6 = Math.sqrt(1 + m11 - m00 - m22);
    const num3 = 0.5 / num6;
    this.x = (m10 + m01) * num3;
    this.y = 0.5 * num6;
    this.z = (m21 + m12) * num3;
    this.w = (m20 - m02) * num3;
    return this;
  }
  const num5 = Math.sqrt(1 + m22 - m00 - m11);
  const num2 = 0.5 / num5;
  this.x = (m20 + m02) * num2;
  this.y = (m21 + m12) * num2;
  this.z = 0.5 * num5;
  this.w = (m01 - m10) * num2;

  return this;
};
