import axios, { AxiosInstance } from "axios";
import Keycloak from "keycloak-js";

const MIN_TOKEN_VALIDITY_SECONDS = 30 

const createAxiosInstance = (baseUrl: string, keycloak: Keycloak): AxiosInstance => {
  const axiosInstance = axios.create({ baseURL: baseUrl });

  axiosInstance.interceptors.request.use((config) => {
    config.headers!["Authorization"] = "Bearer " + keycloak.token;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshed = await keycloak.updateToken(MIN_TOKEN_VALIDITY_SECONDS)
        if (refreshed) {
          return axiosInstance(originalRequest);
        } else {
          throw new Error('Failed to refresh the token: ' + error)
        }
      } else {
        throw error
      }
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
