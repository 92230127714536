import { Object3D, Raycaster, Vector2, Intersection } from "three";
import { Opening } from "@/three/building-objects/Opening";
import { ThreeJsContext } from "../ThreeJsContext";
import { OpeningGizmo } from "../objects/OpeningGizmo";
import { PlanarSurface } from "../objects/PlanarSurface";
import { apartmentEventDispatcher } from "@/events/apartments";
import { Apartment } from "../building-objects/Apartment";

export class OpeningDragController {
  private enabled?: boolean;
  private raycaster = new Raycaster();
  private hoveredGizmo?: OpeningGizmo;
  private selectedGizmo?: OpeningGizmo;
  private currentOpenings: Opening[] = [];
  private pointer: Vector2 = new Vector2();
  private threeJsContext: ThreeJsContext;
  private onKeyDownFactory: (event: KeyboardEvent) => void;
  private onKeyUpFactory: (event: KeyboardEvent) => void;
  private onPointerMoveFactory: (event: PointerEvent) => void;
  private onPointerDownFactory: (event: PointerEvent) => void;
  private onPointerCancelFactory: (event: PointerEvent) => void;
  private onShowCurrentApartmentFactory: (event: any) => void;

  constructor(threeJsContext: ThreeJsContext) {
    this.threeJsContext = threeJsContext;

    this.raycaster.params.Line && (this.raycaster.params.Line.threshold = 0.075);
    this.raycaster.params.Points && (this.raycaster.params.Points.threshold = 0.075);

    this.onShowCurrentApartmentFactory = (event: any) => {
      this.onShowApartment(event);
    };

    this.onKeyDownFactory = (event: KeyboardEvent) => {
      this.onKeyDown(event);
    };
    this.onKeyUpFactory = (event: KeyboardEvent) => {
      this.onKeyUp(event);
    };
    this.onPointerMoveFactory = (event: PointerEvent) => {
      this.onPointerMove(event);
    };
    this.onPointerDownFactory = (event: PointerEvent) => {
      this.onPointerDown(event);
    };
    this.onPointerCancelFactory = (event: PointerEvent) => {
      this.onPointerCancel();
    };
  }

  private onShowApartment(event: Event) {
    this.deactivate();
    this.activate();
  }

  private dragStart(gizmo: OpeningGizmo, raycaster: Raycaster): void {
    this.selectedGizmo = gizmo;
    this.selectedGizmo.startMove(raycaster);
    this.threeJsContext.domElement.style.cursor = "move";
    this.threeJsContext.orbitControls.enabled = false;
  }
  private dragEnd(): void {
    this.threeJsContext.orbitControls.enabled = true;
    this.selectedGizmo = undefined;
    this.threeJsContext.domElement.style.cursor = this.hoveredGizmo ? "pointer" : "auto";
  }

  private hoverOn(gizmo: OpeningGizmo, intersections: Intersection[]): void {
    const openingIntersections = intersections.filter((intersection) => {
      return intersection.object?.parent === gizmo;
    });

    this.threeJsContext.domElement.style.cursor = "pointer";
    this.hoveredGizmo = gizmo;

    gizmo.setIntersections(openingIntersections);
  }

  private hoverOff(): void {
    if (this.hoveredGizmo) {
      this.threeJsContext.domElement.style.cursor = "auto";
      this.hoveredGizmo && this.hoveredGizmo.setColor(0x0000ff);
      this.hoveredGizmo = undefined;
    }
  }

  public activate() {
    this.threeJsContext.domElement.style.cursor = "auto";
    const currentApartments = this.threeJsContext.currentApartments;
    if (!currentApartments.length) return;

    const openingObjects = currentApartments.flatMap((apt) => apt.openingsNode.children);
    this.currentOpenings = openingObjects.filter((opening) => opening.type === "Opening") as Opening[];

    this.currentOpenings.forEach((opening) => {
      opening.showGizmos(true);
      this.currentOpenings.push(opening);
    });

    window.addEventListener("keydown", this.onKeyDownFactory);
    window.addEventListener("keyup", this.onKeyUpFactory);
    this.threeJsContext.domElement.addEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.addEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.addEventListener("pointerup", this.onPointerCancelFactory);
    this.threeJsContext.domElement.addEventListener("pointerleave", this.onPointerCancelFactory);
    apartmentEventDispatcher.addEventListener("showCurrentApartment", this.onShowCurrentApartmentFactory);

    this.enabled = true;
  }

  public deactivate() {
    this.currentOpenings.forEach((opening) => {
      opening.showGizmos(false);
    });
    this.currentOpenings.length = 0;

    window.removeEventListener("keydown", this.onKeyDownFactory);
    window.removeEventListener("keyup", this.onKeyUpFactory);
    this.threeJsContext.domElement.removeEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.removeEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.removeEventListener("pointerup", this.onPointerCancelFactory);
    this.threeJsContext.domElement.removeEventListener("pointerleave", this.onPointerCancelFactory);
    apartmentEventDispatcher.removeEventListener("showCurrentApartment", this.onShowCurrentApartmentFactory);

    this.threeJsContext.domElement.style.cursor = "";

    this.enabled = false;
  }

  private updatePointer(event: PointerEvent) {
    const rect = this.threeJsContext.domElement.getBoundingClientRect();

    this.pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (!this.enabled) return;
    if (event.key === "Control") {
      const element = this.threeJsContext.domElement;
      element.style.cursor == "auto" && (element.style.cursor = "crosshair");
    }
  }

  private onKeyUp(event: KeyboardEvent): void {
    if (!this.enabled) return;
    if (event.key === "Control") {
      const element = this.threeJsContext.domElement;
      element.style.cursor == "crosshair" && (element.style.cursor = "auto");
    }
  }

  private onPointerMove(event: PointerEvent): void {
    if (!this.enabled) return;
    const raycaster = this.raycaster;
    this.updatePointer(event);

    if (this.selectedGizmo) {
      raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
      this.selectedGizmo.move(raycaster);
      return;
    }

    raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    const intersections = raycaster.intersectObjects(this.getIntersectableObjects(), true);

    this.hoverOff();

    if (intersections.length <= 0) return;

    const closestGizmo: OpeningGizmo | undefined = OpeningGizmo.closestGizmoFromIntersections(intersections, raycaster);

    if (!closestGizmo) return;
    this.hoverOn(closestGizmo!, intersections);
  }

  private getIntersectableObjects(): Object3D[] {
    return (this.currentOpenings as Object3D[]).concat(this.getApartmentSurfaces());
  }

  private getApartmentSurfaces(): PlanarSurface[] {
    const apartments = ThreeJsContext.getInstance().currentApartments;
    return apartments.flatMap((apt) => apt.getSurfaces());
  }

  private onPointerDown(event: PointerEvent): void {
    if (!this.enabled || event.button != 0) return;
    const raycaster = this.raycaster;

    this.updatePointer(event);

    raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);

    if (!this.hoveredGizmo && event.ctrlKey) {
      this._createNewHoveredOpening(raycaster);
    }
    this.hoveredGizmo && this.dragStart(this.hoveredGizmo, raycaster);
  }

  private _currentSurfaceFromRaycaster(raycaster: Raycaster): PlanarSurface | undefined {
    const apartmentSurfaces = this.getApartmentSurfaces();
    const intersections = raycaster.intersectObjects(apartmentSurfaces, false);
    return intersections[0].object as PlanarSurface;
  }

  private _currentApartmentFromSurface(surface: PlanarSurface): Apartment | undefined {
    const currentApartments = ThreeJsContext.getInstance().currentApartments;
    const currentApartment = currentApartments.find((apt) => apt.getSurfaces().includes(surface));

    return currentApartment;
  }

  private _createNewHoveredOpening(raycaster: Raycaster): void {
    const apartmentSurfaces = this.getApartmentSurfaces();
    const intersections = raycaster.intersectObjects(apartmentSurfaces, false);
    const currentSurface = this._currentSurfaceFromRaycaster(raycaster);
    if (!currentSurface) return;
    const currentApartment = this._currentApartmentFromSurface(currentSurface);

    const allApartments = ThreeJsContext.getInstance().getApartments();
    const allSurfaces = allApartments.flatMap((apartment) => apartment.getSurfaces());

    if (intersections.length && currentSurface && currentApartment) {
      const position = intersections[0].point;
      const normal = (intersections[0].object as PlanarSurface).normal.clone();
      const bitangent = (intersections[0].object as PlanarSurface).bitangent.clone();

      const newOpening = Opening.fromPositionAndDirections(position, normal, bitangent, currentApartment);
      this.currentOpenings.push(newOpening);

      const hitSurfacesFromOpening = newOpening.attachToHitSurfaces(allSurfaces);

      if (hitSurfacesFromOpening.length) {
        const attachedApartments = allApartments.filter((apt) =>
          hitSurfacesFromOpening.some((surface) => apt.getSurfaces().includes(surface))
        );

        let baseApartment: Apartment | undefined = undefined;

        if (currentApartment.hasEntrance) {
          baseApartment = currentApartment;
        } else {
          baseApartment = attachedApartments.find((apt) => apt.hasEntrance);
        }

        if (!baseApartment) {
          console.warn("Neither of the linked apartments has an entrance.");
          baseApartment = currentApartment;
        }

        attachedApartments.forEach((apt) => {
          if (baseApartment && baseApartment.currentName() && apt.currentName() != baseApartment.currentName()) {
            apt.setCurrentName(baseApartment.currentName());
            apt.setCurrentId(baseApartment.currentId());
            apartmentEventDispatcher.showCurrentApartment(false);
          }
        });
      }

      newOpening.showGizmos(true);

      this.hoveredGizmo = newOpening.gizmos[0];

      this.hoveredGizmo.activateCornersEdges(2);
      this.hoveredGizmo.highlightActiveCornersEdges();
    }
  }

  private _destroyOpeningFromGizmo(gizmo: OpeningGizmo): void {
    const currentSurface = gizmo.surface;

    const currentApartment = currentSurface.apartment;

    const otherApartment = gizmo.opening.getLinkedApartments().find((apt) => apt != currentApartment);

    gizmo.opening.dispose();

    if (!otherApartment) return;

    const connectedApartments = otherApartment.getAllConnectedApartments();

    if (!connectedApartments) return;

    const isStillConnected = connectedApartments.some((apt) => apt === currentApartment);

    if (!isStillConnected) {
      otherApartment.resetName();
      otherApartment.resetId();
      apartmentEventDispatcher.showCurrentApartment(false);
    }
  }

  private onPointerCancel() {
    if (!this.enabled) {
      return;
    }
    if (this.selectedGizmo) {
      const area = this.selectedGizmo.opening.scale.x * this.selectedGizmo.opening.scale.y;
      if (area < 0.05) {
        this._destroyOpeningFromGizmo(this.selectedGizmo);
      }
      this.dragEnd();
    }
  }
}
