

















import Vue from "vue";
import { DataTableHeader } from "vuetify";
import { backend, BuildingPlan } from "@/lib/backend";

type ComponentData = {
  buildingPlans: BuildingPlan[];
  buildingPlanHeaders: DataTableHeader[];
};

export default Vue.extend({
  name: "BuildingPlansTable",
  props: {
    projectId: { type: String },
  },

  data(): ComponentData {
    return {
      buildingPlans: [],
      buildingPlanHeaders: [
        { text: "Uploaded At", value: "uploadedAt" },
        { text: "Name", value: "name" },
        { text: "GLB Actions", value: "actions", align: "center", sortable: false },
      ],
    };
  },

  watch: {
    async projectId(value) {
      if (!value) this.buildingPlans = [];
      else this.buildingPlans = await backend.getBuildingPlans(this.projectId);
    },
  },

  methods: {
    download(url: string) {
      window.open(url, "_blank")
    },
  },
});
