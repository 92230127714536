import { EventDispatcher } from "three";
import router from "@/router";

class ApartmentEventDispatcher extends EventDispatcher {
  showCurrentApartment(focusCamera = true) {
    this.dispatchEvent({
      type: "showCurrentApartment",
      apartmentName: router.currentRoute.params.apartment,
      focusCamera: focusCamera,
    });
  }
  hideAllApartments() {
    this.dispatchEvent({
      type: "hideAllApartments",
    });
  }
}

export const apartmentEventDispatcher = new ApartmentEventDispatcher();
