import {
  Raycaster,
  Vector2,
  Intersection,
  LineSegments,
  EdgesGeometry,
  BoxGeometry,
  LineBasicMaterial,
  Mesh,
  Material,
  MeshStandardMaterial,
} from "three";
import { ThreeJsContext } from "@/three/ThreeJsContext";
import { Surface } from "@/interfaces/Surface";
import { PlanarSurface } from "../objects/PlanarSurface";

export class SurfaceDragController {
  private static emptyMaterial: MeshStandardMaterial;
  private enabled = false;
  private dragAndDropSurfaceTexture?: Material;
  private _currentPlanarSurface?: PlanarSurface;
  private currentPlanarSurfaceMaterial?: Material;
  private raycaster = new Raycaster();
  private pointer = new Vector2();
  // TODO: Make intersections local to respective functions
  private intersections: Intersection[] = [];
  private threeJsContext: ThreeJsContext;
  private isClick = false;
  private onPointerMoveFactory: (event: PointerEvent) => void;
  private onPointerUpFactory: (event: PointerEvent) => void;
  private onPointerCancelFactory: (event: PointerEvent) => void;

  constructor(threeJsContext: ThreeJsContext) {
    this.threeJsContext = threeJsContext;

    if (!SurfaceDragController.emptyMaterial) {
      SurfaceDragController.emptyMaterial = new MeshStandardMaterial();
      SurfaceDragController.emptyMaterial.visible = false;
      SurfaceDragController.emptyMaterial.alphaTest = 1;
      SurfaceDragController.emptyMaterial.opacity = 1;
    }

    // TODO: not used, can be removed?
    const lineSegments = new LineSegments(
      new EdgesGeometry(new BoxGeometry(1, 1, 1)),
      new LineBasicMaterial({
        color: 0x00ff00,
        depthTest: false,
        toneMapped: false,
      })
    );

    this.onPointerMoveFactory = (event: PointerEvent) => {
      this.onPointerMove(event);
    };
    this.onPointerUpFactory = (event: PointerEvent) => {
      this.onPointerUp(event);
    };
    this.onPointerCancelFactory = (event: PointerEvent) => {
      this.onCancel();
    };
  }

  private get currentPlanarSurface() {
    return this._currentPlanarSurface;
  }

  private set currentPlanarSurface(value) {
    if (this.currentPlanarSurfaceMaterial && this.currentPlanarSurfaceMaterial) {
      this._currentPlanarSurface!.material = this.currentPlanarSurfaceMaterial;
    }
    this._currentPlanarSurface = value;
  }

  public async setSelectedSurface(surface: Surface) {
    const gltfAsset = await this.threeJsContext.loadGltf(surface.downloadUrl);
    if (gltfAsset) {
      this.dragAndDropSurfaceTexture = (gltfAsset.children[0] as any).material;
    } else {
      this.dragAndDropSurfaceTexture = SurfaceDragController.emptyMaterial;
    }
  }

  public activate() {
    this.threeJsContext.domElement.addEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.addEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.addEventListener("pointerleave", this.onPointerCancelFactory);
    this.enabled = true;
  }

  public deactivate() {
    this.threeJsContext.domElement.style.cursor = "auto";
    this.threeJsContext.domElement.removeEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.removeEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.removeEventListener("pointerleave", this.onPointerCancelFactory);
    this.enabled = false;
  }

  private onPointerUp(event: PointerEvent): void {
    if (!this.enabled || event.button !== 0) {
      return;
    }

    this.currentPlanarSurfaceMaterial = undefined;
    this.currentPlanarSurface = undefined;
  }

  private onPointerMove(event: PointerEvent): void {
    if (!this.enabled) {
      return;
    }

    this.updatePointer(event);
    const apartmentSurfaces = this.getIntersectableSurfaces();

    if (apartmentSurfaces.length == 0) {
      return;
    }

    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.raycaster.intersectObjects(apartmentSurfaces, true, this.intersections);

    if (!this.intersections.length) {
      if (this.currentPlanarSurface && this.currentPlanarSurfaceMaterial) {
        this.currentPlanarSurface.material = this.currentPlanarSurfaceMaterial!;
        this.currentPlanarSurface = undefined;
        this.currentPlanarSurfaceMaterial = undefined;
      }
      return;
    }

    if (!this.dragAndDropSurfaceTexture) {
      return;
    }

    this.currentPlanarSurface = this.intersections[0].object as PlanarSurface;
    this.currentPlanarSurfaceMaterial = this.currentPlanarSurface.material!;

    if (this.currentPlanarSurface.material !== this.dragAndDropSurfaceTexture) {
      this.currentPlanarSurface.material = this.dragAndDropSurfaceTexture!;
    }
  }

  private onCancel() {
    if (!this.enabled) {
      return;
    }
    this.threeJsContext.orbitControls.enabled = true;
  }

  private updatePointer(event: PointerEvent | DragEvent): void {
    const rect = this.threeJsContext.domElement.getBoundingClientRect();
    const oldX = this.pointer.x;
    const oldY = this.pointer.y;

    this.pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;

    if (this.isClick) {
      const delta = new Vector2(this.pointer.x - oldX, this.pointer.y - oldY);
      if (Math.abs(delta.x) > 0.003 || Math.abs(delta.y) > 0.003) {
        this.isClick = false;
      }
    }
  }

  private getIntersectableSurfaces(): Mesh[] {
    const apartments = this.threeJsContext.currentApartments;
    return apartments.flatMap((apt) => apt.getSurfaces());
  }
}
