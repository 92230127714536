var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"camera-menu"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.placeCamera()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("Add new")])])]}}])},[_c('span',[_vm._v("Create new camera to drag")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.snapSelectedCameraToView()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("To view")])])]}}])},[_c('span',[_vm._v("Snap selected camera to view")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.removeSelectedCamera()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("Remove")])])]}}])},[_c('span',[_vm._v("Remove selected camera")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }