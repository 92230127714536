import { Vector3, Triangle } from "three";

declare module "three/src/math/Triangle.js" {
  interface Triangle  {
    getCornerByIndex: (this: Triangle, index: number) => Vector3;
  }
}

Triangle.prototype.getCornerByIndex = function (this: Triangle, index: number): Vector3 {
  if (index === 0) return this.a;
  else if (index === 1) return this.b;
  else return this.c;
}
