import { Module } from "vuex";
import { Surface } from "@/interfaces/Surface";
import { RootState } from ".";

export const isAnSurfaceSelected = "isAnSurfaceSelected";
export const currentSurfaceTypeId = "currentSurfaceTypeId";
export const allSurfaces = "allSurfaces";
export const setIsAnSurfaceSelected = "setIsAnSurfaceSelected";
export const setCurrentSurfaceTypeId = "setCurrentSurfaceTypeId";
export const setAllSurfaces = "setAllSurfaces";

export interface SurfacesState {
  isAnSurfaceSelected: boolean;
  currentSurfaceTypeId: string;
  allSurfaces: Array<Surface>;
}

const surfaces: Module<SurfacesState, RootState> = {
  state: {
    isAnSurfaceSelected: false,
    currentSurfaceTypeId: "",
    allSurfaces: [],
  },
  getters: {
    [isAnSurfaceSelected]: (state: SurfacesState): boolean => {
      return state.isAnSurfaceSelected;
    },
    [currentSurfaceTypeId]: (state: SurfacesState): string => {
      return state.currentSurfaceTypeId;
    },
    [allSurfaces]: (state: SurfacesState): Surface[] => {
      return state.allSurfaces;
    },
  },
  mutations: {
    [setIsAnSurfaceSelected]: (state: SurfacesState, payload: boolean): void => {
      state.isAnSurfaceSelected = payload;
    },
    [setCurrentSurfaceTypeId]: (state: SurfacesState, payload: string): void => {
      state.currentSurfaceTypeId = payload;
    },
    [setAllSurfaces]: (state: SurfacesState, payload: Surface[]): void => {
      state.allSurfaces = payload;
    },
  },
  actions: {},
  modules: {},
};

export default surfaces;
