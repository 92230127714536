import { Mesh, Object3D } from "three";
import { getObjectParameters } from "@/three/ThreeJsHelpers";
import { PlanarSurface, SURFACE_TYPE, SURFACE_CATEGORY } from "../objects/PlanarSurface";
import { SurfacesGenerator } from "../objects/SurfacesGenerator";
import { SurfaceMoldings } from "../objects/SurfaceMoldings";
import { Apartment } from "./Apartment";
import { Opening } from "./Opening";
import * as slugid from "slugid";

export enum ROOM_CATEGORY {
  Unknown,
  Balcony,
  Bathroom,
  Bedroom,
  Diningroom,
  Entrance,
  Generic,
  Hallway,
  Kitchen,
  Livingroom,
  Recreational,
  Office,
  Storage,
  Techroom,
  WC,
}

export class Room {
  public readonly name: string;
  public readonly longName: string;
  public readonly roomId: string;
  public readonly category: ROOM_CATEGORY = ROOM_CATEGORY.Unknown;
  public readonly spaceMesh: Mesh;
  public readonly moldingsNode: Object3D;
  public readonly apartment: Apartment;
  private _openings: Opening[] = [];
  public get openings(): Opening[] {
    return this._openings;
  }

  private _moldings: SurfaceMoldings[] = [];
  public get moldings(): SurfaceMoldings[] {
    return this._moldings;
  }

  private _planarSurfaces: PlanarSurface[] = [];
  public get planarSurfaces(): PlanarSurface[] {
    return this._planarSurfaces;
  }

  /** Connected floors in order of area. Largest first. If this room has connected floors then this room has the biggest floor. */
  public connectedFloors?: PlanarSurface[];

  constructor(mesh: Mesh, apartment: Apartment) {
    const parameters = getObjectParameters(mesh);
    this.apartment = apartment;
    this.moldingsNode = apartment.moldingsNode;
    const name = parameters.get("Name") || parameters.get("LongName") || "";

    this.roomId = parameters.get("GlobalId") || slugid.v4();
    if (!this.roomId) throw new Error("Unable to find room globalId in ifcParameter");
    this.roomId = this.roomId ? this.roomId : "";

    this.name = name;
    this.longName = parameters.get("LongName") || "";
    this.spaceMesh = mesh;
    this.spaceMesh.userData.isRoomSpace = true;

    const categoryString = parameters.get("Category") || "Unknown";
    this.category = ROOM_CATEGORY[categoryString as keyof typeof ROOM_CATEGORY];

    const surfaceGenerator = new SurfacesGenerator(this, this.apartment);

    this._planarSurfaces = surfaceGenerator.planarSurfaces;
  }

  public addOpening(opening: Opening): void {
    const openingIds = this._openings.map((opening) => opening.globalId);
    if (openingIds.includes(opening.globalId)) return;
    this._openings.push(opening);

    opening.addRoom(this);
  }

  public removeOpening(opening: Opening): void {
    this._openings = this._openings.filter((opn) => opn !== opening);

    opening.removeRoom(this);
  }

  public assignMainRoomSurfaces(): void {

    const traversableMainDoorWalls = this.planarSurfaces.filter((surface) =>
      surface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall)
    );

    if (traversableMainDoorWalls.length != 1) return;

    const connectedTraversableOpenings = this._openings.filter(
      (opening) => ![...opening.linkedSurfacesByIds.values()].includes(traversableMainDoorWalls[0])
    );

    connectedTraversableOpenings.forEach((traversableOpening) => {
      const otherRoomSurfaces = [...traversableOpening.linkedSurfacesByIds.values()].filter((surface) => surface.room !== this);
      if (otherRoomSurfaces.length == 0) return;
      if (otherRoomSurfaces.length > 1) {
        console.warn(`More than one room connected to traversable opening ${traversableOpening.guid}. This is not supported.`);
        return;
      }

      const otherRoomSurface = otherRoomSurfaces[0];
      const otherSurfaceContainsMainDoorWall = otherRoomSurface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall);

      if (otherSurfaceContainsMainDoorWall) return;

      const otherRoom = otherRoomSurface.room;

      const doesOtherRoomContainMainDoorWall = otherRoom.planarSurfaces.some((surface) =>
        surface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall)
      );
      if (doesOtherRoomContainMainDoorWall) return;

      otherRoomSurface.AddToSurfaceCategories([SURFACE_CATEGORY.mainDoorWall]);

      otherRoom.assignMainRoomSurfaces();
    });
  }

  /** Based on the type of surface. Create molding edges based on the _planarCorners of the last updated geometry*/
  public createSurfaceMoldings(accumulatedSurfaceGuids: string[]): string[] {
    this._moldings = SurfaceMoldings.clearSurfaceMoldings(this._moldings);

    const ceilingSurfaces = this._planarSurfaces
      .filter((surface) => surface.surfaceType == SURFACE_TYPE.ceiling)
      .filter((surface) => !accumulatedSurfaceGuids.includes(surface.guid));

    const floorSurfaces = this._planarSurfaces
      .filter((surface) => surface.surfaceType == SURFACE_TYPE.floor)
      .filter((surface) => !accumulatedSurfaceGuids.includes(surface.guid));

    const ceilingSurfaceGroups = PlanarSurface.splitIntoSurfaceGroups(ceilingSurfaces);
    const floorSurfaceGroups = PlanarSurface.splitIntoSurfaceGroups(floorSurfaces);

    const surfaceGroups = [...ceilingSurfaceGroups, ...floorSurfaceGroups];

    this._moldings = surfaceGroups.flatMap((surfaceGroup) => SurfaceMoldings.createSurfaceMoldings(surfaceGroup));

    this._moldings.forEach((molding) => this.moldingsNode.add(molding));

    const newSurfaceIds = [...ceilingSurfaceGroups.flat(), ...floorSurfaceGroups.flat()].map((surface) => surface.guid);

    accumulatedSurfaceGuids.push(...newSurfaceIds);

    return [...new Set(accumulatedSurfaceGuids)]
  }
}
