import { Vector3 } from "three";

const DEFAULT_PRECISION = 2;

declare module "three/src/math/Vector3.js" {
  interface Vector3 {
    /** Approximate this vector to given decimal places. */
    approximate: (this: Vector3, places: number) => Vector3;
    /** Returns a string where x, y, and z are represented either in exponential or fixed-point notation with a specified number of digits. */
    toFixed: (this: Vector3, precision?: number) => string;
    /** Returns a Vector3 from a string representing a value converted to string via the toPrecision function. */
    fromFixed: (this: Vector3, serializedValue: string) => Vector3;
    /** Checks for approximate equality of this vector and other. */
    hashEquals: (this: Vector3, other: Vector3, hashPlaces?: number) => boolean;
    /** Checks for approximate equality of this vector and other. */
    isApproximate: (this: Vector3, other: Vector3, precision?: number) => boolean;
  }
}

Vector3.prototype.toFixed = function (this: Vector3, precision = DEFAULT_PRECISION): string {
  const approximated = this.clone().approximate(precision);
  return `${approximated.x.toFixed(precision)},${approximated.y.toFixed(precision)},${approximated.z.toFixed(precision)}`;
};

Vector3.prototype.fromFixed = function (this: Vector3, serializedValue: string): Vector3 {
  const [x, y, z] = serializedValue.split(",");
  this.x = parseFloat(x);
  this.y = parseFloat(y);
  this.z = parseFloat(z);

  return this;
};

Vector3.prototype.approximate = function (this: Vector3, precision: number): Vector3 {
  const power = Math.pow(10, precision);
  const factor = 1 / power;
  this.x = Math.round((this.x + Number.EPSILON) * power) * factor;
  this.y = Math.round((this.y + Number.EPSILON) * power) * factor;
  this.z = Math.round((this.z + Number.EPSILON) * power) * factor;

  return this;
};

Vector3.prototype.hashEquals = function (this: Vector3, other: Vector3, precision = DEFAULT_PRECISION): boolean {
  return this.toFixed(precision) === other.toFixed(precision);
};

Vector3.prototype.isApproximate = function (this: Vector3, other: Vector3, precision = DEFAULT_PRECISION): boolean {
  return this.approximate(precision).equals(other.approximate(precision));
}