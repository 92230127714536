import { Vector2, Vector3, Line3, Raycaster, Plane, BufferGeometry, Mesh, Material, Float32BufferAttribute } from "three";
import { Line2 } from "../math/Line2";
import { intersectionLineChunks } from "../tools/PolygonTools";
import * as slugid from "slugid";
import { ThreeJsContext } from "../ThreeJsContext";
import store, { setUvDebug } from "@/store";
import { getObjectParameters, setObjectParameters } from "../ThreeJsHelpers";
import { Apartment } from "../building-objects/Apartment";
import { Room } from "../building-objects/Room";
import { Opening } from "../building-objects/Opening";
import { SurfaceGeoCreator } from "./SurfaceGeoCreator";

const UP = new Vector3(0, 1, 0);
const DOWN = new Vector3(0, -1, 0);

export enum SURFACE_TYPE {
  wall = "wall",
  ceiling = "ceiling",
  floor = "floor",
}

export enum SURFACE_CATEGORY {
  mainDoorWall = "mainDoorWall",
  leftOfMainDoorWall = "leftOfMainDoorWall",
  oppositeOfMainDoorWall = "oppositeOfMainDoorWall",
  rightOfMainDoorWall = "rightOfMainDoorWall",
  windowWall = "windowWall",
  traversableSurface = "traversableSurface",
  towardsBalconyWall = "towardsBalconyWall",
  slanted = "slanted",
  pillarWall = "pillarWall",
}

export class SurfaceData {
  public worldOutline: Line3[];
  public worldHoles: Line3[][];
  public direction: Vector3;

  public constructor(worldOutline: Line3[], worldHoles: Line3[][], direction: Vector3) {
    this.worldOutline = worldOutline;
    this.worldHoles = worldHoles;
    this.direction = direction;
  }
}

export class PlanarSurface extends Mesh {
  //#region Fields and properties
  public readonly type = "PlanarSurface";

  public declare material: Material;

  /** Assigned surface material, in case we are temporarily using another material */
  private assignedSurfaceMaterial: Material;
  public surfaceType: SURFACE_TYPE;

  private surfaceCategories: SURFACE_CATEGORY[] = [];

  public getSurfaceCategories(): SURFACE_CATEGORY[] {
    return [...this.surfaceCategories]
  }

  public hasSurfaceCategory(category: SURFACE_CATEGORY): boolean {
    return this.surfaceCategories.includes(category);
  }

  public hasSurfaceCategories(categories: SURFACE_CATEGORY[]): boolean {
    return categories.some((category) => this.surfaceCategories.includes(category));
  }

  public AddToSurfaceCategories(categories: SURFACE_CATEGORY[], categoryIndex: number = 0): void {
    if (!categories || categories.length === 0) return;
    this.surfaceCategories = [...this.surfaceCategories, ...categories];
    this.surfaceCategories = [...new Set(this.surfaceCategories)];

    const surfaceParameters = getObjectParameters(this);
    surfaceParameters.set("surfaceCategories", `['${this.surfaceCategories.join("', '")}']`);
    surfaceParameters.set("surfaceCategoryIndex", categoryIndex.toString());

    setObjectParameters(surfaceParameters, this);
  }

  private _linkedOpenings: Opening[] = [];
  public get linkedOpenings() {
    return this._linkedOpenings;
  }
  /* Openings that are cutting this surface */
  public set linkedOpenings(openings: Opening[]) {
    this._linkedOpenings = openings;
  }

  /* Traversable openings that are tangential to this surface. These openings add their sides to this surface. */
  public tangentialOpenings: Opening[] = [];

  /* Surfaces that are tangential to this surface via a traversable opening. They are ordered in descending order by area. */
  public tangentialSurfaces: PlanarSurface[] = [];

  private _guid: string;
  public get guid(): string {
    return this._guid;
  }

  public room: Room;

  private _area: number = 0;
  public get area(): number {
    if (this._area == 0 && this._localOutline) {
      for (let i = 0; i < this._localOutline.length; i++) {
        const start = this._localOutline[i].start;
        const end = this._localOutline[i].end;

        const xi = start.x;
        const yi = start.y;
        const xj = end.x;
        const yj = end.y;

        this._area += xi * yj - xj * yi;
      }
      this._area = Math.abs(this._area) / 2.0;
    }

    return this._area;
  }

  public readonly apartment: Apartment;

  private _planarCorners: Vector3[] = [];
  /** Any corners of the mesh that are planar to this surface. Mesh positions are always in local space */
  public get planarCorners(): Vector3[] {
    return this._planarCorners;
  }

  /* Uncorrected uvs. The local coordinates in relation to surface origin. */
  private _rawUvs: Vector2[] = [];

  public declare geometry: BufferGeometry;

  private _centerWorldPosition: Vector3;
  public get centerWorldPosition(): Vector3 {
    return this._centerWorldPosition;
  }

  public worldOutline: Line3[];

  private _localOutline?: Line2[];
  public get localOutline(): Line2[] | undefined {
    return this._localOutline;
  }

  public overlapToSurface(that: PlanarSurface): number {

    const dotProduct = that.normal.dot(this.normal);
    if (dotProduct > -0.5) return 0;

    return this.overlapOfPlanarSurface(that);
  }

  public hasLineOfSight(that: PlanarSurface): boolean {

    const dotProduct = that.normal.dot(this.normal);
    if (dotProduct > -0.5) return false;

    const thisCenter = this.centerWorldPosition;
    const thatCenter = that.centerWorldPosition;

    const direction = thatCenter.clone().sub(thisCenter).normalize();

    const raycaster = new Raycaster(thisCenter, direction, 0, 100);

    const thatIntersections = raycaster.intersectObject(that);
    if (thatIntersections.length === 0) return false;

    const thisRoomSurfaces = this.room.planarSurfaces.filter((surface) => surface != this && surface != that);
    const thatRoomSurfaces = that.room.planarSurfaces.filter((surface) => surface != that && surface != this);

    const roomSurfaces = [...thisRoomSurfaces, ...thatRoomSurfaces];

    const hasRoomIntersections = roomSurfaces.some((roomSurface) => {
      return raycaster.intersectObject(roomSurface).length > 0;
    });

    if (hasRoomIntersections) return false;

    return true;
  }

  /** Holes that exist on the surface from the beginning. i.e. holes for pillars. */
  private _worldPrimordialHoles: Line3[][] = [];
  private _localPrimordialHoles: Line2[][] = [];

  public get primordialHoles(): Vector2[][] {
    return this._localPrimordialHoles.map((hole) => hole.map((line) => line.start.clone()));
  }

  private _neighborsByHash: Map<string, PlanarSurface> = new Map<string, PlanarSurface>();

  public getConnectedSurfaces(): PlanarSurface[] {
    return [...this._neighborsByHash.values()];
  }

  public getWorldEdgeByHash(hash: string): Line3 | undefined {
    return this.worldOutline.find((edge) => edge.toHash() === hash || edge.toHash(true) === hash);
  }

  public getWallNeighborsOnLeftSide(): PlanarSurface[] {
    let surfaces = [...this._neighborsByHash.values()].filter((surface) => surface.surfaceType === SURFACE_TYPE.wall);

    const localCenter = this.positionToPlanarSpace(this.centerWorldPosition);

    surfaces = surfaces.filter((surface) => {
      const otherCenter = this.positionToPlanarSpace(surface.centerWorldPosition);
      const isLeft = localCenter.x < otherCenter.x;
      return isLeft;
    });

    return surfaces;
  }

  public getWallNeighborsOnRightSide(): PlanarSurface[] {
    let surfaces = [...this._neighborsByHash.values()].filter((surface) => surface.surfaceType === SURFACE_TYPE.wall);

    const localCenter = this.positionToPlanarSpace(this.centerWorldPosition);

    surfaces = surfaces.filter((surface) => {
      const otherCenter = this.positionToPlanarSpace(surface.centerWorldPosition);
      const isRight = localCenter.x > otherCenter.x;
      return isRight;
    });

    return surfaces;
  }

  public getNeighborSurfaces(surfaceType?: SURFACE_TYPE): PlanarSurface[] {
    let surfaces = [...this._neighborsByHash.values()];
    surfaces = surfaceType ? surfaces.filter((surface) => surface.surfaceType === surfaceType) : surfaces;
    return surfaces;
  }

  public getNeighborsAndHashes(surfaceType?: SURFACE_TYPE): [string, PlanarSurface][] {
    let entries = [...this._neighborsByHash.entries()];
    entries = surfaceType ? entries.filter((entry) => entry[1].surfaceType === surfaceType) : entries;
    return entries;
  }

  /** Get all surfaces that either share an edge or are tangential to this surface via a traversable opening. This surface is included. */
  private _getAssociatedSurfaces(type?: SURFACE_TYPE): PlanarSurface[] {
    let neighborSurfaces = this.getNeighborSurfaces();
    if (type) neighborSurfaces = neighborSurfaces.filter((surface) => surface.surfaceType === type);

    let tangentialSurfaces = this.tangentialSurfaces;
    if (type) tangentialSurfaces = tangentialSurfaces.filter((surface) => surface.surfaceType === type);
    tangentialSurfaces.push(this);

    return [...new Set([...neighborSurfaces, ...tangentialSurfaces])];
  }

  private getNeighborByHash(hash: string): PlanarSurface | undefined {
    if (this._neighborsByHash.has(hash)) {
      return this._neighborsByHash.get(hash);
    } else {
      const reverseHash = hash.split("_").reverse().join("_");
      if (this._neighborsByHash.has(reverseHash)) {
        return this._neighborsByHash.get(reverseHash);
      }
    }
    return undefined;
  }

  public getNeighborAndHashByLocalLine(line: Line2): [PlanarSurface, string] | undefined {
    return this.getNeighborAndHashByWorldLine(this.edgeToWorldSpace(line));
  }

  public getNeighborAndHashByWorldLine(line: Line3): [PlanarSurface, string] | undefined {
    let neighborHash = line.toHash();
    neighborHash = this._neighborsByHash.has(neighborHash) ? neighborHash : line.toHash(true);
    const surface = this._neighborsByHash.get(neighborHash);

    return surface ? [surface, neighborHash] : undefined;
  }

  public intersectingNeighborsByPlanarEdges(edges: Line2[]): PlanarSurface[] {
    if (!this.localOutline) throw new Error("Can't intersect neighbors without local space setup");

    const neighborSurfaces: PlanarSurface[] = [];
    edges.forEach((edge) => {
      const edgeCenter = edge.start.clone().add(edge.end).divideScalar(2);

      const intersectingEdge = Line2.getIntersectingLine(edgeCenter, this.localOutline!);
      if (!intersectingEdge) return;

      const surfaceAndHash = this.getNeighborAndHashByLocalLine(intersectingEdge);
      if (!surfaceAndHash) return;

      neighborSurfaces.push(surfaceAndHash[0]);
    });
    return neighborSurfaces;
  }

  private _flushHoleSideByHash: Map<string, Line3[][]> = new Map<string, Line3[][]>();

  public getFlushSides(): Line3[][] {
    return [...this._flushHoleSideByHash.values()].flat();
  }

  public getLocalFlushSides(): Line2[][] {
    return this.getFlushSides().map((side) => this._localOutlineFromWorldOutline(side));
  }

  /** Given a hash, return the hash if has flush opening sides. Also returns the corrected version of the hash if it has to be corrected. */
  public hasFlushHole(hash: string): string | undefined {
    if (this._flushHoleSideByHash.has(hash)) {
      return hash;
    }

    const reverseHash = hash.split("_").reverse().join("_");
    if (this._flushHoleSideByHash.has(reverseHash)) {
      return reverseHash;
    }

    return undefined;
  }

  private _getFlushHoleSidesByHash(hash: string): Line3[][] {
    const correct_hash = this.hasFlushHole(hash);
    return correct_hash ? this._flushHoleSideByHash.get(correct_hash)! : [];
  }

  private _setFlushHoleSide(hash: string, holeSides: Line3[]) {
    const sidesOutlines = this._getFlushHoleSidesByHash(hash);
    sidesOutlines.push(holeSides);
    this._setFlushHoleSides(hash, sidesOutlines);
  }

  private _setFlushHoleSides(hash: string, sides: Line3[][]): void {

    const filteredSides = sides.filter((chunk) => this.polygonArea(chunk) > 0.001);

    if (this._flushHoleSideByHash.has(hash)) {
      this._flushHoleSideByHash.set(hash, filteredSides);
      return;
    } else {
      const reverseHash = hash.split("_").reverse().join("_");
      if (this._flushHoleSideByHash.has(reverseHash)) {
        this._flushHoleSideByHash.set(reverseHash, filteredSides);
        return;
      }
    }
    this._flushHoleSideByHash.set(hash, sides);
  }

  /** Calculate the area of a polygon */
  public polygonArea(lines: Line3[]): number {
    let area = 0;

    const sides: Line2[] = lines.map((line) => this._edgeToPlanarSpace(line));

    sides.forEach(side => {
      const x1 = side.start.x;
      const y1 = side.start.y;

      const x2 = side.end.x;
      const y2 = side.end.y;

      area += x1 * y2 - y1 * x2;
    });

    return area;
  }

  public clearFlushHoleSide(hash: string): void {
    if (this._flushHoleSideByHash.has(hash)) {
      this._flushHoleSideByHash.delete(hash);
    } else {
      const reverseHash = hash.split("_").reverse().join("_");
      if (this._flushHoleSideByHash.has(reverseHash)) {
        this._flushHoleSideByHash.delete(reverseHash);
      }
    }
  }

  private _sideFromHoleEdge(edge: Line2, depths: number[], sourceSurface: PlanarSurface, offset = 0.05): Line3[] {
    const sideOutline: Line3[] = [];

    // Make sure that the edge is slightly offset inside the surface, so that merging will always work.
    const corner0 = sourceSurface.positionToWorldSpace(edge.start, offset);
    const corner1 = sourceSurface.positionToWorldSpace(edge.start, -depths[0]);
    const corner2 = sourceSurface.positionToWorldSpace(edge.end, -depths[1]);
    const corner3 = sourceSurface.positionToWorldSpace(edge.end, offset);

    sideOutline.push(new Line3(corner0, corner1));
    sideOutline.push(new Line3(corner1, corner2));
    sideOutline.push(new Line3(corner2, corner3));
    sideOutline.push(new Line3(corner3, corner0));

    return sideOutline;
  }

  public appendFlushSide(holeEdge: Line2, holeDepths: number[], hash: string, sourceSurface: PlanarSurface): void {
    const holeSides: Line3[] = this._sideFromHoleEdge(holeEdge, holeDepths, sourceSurface);
    holeSides.length && this._setFlushHoleSide(hash, holeSides);
  }
  //#endregion
  //#region Constructors
  constructor(surfaceData: SurfaceData, apartment: Apartment, existingGuid = "", room: Room) {
    super();

    this._guid = existingGuid != "" ? existingGuid : slugid.v4();

    this.worldOutline = surfaceData.worldOutline;
    this._centerWorldPosition = this.worldOutline
      .reduce((acc, line) => acc.add(line.start), new Vector3())
      .divideScalar(this.worldOutline.length);
    this._worldPrimordialHoles = surfaceData.worldHoles;
    this._normal = surfaceData.direction;
    this._plane = new Plane().setFromNormalAndCoplanarPoint(this._normal, this._originFromLines(this.worldOutline));
    this.apartment = apartment;
    this.surfaceType = this._faceTypeFromNormal(this._normal);
    this.room = room;

    const floorMaterial = ThreeJsContext.getInstance().defaultFloorMaterial;
    const wallMaterial = ThreeJsContext.getInstance().defaultWallMaterial;

    this.assignedSurfaceMaterial = this.surfaceType === SURFACE_TYPE.floor ? floorMaterial! : wallMaterial!;
    this.material = this.assignedSurfaceMaterial;

    this._setSurfaceParameters();

    store.subscribe((mutation) => {
      if (mutation.type === setUvDebug) {
        const debugMaterial = ThreeJsContext.getInstance().uvCheckerMaterial;
        this.material = mutation.payload && debugMaterial ? debugMaterial : this.assignedSurfaceMaterial;
      }
    });
  }
  //#endregion
  //#region Static methods
  /** Split given surfaces into groups based on their associated surfaces. */
  public static splitIntoSurfaceGroups(surfaces: PlanarSurface[]): PlanarSurface[][] {
    if (surfaces.length === 0) return [];

    const uniqueTypes = [...new Set(surfaces.map((surface) => surface.surfaceType))];
    if (uniqueTypes.length > 1) {
      console.error("Can't get surface groups from surfaces of different types");
    }
    const type = uniqueTypes[0];

    const surfaceGroups: PlanarSurface[][] = [];

    surfaces.forEach((surface) => {
      const isInsideSurfaceGroups = surfaceGroups.some((surfaceGroup) =>
        surfaceGroup.some((groupedSurface) => surface.guid === groupedSurface.guid)
      );

      if (isInsideSurfaceGroups) return;

      const allSurfacesSoFar = surfaceGroups.flat();

      const associatedSurfaces = surface._getAssociatedSurfaces(type);

      const alreadyAdded = associatedSurfaces.some((surface) => allSurfacesSoFar.includes(surface));

      if (alreadyAdded) return;

      surfaceGroups.push(associatedSurfaces);
    });

    return surfaceGroups;
  }
  //#endregion
  //#region Setup
  private _setSurfaceParameters(existingParameters?: Map<string, string>): void {
    const type = SURFACE_TYPE[this.surfaceType];

    const parameters = existingParameters ? existingParameters : getObjectParameters(this);

    !parameters.has("type") && parameters.set("type", `${type}Surface`);
    !parameters.has("surfaceId") && parameters.set("surfaceId", this.guid);
    !parameters.has("surfaceType") && parameters.set("surfaceType", this._faceTypeFromNormal(this.normal).toString());

    setObjectParameters(parameters, this);
  }

  /** Given an array of Line3's return the point that is closest to origin in planar uv space. The most low left point*/
  private _originFromLines(lines: Line3[]): Vector3 {
    if (!lines.length) {
      return new Vector3();
    }
    let maxDistance = Number.NEGATIVE_INFINITY;
    let maxDistanceIndex = 0;

    for (let i = 0; i < lines.length; i++) {
      const distance = -lines[i].start.dot(this.bitangent) - lines[i].start.dot(this.tangent);
      if (distance > maxDistance) {
        maxDistance = distance;
        maxDistanceIndex = i;
      }
    }
    return lines[maxDistanceIndex].start.clone();
  }

  public setupLocalSpace(): void {
    this.updateMatrixWorld();

    this.position.copy(this._originFromLines(this.worldOutline));
    this.quaternion.lookRotation(this._normal, this.bitangent);

    this._localOutline = this._localOutlineFromWorldOutline(this.worldOutline);

    this._localPrimordialHoles = this._worldPrimordialHoles.map((hole) => this._localOutlineFromWorldOutline(hole));
  }

  //#endregion
  //#region Vectors
  // The following property setters make sure that both tangent and bitangent are perpendicular to each other at all times
  private uVOffset: Vector2 = new Vector2();

  private _normal: Vector3;
  public get normal(): Vector3 {
    return this._normal;
  }
  private _bitangent?: Vector3;
  public get bitangent(): Vector3 {
    this._bitangent = this._bitangent || this.getDefaultBitangent();
    return this._bitangent;
  }
  public set bitangent(bitangent: Vector3) {
    this._localOutline = undefined;
    this._bitangent = bitangent;
    this._tangent = this.getDefaultTangent();
  }
  private _tangent?: Vector3;
  public set tangent(tangent: Vector3) {
    this._localOutline = undefined;
    this._tangent = tangent;
    this._bitangent = this.getDefaultBitangent();
  }
  public get tangent(): Vector3 {
    if (!this._tangent) {
      this._tangent = this.getDefaultTangent();
    }
    return this._tangent;
  }
  private _plane: Plane;
  public get plane(): Plane {
    return this._plane;
  }

  private getDefaultTangent(): Vector3 {
    return new Vector3().crossVectors(this.bitangent, this._normal);
  }

  public getDefaultBitangent(): Vector3 {

    const isSlanted = this.surfaceCategories.includes(SURFACE_CATEGORY.slanted);
    if (this._tangent) {
      return new Vector3().crossVectors(this._normal, this._tangent).normalize();
    } else {
      if (this.surfaceType === SURFACE_TYPE.ceiling || this.surfaceType === SURFACE_TYPE.floor || isSlanted) {
        return new Vector3(-1, 0, 0);
      } else {
        return UP.clone();
      }
    }
  }

  private _setupTangentialVectors(lineDirection: Vector3): void {
    const lineBitangentProduct = lineDirection.dot(this.bitangent);
    const lineTangentProduct = lineDirection.dot(this.tangent);

    const lineParallelToBitangent = Math.abs(lineBitangentProduct) > Math.abs(lineTangentProduct);

    const isSlanted = this.surfaceCategories.includes(SURFACE_CATEGORY.slanted);

    if (this.surfaceType === SURFACE_TYPE.wall && !isSlanted) {
      this.bitangent = UP.clone();
    } else if (lineParallelToBitangent) {
      this.bitangent = lineBitangentProduct > 0 ? lineDirection.clone() : lineDirection.clone().negate();
    } else {
      this.tangent = lineTangentProduct > 0 ? lineDirection.clone() : lineDirection.clone().negate();
    }
  }

  private _localOutlineFromWorldOutline(worldOutline: Line3[]): Line2[] {
    return worldOutline.map((edge) => this._edgeToPlanarSpace(edge));
  }

  public positionToWorldSpace(planarPosition: Vector2, depth = 0): Vector3 {
    return this.localToWorld(new Vector3(planarPosition.x, planarPosition.y, depth));
  }

  public edgeToWorldSpace(edge: Line2, depth = 0): Line3 {
    const start = this.positionToWorldSpace(edge.start, depth);
    const end = this.positionToWorldSpace(edge.end, depth);
    return new Line3(start, end);
  }

  private _edgeToPlanarSpace(edge: Line3): Line2 {
    const start = this.positionToPlanarSpace(edge.start);
    const end = this.positionToPlanarSpace(edge.end);
    return new Line2(start, end);
  }

  public positionToPlanarSpace(worldPosition: Vector3): Vector2 {
    const position = this.worldToLocal(worldPosition.clone());
    return new Vector2(position.x, position.y);
  }

  private _faceTypeFromNormal(normal: Vector3): SURFACE_TYPE {
    if (normal.dot(DOWN) > 0.025) {
      this.surfaceCategories.push(SURFACE_CATEGORY.slanted);
    }

    if (normal.dot(DOWN) > 0.65) {
      return SURFACE_TYPE.ceiling;
    } else if (normal.dot(UP) > 0.025) {
      return SURFACE_TYPE.floor;
    }
    return SURFACE_TYPE.wall;
  }

  //#endregion
  //#region Outline
  public getWorldEdgeFromHash(hash: string): Line3 | undefined {
    const clampedEdge = new Line3().fromHash(hash);

    const worldEdge = this.worldOutline.find((edge) => edge.hashEquals(clampedEdge));
    if (worldEdge) {
      return worldEdge;
    } else {
      const reverseHash = hash.split("_").reverse().join("_");
      const reversedClampedEdge = new Line3().fromHash(reverseHash);
      return this.worldOutline.find((edge) => edge.hashEquals(reversedClampedEdge));
    }
  }
  //#endregion
  //#region Neighbor surfaces
  public setupConnectedSurfaces(type: SURFACE_TYPE): void {
    if (!this._localOutline) throw new Error("Cannot setup connected surfaces to a surface without local space setup");

    const connectedSurfaceOfType = [...this._neighborsByHash.entries()].filter(([, value]) => value.surfaceType === type);

    connectedSurfaceOfType.forEach(([hash, that]) => {
      if (that._localOutline) return; // No need to setup surface that already has local space setup

      if (this != that) {
        const connectedEdge = this.getWorldEdgeFromHash(hash);
        if (!connectedEdge) return;
        this.setupConnectedSurface(that, connectedEdge);
        that.setupConnectedSurfaces(type);
      }
    });
  }

  public setupConnectedSurface(planarSurface: PlanarSurface, connectedLine: Line3): void {
    const lineDirection = connectedLine.start.clone().sub(connectedLine.end).normalize();
    const startPosition = connectedLine.start.clone();

    planarSurface._setupTangentialVectors(lineDirection);
    planarSurface.setupLocalSpace();

    const fromThisOriginToLine = this.positionToPlanarSpace(startPosition);
    const fromLineToThatOrigin = planarSurface.positionToPlanarSpace(startPosition).negate();

    planarSurface.uVOffset = fromThisOriginToLine.add(fromLineToThatOrigin).add(this.uVOffset);
  }

  public connectSurfaces(surfaces: PlanarSurface[]): void {
    this.worldOutline.forEach((thisEdge) => {
      const thisHash = thisEdge.toHash();
      const thisReverse = thisEdge.toHash(true);

      surfaces.forEach((thatSurface) => {
        if (this === thatSurface) return;

        thatSurface.worldOutline.forEach((thatEdge) => {
          const thatHash = thatEdge.toHash();

          if (thisHash === thatHash || thisReverse === thatHash) {
            const connectionExists = this._neighborsByHash.has(thisHash) || this._neighborsByHash.has(thisReverse);
            if (!connectionExists) {
              thatSurface._neighborsByHash.set(thisHash, this);
              this._neighborsByHash.set(thisHash, thatSurface);
            }
          }
        });
      });
    });
  }

  public connectTangentialSurfaces(): void {
    this.tangentialSurfaces = this._accumulateTangentialSurfaces();
  }

  /** Accumulate all tangential surfaces that are connected to this surface. Returns surface ordered by area, largest first. */
  private _accumulateTangentialSurfaces(accumulated: PlanarSurface[] = []): PlanarSurface[] {
    const firstInvocation = accumulated.length === 0;

    if (!accumulated.includes(this)) {
      accumulated.push(this);
    }

    this.tangentialOpenings.forEach((opening) => {
      const tangentialSurfaces = opening.tangentialSurfaces;
      tangentialSurfaces.forEach((surface) => {
        if (surface == this) return;
        if (surface.surfaceType !== this.surfaceType) return;
        if (accumulated.includes(surface)) return;
        surface._accumulateTangentialSurfaces(accumulated);
      });
    });

    return firstInvocation ? accumulated.sort((a, b) => b.area - a.area) : accumulated;
  }

  // #endregion
  //#region Opening methods
  public attachOpening(opening: Opening): void {
    if (opening.isSurfaceLinked(this)) {
      console.error("Attempted to link same surface to opening twice");
      return;
    }

    opening.appendSurfaceToOpening(this);

    this._linkedOpenings.push(opening);

    this.room.addOpening(opening);
  }

  public removeOpening(opening: Opening): void {
    this._linkedOpenings = this._linkedOpenings.filter((o) => opening !== o);
    this.room.removeOpening(opening);
  }
  //#endregion

  //#region Geometry methods
  /** Update the geometry of this surface, we also update neighbor surfaces here if necessary. @param updateConnectedHoleSides if true, we will update the connected surfaces that share an opening side with this surface. */
  public updatePlanarGeometry(updateConnectedHoleSides = true): void {
    if (!this._localOutline) throw new Error("Can't create geometry without local space setup");

    let neighborHashesToUpdate = updateConnectedHoleSides ? this._getFlushHoleSideNeighbors() : [];
    this._clearConnectedFlushHoleSides(neighborHashesToUpdate);

    const surfaceGeoCreator = new SurfaceGeoCreator(this._localOutline, this.uVOffset);
    const newNeighborHashesToUpdate = surfaceGeoCreator.createPlanarGeometry(this);
    neighborHashesToUpdate = neighborHashesToUpdate.concat(newNeighborHashesToUpdate);

    updateConnectedHoleSides = updateConnectedHoleSides && !!neighborHashesToUpdate.length;
    this._rawUvs = surfaceGeoCreator.rawUvs;
    this.geometry = surfaceGeoCreator.setupGeometryBuffer(this.geometry);
    this._planarCorners = surfaceGeoCreator.planarCorners;

    updateConnectedHoleSides && this._updateNeighboringSurfaces(neighborHashesToUpdate);

    this._unwrapTangentialSurfaces();
  }

  private _unwrapTangentialSurfaces(): void {
    if (!this.tangentialSurfaces || this.tangentialSurfaces.length < 1) return;

    if (!this.tangentialSurfaces.includes(this)) return;

    this.tangentialSurfaces.forEach((surface) => surface._projectUvsToSurface(this.tangentialSurfaces[0]));
  }

  private _projectUvsToSurface(basisSurface: PlanarSurface): void {
    const basisIsSelf = basisSurface == this;
    if (basisIsSelf) return;

    const isSameType = this.type == basisSurface.type;
    if (!isSameType) return;

    const geometry = this.geometry as BufferGeometry;
    const uvAttribute = geometry.getAttribute("uv") as Float32BufferAttribute;
    if (!uvAttribute) console.error("Unable to project uvs. Missing uv attribute");

    const uvArray = uvAttribute.array as Float32Array;

    const worldUvCoords = this._rawUvs.map((uv) => new Vector3(uv.x, uv.y, 0)).map((uv3D) => this.localToWorld(uv3D));

    const localUvs = worldUvCoords.map((worldUv) => basisSurface.worldToLocal(worldUv));

    localUvs.forEach((localUv, index) => {
      uvArray[index * 2] = localUv.x + basisSurface.uVOffset.x;
      uvArray[index * 2 + 1] = -(localUv.y + basisSurface.uVOffset.y);
    });

    geometry.getAttribute("uv").needsUpdate = true;
  }

  public overlapOfPlanarSurface(otherSurface: PlanarSurface): number {
    if (!this._localOutline || !otherSurface._localOutline) throw new Error("Can't calculate overlap without local space setup");

    const otherSurfaceLocalOutline = otherSurface.worldOutline.map((edge) => this._edgeToPlanarSpace(edge));

    const intersection = intersectionLineChunks(this._localOutline, otherSurfaceLocalOutline);

    return intersection.reduce((acc, line) => acc + line.distance(), 0);
  }

  /** Given an array of neighbor surface hashes. Update the neighbor surfaces.*/
  private _updateNeighboringSurfaces(neighborHashesToUpdate: string[]): void {
    neighborHashesToUpdate = [...new Set(neighborHashesToUpdate)];
    neighborHashesToUpdate.forEach((hash) => {
      const surface = this.getNeighborByHash(hash);
      surface && surface.updatePlanarGeometry(false);
    });
  }

  /** Connected surfaces that share an opening side i.e. connected surfaces where the openings of other surfaces "touch" the outer edges of this surface*/
  private _getFlushHoleSideNeighbors(): string[] {
    const neighborHashesToUpdate: string[] = [];

    this._neighborsByHash.forEach((surface, hash) => {
      const flushHoleHash = surface.hasFlushHole(hash);
      flushHoleHash && neighborHashesToUpdate.push(flushHoleHash);
    });

    return neighborHashesToUpdate;
  }

  /** Given an array of Line3 hashes, clear out the opening hole sides that neighbors will generate on our behalf*/
  private _clearConnectedFlushHoleSides(hashes: string[]): void {
    this._neighborsByHash.forEach((surface, hash) => {
      if (hashes.includes(hash)) {
        surface.clearFlushHoleSide(hash);
      } else {
        const reverseHash = hash.split("_").reverse().join("_");
        if (hashes.includes(reverseHash)) {
          surface.clearFlushHoleSide(reverseHash);
        }
      }
    });
  }

  /** Add this surface outline to the bounds that clamp the movement of openings on this surface and the opposing surface */
  public addOutlineToOpeningBounds(worldOutline: Line3[], bounds: Line2[]): Line2[] {
    if (bounds.length === 0) {
      bounds = worldOutline.map((edge) => this._edgeToPlanarSpace(edge));
    } else {
      const otherLocalOpeningBounds: Line2[] = worldOutline.map((edge) => this._edgeToPlanarSpace(edge));
      try {
        return intersectionLineChunks(bounds, otherLocalOpeningBounds);
      } catch (error) {
        console.error(`Error in opening bounds intersection. Space id: ${this.room.roomId}`, error);
      }
    }
    return bounds;
  }

  /** Return the offset of a given point that is needed to bring it back outside of the opening outlines */
  public offsetToAllBounds(point: Vector2, opening: Opening, otherOpenings: Opening[]): Vector2 {
    const offsets: any[] = [];

    const outerOffset = Line2.offsetBounds(point, opening.getBoundsBySurface(this));
    outerOffset && offsets.push(outerOffset);

    otherOpenings.forEach((opening) => {
      const openingCorners: any = opening.edgesOnSurface(this);
      const offset = Line2.offsetBounds(point, openingCorners, true);
      offset && offsets.push(offset);
    });

    return offsets.reduce((accumulator, currentValue) => accumulator.add(currentValue), new Vector2(0, 0));
  }
  // #endregion
}
