import { Vector3, Line3 } from "three";

const TOLERANCE = 0.0005;

export const edgesAreSimilar = (thisEdge: Line3, thatEdge: Line3) => {
    const startDistance = thisEdge.start.distanceTo(thatEdge.start);
    const endDistance = thisEdge.end.distanceTo(thatEdge.end);

    if (startDistance < TOLERANCE && endDistance < TOLERANCE) return true;
    return false;
}

export const nearestPointOnEdge = (point: Vector3, edge: Line3) => {
    const start = edge.start;
    const end = edge.end;
    const pointToStart = point.clone().sub(start);
    const endToStart = end.clone().sub(start);
    const endToStartProduct = endToStart.dot(endToStart);
    const projection = pointToStart.dot(endToStart) / endToStartProduct;
    if (projection < 0) {
        return start;
    } else if (projection > 1) {
        return end;
    }
    const nearestPoint = start.clone().addScaledVector(endToStart, projection);
    return nearestPoint;
};

export const edgeIsInside = (thisEdge: Line3, thatEdge: Line3) => {
    const startNearest = nearestPointOnEdge(thisEdge.start, thatEdge);
    const endNearest = nearestPointOnEdge(thisEdge.end, thatEdge);

    const startDistance = startNearest.distanceTo(thisEdge.start);
    const endDistance = endNearest.distanceTo(thisEdge.end);

    if (startDistance < TOLERANCE && endDistance < TOLERANCE) {
        return true;
    }
    return false;
};

const edgesHaveSamelength = (thisEdge: Line3, thatEdge: Line3) => {
    if (Math.abs(thisEdge.distance() - thatEdge.distance()) < TOLERANCE) return true;
    return false;
};

/** Get the difference of two edges via a boolean operation.
 * If the edges are not overlapping, return null.
 * If the edges are overlapping, and have the same length, return an empty array
 * If the edges are overlapping and have different lengths, return the difference edges.
 * @param thisEdge The first edge
 * @param thatEdge The second edge
 * @returns The difference edges or null
 * */
export const edgeDifference = (thisEdge: Line3, thatEdge: Line3) => {
    const thisIsInside = edgeIsInside(thisEdge, thatEdge);
    const thatIsInside = edgeIsInside(thatEdge, thisEdge);

    if (!thisIsInside && !thatIsInside) return null;

    if (edgesHaveSamelength(thisEdge, thatEdge)) return [];

    const insideEdge = thisIsInside ? thisEdge.clone() : thatEdge.clone();
    const outsideEdge = thisIsInside ? thatEdge.clone() : thisEdge.clone();

    const insideDirection = insideEdge.end.clone().sub(insideEdge.start);
    const outsideDirection = outsideEdge.end.clone().sub(outsideEdge.start);

    const difference: Line3[] = [];

    const dot_product = insideDirection.dot(outsideDirection);
    const edges_face_same_direction = dot_product > 0;

    if (edges_face_same_direction) {
        const diff_start = new Line3(insideEdge.start.clone(), outsideEdge.start.clone());
        if (diff_start.distance() > TOLERANCE) difference.push(diff_start);
        const diff_end = new Line3(insideEdge.end.clone(), outsideEdge.end.clone());
        if (diff_end.distance() > TOLERANCE) difference.push(diff_end);
    } else {
        const diff_start = new Line3(insideEdge.start.clone(), outsideEdge.end.clone());
        if (diff_start.distance() > TOLERANCE) difference.push(diff_start);
        const diff_end = new Line3(insideEdge.end.clone(), outsideEdge.start.clone());
        if (diff_end.distance() > TOLERANCE) difference.push(diff_end);
    }

    return difference;
};