




import Vue from "vue";

export default Vue.extend({
  name: "LoadingCursor",
  data(): {
    offset: number;
  } {
    return {
      offset: 16,
    };
  },
  mounted() {
    window.addEventListener("mousemove", this.updateSpinner);
  },
  methods: {
    updateSpinner(e: MouseEvent): void {
      e.stopPropagation();
      const spinner = (this.$refs["progress-spinner"] as any)?.$el as HTMLElement;
      if (spinner) {
        spinner.style.transform = `translate3d(${e.clientX + this.offset}px, ${e.clientY - this.offset}px, 0px)`;
      }
    },
  },
  destroyed() {
    window.removeEventListener("mousemove", this.updateSpinner);
  },
});
