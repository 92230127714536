import { Module } from "vuex";
import { Asset } from "@/interfaces/Asset";
import { RootState } from ".";

export const isAnAssetSelected = "isAnAssetSelected";
export const currentAssetTypeId = "currentAssetTypeId";
export const allAssets = "allAssets";
export const setIsAnAssetSelected = "setIsAnAssetSelected";
export const setCurrentAssetTypeId = "setCurrentAssetTypeId";
export const setAllAssets = "setAllAssets";

export interface AssetsState {
  isAnAssetSelected: boolean;
  currentAssetTypeId: string;
  allAssets: Array<Asset>;
}

const assets: Module<AssetsState, RootState> = {
  state: {
    isAnAssetSelected: false,
    currentAssetTypeId: "",
    allAssets: [],
  },
  getters: {
    [isAnAssetSelected]: (state: AssetsState): boolean => {
      return state.isAnAssetSelected;
    },
    [currentAssetTypeId]: (state: AssetsState): string => {
      return state.currentAssetTypeId;
    },
    [allAssets]: (state: AssetsState): Asset[] => {
      return state.allAssets;
    },
  },
  mutations: {
    [setIsAnAssetSelected]: (state: AssetsState, payload: boolean): void => {
      state.isAnAssetSelected = payload;
    },
    [setCurrentAssetTypeId]: (state: AssetsState, payload: string): void => {
      state.currentAssetTypeId = payload;
    },
    [setAllAssets]: (state: AssetsState, payload: Asset[]): void => {
      state.allAssets = payload;
    },
  },
  actions: {},
  modules: {},
};

export default assets;
