import { Mesh, Object3D } from "three";
import store from "@/store";
import { addApartments } from "@/store/apartments";
import { Apartment } from "./Apartment";
import { getObjectParameters } from "@/three/ThreeJsHelpers";

export class Storey {
  public readonly name?: string;
  public readonly elevation?: number;
  public readonly globalId: string;
  public readonly storeyNode: Object3D;
  public readonly apartments: Apartment[] = [];

  constructor(storeyNode: Object3D) {
    const parameters = getObjectParameters(storeyNode);
    const name = parameters.get("Name") || parameters.get("LongName") || "";

    const globalId = parameters.get("GlobalId");
    if (!globalId) console.error("Unable to find storey globalId in ifcParameter");

    let elevation: string | number | undefined = parameters.get("Elevation");
    elevation = elevation != undefined ? parseFloat(elevation.replace(",", ".")) : undefined;

    if (elevation === undefined) {
      console.log(`Unable to find elevation in storey: ${name}`);
    }

    this.name = name;
    this.elevation = elevation;
    this.globalId = globalId ? globalId : "";
    this.storeyNode = storeyNode;

    this.appendApartments();
  }

  private appendApartments(): void {
    if (!this.storeyNode.children.length) return;

    const apartmentNodes: Object3D[] = this.storeyNode.children.filter(
      (apartment: Object3D) => !(apartment as Mesh).isMesh
    ) as Object3D[];

    apartmentNodes.forEach((apartmentNode: Object3D) => {
      this.apartments.push(new Apartment(apartmentNode));
    });

    const aptsStorePayload = this.apartments.map((apt) => {
      return { name: apt.currentName(), id: apt.currentId() };
    });
    store.commit(addApartments, aptsStorePayload);
  }
}
