import { Module } from "vuex";
import { RootState } from ".";
import { ApartmentStore } from "@/interfaces/Apartment";

export const apartments = "apartments";
export const setApartments = "setApartments";
export const addApartments = "addApartments";

export interface ApartmentsState {
  apartments: Array<ApartmentStore>;
}

const surfaces: Module<ApartmentsState, RootState> = {
  state: {
    apartments: [],
  },
  getters: {
    [apartments]: (state: ApartmentsState): ApartmentStore[] => {
      return state.apartments;
    },
  },
  mutations: {
    [setApartments]: (state: ApartmentsState, payload: ApartmentStore[]): void => {
      state.apartments = [];
      state.apartments = state.apartments.concat(payload);
      state.apartments = state.apartments.filter((x) => x.name);
    },
    [addApartments]: (state: ApartmentsState, payload: ApartmentStore[]): void => {
      state.apartments = state.apartments.concat(payload);
      state.apartments = state.apartments.filter((x) => x.name);
    },
  },
  actions: {},
  modules: {},
};

export default surfaces;
