import {
  Vector3,
  Color,
  Plane,
  Object3D,
  Mesh,
  LineSegments,
  Points,
  MeshBasicMaterial,
  LineBasicMaterial,
  PointsMaterial,
  PlaneGeometry,
  BufferGeometry,
  Float32BufferAttribute,
  ColorRepresentation,
  MathUtils,
  Raycaster,
  Intersection,
  Ray,
  Vector2,
  BufferAttribute,
} from "three";
import { Opening } from "../building-objects/Opening";
import { MERGE_TOLERANCE } from "../math/Line2";

import { PlanarSurface } from "./PlanarSurface";

const mirrorCornerIndexOnX = (corner: number) => {
  if (corner === 0) return 3;
  if (corner === 1) return 2;
  if (corner === 2) return 1;
  if (corner === 3) return 0;
  return corner;
};

const mirrorCornerIndexOnY = (corner: number) => {
  if (corner === 0) return 1;
  if (corner === 1) return 0;
  if (corner === 2) return 3;
  if (corner === 3) return 2;
  return corner;
};

const mirrorEdgeIndexOnX = (edge: number) => {
  if (edge === 0) return 4;
  if (edge === 2) return 0;
  return edge;
};

const mirrorEdgeIndexOnY = (edge: number) => {
  if (edge === 1) return 6;
  if (edge === 3) return 2;
  return edge;
};

const inactiveColor = 0x0000ff;
const activeColor = 0x00ff00;

export class OpeningGizmo extends Object3D {
  readonly type: string = "OpeningGizmo";

  public openingPlane = new Mesh();
  public openingEdges = new LineSegments();
  public openingCorners = new Points();

  private activeCornerIndices: number[] = [];
  private activeEdgeIndices: number[] = [];

  readonly surface: PlanarSurface;

  private startPlanarPosition = new Vector3();
  private startOpeningCorners: Vector3[] = [];

  public opening: Opening;

  constructor(sourceOpening: Opening, surface: PlanarSurface) {
    super();

    this.name = "OpeningGizmo";
    this.openingPlane.name = "openingGizmoPlane";
    this.openingEdges.name = "openingGizmoEdges";
    this.openingCorners.name = "openingGizmoCorners";

    this.surface = surface;
    this.opening = sourceOpening;

    this.setupGeometry();
    this.addGeometry();
    this.setupMaterials();

    this.setEdgeColor(inactiveColor);
    this.setCornerColor(inactiveColor);

    const openingDirection = sourceOpening.getWorldDirection(new Vector3())
    const openingPosition = sourceOpening.position.clone()
    const surfacePosition = surface.position.clone()
    const distance = openingDirection.dot(openingPosition.sub(surfacePosition));
    const scalar = distance > 0 ? 1 : -1;

    // TODO: Maybe the issue for connections between spaces
    this.translateZ(-scalar / 2);

    if (distance > 0) {
      this.rotateY(Math.PI);
    }

    sourceOpening.add(this);
  }

  public setVisible(visible = true) {
    this.visible = visible;
    this.openingPlane.visible = visible;
    this.openingEdges.visible = visible;
    this.openingCorners.visible = visible;
  }

  public static closestGizmoFromIntersections(intersections: Intersection[], raycaster: Raycaster): OpeningGizmo | undefined {
    const surfaceIntersections = intersections.filter((intersection) => intersection.object.type === "PlanarSurface");
    const surfacesDistances = surfaceIntersections.map((intersection) => intersection.distance);

    const closestSurfaceDistance = surfacesDistances.reduce(
      (closest: any, current: any) => (current < closest ? current : closest),
      Number.POSITIVE_INFINITY
    );

    const rayDirection = raycaster.ray.direction;
    const scratchVector = new Vector3();
    let openingIntersections = intersections.filter((intersection) => intersection.object.parent?.type === "OpeningGizmo");
    openingIntersections = openingIntersections.filter(
      (intersection) => intersection.object.parent!.getWorldDirection(scratchVector).dot(rayDirection) < 0
    );

    if (!openingIntersections.length) return undefined;

    const margin = 0.02;
    openingIntersections = openingIntersections.filter(
      (intersection) => intersection.distance < closestSurfaceDistance + margin
    );

    const meshIntersections = openingIntersections.filter((intersection) => intersection.object.type === "Mesh");
    const pointsIntersections = openingIntersections.filter((intersection) => intersection.object?.type === "Points");
    const edgesIntersections = openingIntersections.filter((intersection) => intersection.object?.type === "LineSegments");

    edgesIntersections.forEach((intersection) => {
      const closestPointToPoint = raycaster.ray.closestPointToPoint(intersection.point, new Vector3());
      intersection.distanceToRay = intersection.point.distanceTo(closestPointToPoint);
    });

    const nonMeshGizmoComponents = pointsIntersections.concat(edgesIntersections);
    const closestOpeningIntersection = nonMeshGizmoComponents.reduce(
      (closest: any, current: any) => (current.distanceToRay < closest.distanceToRay ? current : closest),
      nonMeshGizmoComponents[0]
    );

    if (closestOpeningIntersection) {
      return closestOpeningIntersection.object?.parent;
    } else {
      const closestMeshIntersection = meshIntersections.reduce(
        (closest: any, current: any) => (current.distance < closest.distance ? current : closest),
        meshIntersections[0]
      );
      return closestMeshIntersection?.object?.parent;
    }
  }

  public startMove(raycaster: Raycaster): void {
    const intersection = this.getRayIntersectionPoint(raycaster.ray);
    if (!intersection) return;

    this.startOpeningCorners = this.opening.boundsCornersOnSurface(this.surface);
    this.startPlanarPosition = this.surface.worldToLocal(intersection);
  }

  public move(raycaster: Raycaster): void {
    const intersection = this.getRayIntersectionPoint(raycaster.ray);
    if (!intersection) return;

    const planarDelta = this.surface.worldToLocal(intersection).sub(this.startPlanarPosition);

    if (this.activeCornerIndices.length > 0 || this.activeEdgeIndices.length > 0) {
      this._transformOpening(planarDelta, this.activeCornerIndices, this.activeEdgeIndices, raycaster);
    } else {
      this._transformOpening(planarDelta, [0, 1, 2, 3], [], raycaster);
    }

    this.opening.dispatchEvent({ type: "moved", opening: this });
  }

  private _reverseActiveCornerEdgesBasedOnScale(scale: Vector3): void {
    const activeCornerIndex = this.activeCornerIndices[0];
    if (activeCornerIndex != undefined) {
      if (scale.x < 0) {
        this.activeCornerIndices[0] = mirrorCornerIndexOnX(activeCornerIndex);
      }
      if (scale.y < 0) {
        this.activeCornerIndices[0] = mirrorCornerIndexOnY(activeCornerIndex);
      }
    } else {
      const activeEdgeIndex = this.activeEdgeIndices[0];
      if (scale.x < 0) {
        this.activeEdgeIndices[0] = mirrorEdgeIndexOnX(activeEdgeIndex);
      }
      if (scale.y < 0) {
        this.activeEdgeIndices[0] = mirrorEdgeIndexOnY(activeEdgeIndex);
      }
    }
  }

  private _transformOpening(
    planarDelta: Vector3,
    cornerIndices: number[] = [],
    edgeIndices: number[] = [],
    raycaster: Raycaster
  ): void {
    let corners = this.startOpeningCorners.map((corner) => corner.clone());
    corners = this._moveCorners(corners, planarDelta, cornerIndices, edgeIndices);
    corners = this._clampCorners(corners, cornerIndices, edgeIndices);

    const openingPos = corners.reduce((sum, corner) => sum.add(corner), new Vector3()).divideScalar(corners.length);
    this.opening.position.copy(openingPos);
    this.surface.localToWorld(this.opening.position);

    const scale = this.opening.scaleFromOutline(corners);
    this.opening.scale.set(Math.abs(scale.x), Math.abs(scale.y), scale.z);

    if (scale.x < 0 || scale.y < 0) {
      this._reverseActiveCornerEdgesBasedOnScale(scale);
      this.activateCornersEdges(this.activeCornerIndices[0], this.activeEdgeIndices[0]);
      this.highlightActiveCornersEdges();
      this.startMove(raycaster);
    }
  }

  private _moveCorners(corners: Vector3[], planarDelta: Vector3, cornerIndices?: number[], edgeIndices?: number[]): Vector3[] {
    let xMovingCornerIndices: number[] = [];
    let yMovingCornerIndices: number[] = [];

    if (edgeIndices) {
      // Dragged edge indices restrict corner movement on either the x or y axis
      edgeIndices.forEach((edgeIndex) => {
        if (edgeIndex % 2 === 0) {
          xMovingCornerIndices.push(edgeIndex);
          xMovingCornerIndices.push((edgeIndex + 1) % 4);
        } else {
          yMovingCornerIndices.push(edgeIndex);
          yMovingCornerIndices.push((edgeIndex + 1) % 4);
        }
      });
    }

    if (cornerIndices) {
      // Dragged corner indices enable movement both on the x and y axis
      cornerIndices.forEach((cornerIndex) => {
        corners[cornerIndex].x += planarDelta.x;
        corners[cornerIndex].y += planarDelta.y;
      });

      xMovingCornerIndices = xMovingCornerIndices.filter((index) => !cornerIndices.includes(index));
      yMovingCornerIndices = yMovingCornerIndices.filter((index) => !cornerIndices.includes(index));
    }

    xMovingCornerIndices.forEach((index) => (corners[index].x += planarDelta.x));
    yMovingCornerIndices.forEach((index) => (corners[index].y += planarDelta.y));

    return corners;
  }

  private _clampCorners(corners: Vector3[], cornerIndices?: number[], edgeIndices?: number[]): Vector3[] {
    const otherOpenings = this.surface.linkedOpenings.filter((opening) => opening !== this.opening);

    const offsets: Vector2[] = corners.map((corner) =>
      this.surface.offsetToAllBounds(new Vector2(corner.x, corner.y), this.opening, otherOpenings)
    );

    const offset = offsets.reduce(
      (biggest, current) => (current.lengthSq() > biggest.lengthSq() ? current : biggest),
      new Vector2()
    );

    if (offset.x != 0 || offset.y != 0) {
      const xSign = offset.x < 0 ? -1 : 1;
      const ySign = offset.y < 0 ? -1 : 1;

      offset.x = xSign * Math.min(MERGE_TOLERANCE - Math.abs(offset.x), 0);
      offset.y = ySign * Math.min(MERGE_TOLERANCE - Math.abs(offset.y), 0);

      if (offset.x != 0 || offset.y != 0) {
        return this._moveCorners(corners, new Vector3(offset.x, offset.y, 0), cornerIndices, edgeIndices);
      }
    }

    return corners;
  }

  public highlightActiveCornersEdges(): void {
    this.setColor(inactiveColor);

    if (!this.activeCornerIndices.length && !this.activeEdgeIndices.length) {
      this.setColor(activeColor);
    } else {
      this.activeCornerIndices.forEach((cornerIndex) => this.setCornerColor(activeColor, cornerIndex));
      this.activeEdgeIndices.forEach((edgeIndex) => this.setEdgeColor(activeColor, edgeIndex));
    }
  }

  private activeCornerEdgeFromIntersections(intersections: Intersection[]): [number | undefined, number | undefined] {
    const isPoint = (intersection: Intersection): boolean =>
      intersection.object.parent === this && intersection.object.type === "Points";
    const isEdge = (intersection: Intersection): boolean =>
      intersection.object.parent === this && intersection.object.type === "LineSegments";

    const activeCornerIndex = intersections.find((intersection) => isPoint(intersection))?.index;
    const activeEdgeIndex = intersections.find((intersection) => isEdge(intersection))?.index;

    return [activeCornerIndex, activeEdgeIndex];
  }

  public activateCornersEdges(cornerIndex?: number, edgeIndex?: number): void {
    this.activeCornerIndices = [];
    this.activeEdgeIndices = [];

    if (cornerIndex != undefined) {
      this.activeCornerIndices.push(cornerIndex);
      this.activeEdgeIndices.push(cornerIndex);
      this.activeEdgeIndices.push(MathUtils.euclideanModulo(cornerIndex - 1, 4));
    } else if (edgeIndex != undefined) {
      this.activeEdgeIndices.push(edgeIndex / 2);
    }
  }

  public setIntersections(intersections: Intersection[]) {
    const [cornerIndex, edgeIndex] = this.activeCornerEdgeFromIntersections(intersections);
    this.activateCornersEdges(cornerIndex, edgeIndex);
    this.highlightActiveCornersEdges();
  }

  private getRayIntersectionPoint(ray: Ray): Vector3 | null {
    const plane = new Plane().setFromNormalAndCoplanarPoint(this.surface.normal, this.surface.position);
    return ray.intersectPlane(plane, new Vector3());
  }

  public setColor(color: ColorRepresentation) {
    this.setEdgeColor(color);
    this.setCornerColor(color);
  }

  public dispose() {
    this.openingPlane.geometry.dispose();
    this.openingEdges.geometry.dispose();
    this.openingCorners.geometry.dispose();
  }

  private setupGeometry() {
    this.openingPlane.geometry = new PlaneGeometry(1, 1);
    this.openingPlane.geometry.computeBoundingSphere();

    this.openingEdges.geometry = new BufferGeometry();
    this.openingEdges.geometry.setAttribute("position", new Float32BufferAttribute(this.createEdgePositions(), 3));
    this.openingEdges.geometry.computeBoundingSphere();

    this.openingCorners.geometry = new BufferGeometry();
    this.openingCorners.geometry.setAttribute("position", new Float32BufferAttribute(this.createPointPositions(), 3));
    this.openingCorners.geometry.computeBoundingSphere();
  }

  private addGeometry() {
    this.add(this.openingPlane);
    this.add(this.openingEdges);
    this.add(this.openingCorners);
  }

  private setupMaterials() {
    this.openingPlane.material = new MeshBasicMaterial({ visible: false });
    this.openingEdges.material = new LineBasicMaterial({ toneMapped: false, vertexColors: true });
    this.openingCorners.material = new PointsMaterial({ size: 0.05, toneMapped: false, vertexColors: true });

    this.visible = false;
  }

  private createEdgePositions(): Float32Array {
    const edgePositions = [
      new Vector3(-0.5, -0.5, 0),
      new Vector3(-0.5, 0.5, 0),

      new Vector3(-0.5, 0.5, 0),
      new Vector3(0.5, 0.5, 0),

      new Vector3(0.5, 0.5, 0),
      new Vector3(0.5, -0.5, 0),

      new Vector3(0.5, -0.5, 0),
      new Vector3(-0.5, -0.5, 0),
    ];

    const edgePositionsTyped = new Float32Array(edgePositions.length * 3);

    for (let i = 0; i < edgePositions.length; i++) {
      const index = i * 3;
      edgePositionsTyped[index] = edgePositions[i].x;
      edgePositionsTyped[index + 1] = edgePositions[i].y;
      edgePositionsTyped[index + 2] = edgePositions[i].z;
    }

    return edgePositionsTyped;
  }

  private createPointPositions(): Float32Array {
    const pointPositions = [
      new Vector3(-0.5, -0.5, 0),
      new Vector3(-0.5, 0.5, 0),
      new Vector3(0.5, 0.5, 0),
      new Vector3(0.5, -0.5, 0),
    ];

    const pointPositionsTyped = new Float32Array(pointPositions.length * 3);

    for (let i = 0; i < pointPositions.length; i++) {
      const index = i * 3;
      pointPositionsTyped[index] = pointPositions[i].x;
      pointPositionsTyped[index + 1] = pointPositions[i].y;
      pointPositionsTyped[index + 2] = pointPositions[i].z;
    }

    return pointPositionsTyped;
  }

  public setEdgeColor(color: ColorRepresentation = activeColor, index?: number) {
    let colorsTyped: Float32Array;
    color = new Color(color);

    if (index == undefined) {
      colorsTyped = new Float32Array(8 * 3);

      for (let i = 0; i < colorsTyped.length; i++) {
        index = i * 3;
        colorsTyped[index] = color.r;
        colorsTyped[index + 1] = color.g;
        colorsTyped[index + 2] = color.b;
      }
    } else {
      index = index * 2;
      const currentIndex = (index % 8) * 3;
      const nextIndex = ((index + 1) % 8) * 3;

      colorsTyped = (this.openingEdges.geometry.getAttribute("color") as BufferAttribute).array as Float32Array;

      colorsTyped[currentIndex] = color.r;
      colorsTyped[currentIndex + 1] = color.g;
      colorsTyped[currentIndex + 2] = color.b;

      colorsTyped[nextIndex] = color.r;
      colorsTyped[nextIndex + 1] = color.g;
      colorsTyped[nextIndex + 2] = color.b;
    }

    this.openingEdges.geometry.setAttribute("color", new Float32BufferAttribute(colorsTyped, 3));
  }

  public setCornerColor(color: ColorRepresentation = activeColor, index?: number) {
    let colorsTyped: Float32Array;

    color = new Color(color);

    if (index == undefined) {
      colorsTyped = new Float32Array(4 * 3);

      for (let i = 0; i < colorsTyped.length; i++) {
        const index = i * 3;
        colorsTyped[index] = color.r;
        colorsTyped[index + 1] = color.g;
        colorsTyped[index + 2] = color.b;
      }
    } else {
      const currentIndex = (index % 4) * 3;

      colorsTyped = (this.openingCorners.geometry.getAttribute("color") as BufferAttribute).array as Float32Array;

      colorsTyped[currentIndex] = color.r;
      colorsTyped[currentIndex + 1] = color.g;
      colorsTyped[currentIndex + 2] = color.b;
    }

    this.openingCorners.geometry.setAttribute("color", new Float32BufferAttribute(colorsTyped, 3));
  }
}
