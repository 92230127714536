import { Line3 } from "three";
import { createLinesHelper } from "../ThreeJsHelpers";
import { edgeDifference } from "../math/LineMergingUtils";

let lastSpaceIds: string[] = [];

const TOLERANCE = 0.005;

let debug = false;

const red = 0xff0000 * 2.2;
const green = 0x00ff00 * 2.2;
const blue = 0x0000ff * 2.2;

/**
 * Merge polygons that intersect with each other. Polygons that don't intersect are returned as is.
 * @param polygonsToMerge The polygons to merge given as arrays of Line3-arrays.
 * @param spaceIds The space ids for debugging.
 * @param shouldDebug Should debug.
 * @param mergedPolygons polygons that have already been merged.
 * @returns The merged polygons
 */
export const moldingsPolygonMerger = (
  polygonsToMerge: Line3[][],
  spaceIds: string[] = [],
  shouldDebug = false,
  mergedPolygons: Line3[][] = []
): Line3[][] => {
  if (!polygonsToMerge.length) return mergedPolygons;

  debug = shouldDebug;
  lastSpaceIds = spaceIds;

  return mergeIntersectingPolygons(polygonsToMerge);
};

/**
 * Convert polygons into edges that need to be connected. Then connect them.
 * Intersecting edges are removed or restructured into new edges.
 * If a polygon is not intersecting another one it is returned as is.
 * @param polygons The polygons to filter given as arrays of Line3-arrays
 * @returns merged polygons as arrays of Line3-arrays
 */
const mergeIntersectingPolygons = (polygons: Line3[][]): Line3[][] => {
  let thisConnectedPolygon: Line3[] = [];
  let thatConnectedPolygon: Line3[] = [];

  const intersectingPolygons = getIntersectingPolygons(polygons);

  if (!intersectingPolygons.length) {
    // No further polygons were able to be connected.
    // This means that they are already fully formed and all polygons can be returned as is.
    if (debug) {
      for (const newPolygon of polygons) {
        createLinesHelper(newPolygon, green);
      }
    }
    return polygons;
  }

  thisConnectedPolygon = intersectingPolygons[0]!;
  thatConnectedPolygon = intersectingPolygons[1]!;

  polygons = polygons.filter((polygon) => polygon !== thisConnectedPolygon && polygon !== thatConnectedPolygon);

  const edgesToConnect: Line3[] = getEdgesOfIntersecting(thisConnectedPolygon, thatConnectedPolygon);

  const newPolygons: Line3[][] = connectMergeableEdges(edgesToConnect);

  // Continue to merge the remaining polygons
  return mergeIntersectingPolygons(newPolygons.concat(polygons));
};

/**
 * Get polygons that intersect with each other.
 * This function only returns the first two detected intersection.
 * @param polygons The polygons to check for intersections.
 * @returns The intersecting polygons.
 */
const getIntersectingPolygons = (polygons: Line3[][]): Line3[][] => {
  // No point in intersecting if there are less than two polygons
  if (polygons.length <= 1) return [];

  for (const thisPolygon of polygons) {
    for (const thatPolygon of polygons) {
      // No need to intersect polygon against itself
      if (thisPolygon === thatPolygon) continue;
      for (const thisEdge of thisPolygon) {
        for (const thatEdge of thatPolygon) {
          if (edgeDifference(thisEdge, thatEdge)) {
            return [thisPolygon, thatPolygon];
          }
        }
      }
    }
  }

  return [];
};

/**
 * Get edges from two intersecting polygons.
 * @param thisPolygon The first polygon.
 * @param thatPolygon The second polygon.
 * @returns The edges of both intersecting polygons.
 */
const getEdgesOfIntersecting = (thisPolygon: Line3[], thatPolygon: Line3[]): Line3[] => {
  const intersectingEdges: Line3[] = edgesOfIntersecting(thisPolygon, thatPolygon, true);
  return intersectingEdges.concat(edgesOfIntersecting(thatPolygon, thisPolygon, false));
};
/**
 * Get edges from the first of two intersecting polygons.
 * @param thisPolygon The first polygon.
 * @param thatPolygon The second polygon.
 * @param includeInterestions Should the intersecting edges also be included.
 * @returns The edges of thisPolygon.
 */
const edgesOfIntersecting = (thisPolygon: Line3[], thatPolygon: Line3[], includeInterestions: boolean = true): Line3[] => {
  const edges: Line3[] = [];

  for (const thisEdge of thisPolygon) {
    let intersections: Line3[] | null = null;
    for (const thatEdge of thatPolygon) {
      const currentDifference = edgeDifference(thisEdge, thatEdge);
      if (currentDifference) {
        intersections = currentDifference;
        break;
      }
    }
    if (!intersections) {
      edges.push(thisEdge);
      continue;
    }
    if (!includeInterestions) continue;

    for (const diffEdge of intersections) {
      edges.push(diffEdge);
    }
  }

  return edges;
};

/**
 * Connect mergeable edges to form polygons.
 * @param unconnectedEdges Edges that haven't been connected yet for merging.
 * @param mergedPolygons The polygons that are being formed from the unconnected edges.
 * @param previousEdgesTotal The total amount of unconnected edges in the previous iteration.
 * @returns The merged polygons.
 */
const connectMergeableEdges = (
  unconnectedEdges: Line3[],
  mergedPolygons: Line3[][] = [],
  previousEdgesTotal: number = -1
): Line3[][] => {
  if (unconnectedEdges.length == 0) return mergedPolygons;

  if (mergedPolygons.length == 0) {
    // First iteration
    previousEdgesTotal = unconnectedEdges.length;
  }

  if (previousEdgesTotal == unconnectedEdges.length) {
    const newPolygon: Line3[] = [unconnectedEdges.shift()!.clone()];
    mergedPolygons.push(newPolygon);
  }

  previousEdgesTotal = unconnectedEdges.length;

  for (let i = 0; i < unconnectedEdges.length; i++) {
    const lastMergedPolygon = mergedPolygons[mergedPolygons.length - 1];
    const lastMergedEdge = lastMergedPolygon[lastMergedPolygon.length - 1];

    const thisEdge = unconnectedEdges[i];

    const lastConnectedToStart = lastMergedEdge.end.distanceTo(thisEdge.start) < TOLERANCE;
    const lastConnectedToEnd = lastMergedEdge.end.distanceTo(thisEdge.end) < TOLERANCE;

    if (!lastConnectedToStart && !lastConnectedToEnd) continue;

    const intersectingEdge = unconnectedEdges.splice(i, 1)[0].clone();
    if (lastConnectedToStart) {
      lastMergedPolygon.push(intersectingEdge);
    } else {
      const flippedEdge = new Line3(intersectingEdge.end, intersectingEdge.start);
      lastMergedPolygon.push(flippedEdge);
    }

    mergedPolygons[mergedPolygons.length - 1] = lastMergedPolygon;
    // We continue from the start since we have modified the array
    break;
  }

  return connectMergeableEdges(unconnectedEdges, mergedPolygons, previousEdgesTotal);
};
