import { AxiosInstance } from "axios";
import { Surface } from "@/interfaces/Surface";
import { Asset } from "@/interfaces/Asset";

export type Project = {
  id: string;
  name: string;
};

export type BuildingPlan = {
  id: string;
  name: string;
  uploadedAt: string;
};

export class BackendApi {
  private projectAxios: AxiosInstance;
  private assetAxios: AxiosInstance;

  constructor(axios: AxiosInstance, assetAxios: AxiosInstance) {
    this.projectAxios = axios;
    this.assetAxios = assetAxios;
  }

  async getProjects(): Promise<Project[]> {
    const response = await this.projectAxios.get("/builder/secure/projects");
    return response.data;
  }

  async getBuildingPlans(projectId: string): Promise<BuildingPlan[]> {
    const response = await this.projectAxios.get(`/builder/secure/projects/${projectId}/plans`);
    const playload = response.data;
    return playload.map((bp: any) => ({ ...bp, uploadedAt: new Date(bp.uploadedAt), convertedGlbUrl: this.makeConvertedGlbUrl(projectId, bp.id) }));
  }

  async getFloorplans(projectId: string): Promise<string[]> {
    const response = await this.projectAxios.get(`/builder/secure/projects/${projectId}/files`);
    const filenames = Object.keys(response.data.files).filter((f: any) => f.endsWith(".svg"));
    const fileValues = filenames.map((f) => response.data.files[f]);
    const svgLocations = fileValues.map((f: any) => f.location);

    return await Promise.all(svgLocations.map((l: string) => this.projectAxios.get(l) as Promise<any>));
  }

  getSceneDownloadUrl(projectId: string): string {
    const baseUrl = this.projectAxios.defaults.baseURL ?? ""
    return `${baseUrl}/builder/projects/${projectId}/scene`
  }

  async postGlb(project: string, content: Blob | File): Promise<Response> {
    const formData = new FormData();
    formData.append("content", content);

    const postResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/builder/projects/${project}/scene`, {
      method: "POST",
      body: formData,
    });
    return postResponse;
  }

  async getBuildingUnitMetadata(project: string): Promise<any> {
    return (await this.projectAxios.get(`${process.env.VUE_APP_BASE_URL}/api/hub/${project}`)).data;
  }

  async postBuildingUnitMetadata(content: Blob | File): Promise<Response> {
    const contentJson = JSON.parse(await content.text());
    return await this.projectAxios.post(`${process.env.VUE_APP_BASE_URL}/api/hub/`, contentJson);
  }

  async postFloorPlans(project: string, content: Blob[] | File[]): Promise<Response[]> {

    // TODO: Poke Sujan in the back to implement multifile upload.
    //const formData = new FormData();
    //content.forEach((c) => formData.append("content[]", c));


    const responses = (content as any[]).map((c: any) => {
      const formData = new FormData();
      formData.append("content", c);

      return fetch(`${process.env.VUE_APP_BASE_URL}/builder/projects/${project}/files`, {
        method: "POST",
        body: formData,
      });
    });

    return await Promise.all(responses);
  }

  async getAssets(): Promise<Asset[]> {
    const response = await this.assetAxios.get("/data/assets");
    return response.data;
  }

  async getSurfaces(): Promise<Surface[]> {
    const response = await this.assetAxios.get("/data/materials");
    return response.data.map((d: Surface) => {
      d.surface = d.surface.map((s) => s.trim());
      return d;
    });
  }

  private makeConvertedGlbUrl(projectId: string, planId: string) {
    const baseUrl = this.projectAxios.defaults.baseURL ?? ""
    return `${baseUrl}/builder/projects/${projectId}/plans/${planId}/converted`
  }
}

export const initializeBackend = (projectAxios: AxiosInstance, assetAxios: AxiosInstance) => {
  backend = new BackendApi(projectAxios, assetAxios);
};

export let backend: BackendApi;
