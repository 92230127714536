import {
  Mesh,
  Vector3,
  Object3D,
  BufferGeometry,
  Line,
  SphereGeometry,
  MeshBasicMaterial,
  ArrowHelper,
  Line3,
  LineBasicMaterial,
  ColorRepresentation,
} from "three";
import { Text as TroikaText } from "troika-three-text";
import { ThreeJsContext } from "./ThreeJsContext";
import * as slugid from "slugid";


const blacklistedProperties = [
  "BIMModelProperties",
  "BIMProjectProperties",
  "BIMProperties",
  "DocProperties",
  "BIMMeshProperties",
  "uploader_scene_properties",
  "camera_properties",
]

export function getObjectParameters(object: Object3D): Map<string, string> {
  if (object) {
    if (object.userData && object.userData["type"]) {
      return _getParametersFromUserData(object);
    } else {
      return _getParametersFromName(object);
    }
  } else {
    return new Map<string, string>();
  }
}

export function objectHasParameter(object: Object3D, key: string, value = ""): boolean {
  const parameters = getObjectParameters(object);
  if (value == "") {
    return parameters.has(key);
  }
  return parameters.get(key) == value;
}

function _getParametersFromName(object: Object3D): Map<string, string> {
  const split = object.name.split("|");
  const parameters = new Map<string, string>();

  split.forEach((parameter) => {
    const params = parameter.split("=") as string[];
    parameters.set(params[0], params[1]);
  });

  return parameters;
}

function _getParametersFromUserData(object: Object3D): Map<string, string> {
  const entries = Object.entries(object.userData);
  const parameters = new Map<string, string>();

  entries.forEach(([key, value]) => {
    if (!key) return;
    const nameAsParams = value?.toString()?.includes("|");
    if (nameAsParams) return;
    parameters.set(key, value?.toString());
  });

  setParametersToName(parameters, object);

  return parameters;
}

export function setObjectParameters(parameters: Map<string, string>, object: Object3D): void {
  const entries = [...parameters.entries()].filter(([key]) => key);
  if (entries.length === 0) return;

  entries.forEach(([key, value]) => (object.userData[key] = value));

  setParametersToName(parameters, object);
}

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function addToObjectParameters(key: string, value: string, object: Object3D): void {
  const parameters = getObjectParameters(object);
  parameters.set(key, value);
  setObjectParameters(parameters, object);
}

function setParametersToName(parameters: Map<string, string>, object: Object3D): void {
  let entries = [...parameters.entries()].filter(([key]) => key);
  if (entries.length === 0) return;

  entries = entries.filter(([key]) => !blacklistedProperties.includes(key));

  object.name = entries
    .map(([key, value]) => {
      value = value?.replace(".", ",");
      value = value?.replace(" ", "_");
      value = value?.replace("/", ""); // Not sure why this character shows up in the data
      return key + "=" + value;
    })
    .join("|");
}

export function getParentOfType(object?: Object3D | null, type?: string): Object3D | undefined {
  if (!object || !type) return undefined;

  const parameters = getObjectParameters(object);
  const paramType = parameters.get("type");
  if (paramType && paramType === type) return object;

  return getParentOfType(object.parent, type);
}

export function getObjectsByType(type: string, parent?: Object3D): Object3D[] {
  parent = parent ? parent : ThreeJsContext.getInstance().scene;
  const objects: Object3D[] = [];
  parent.traverse((child: Object3D) => {
    const parameters = getObjectParameters(child);
    if (parameters.has("type") && parameters.get("type") === type) {
      objects.push(child);
    }
  });
  return objects;
}

export function createObjectOfType(type: string, parent?: Object3D): Object3D {
  const object = new Object3D();
  const parameters = new Map<string, string>();
  parameters.set("type", type);
  parameters.set("moldingsId", slugid.v4());
  setObjectParameters(parameters, object);
  if (parent) parent.add(object);
  else ThreeJsContext.getInstance().scene.add(object);
  return object;
}

export function createArrowHelper(
  direction: Vector3,
  position: Vector3,
  color: ColorRepresentation,
  length = 0.5
): ArrowHelper {
  const headLength = length * 0.35;
  const headWidth = headLength * 0.75;

  const arrowHelper = new ArrowHelper(direction, position, length, color, headLength, headWidth);
  ThreeJsContext.getInstance().scene.add(arrowHelper);
  return arrowHelper;
}

export function createLinesHelper(lines: Line3[], color: ColorRepresentation = 0x0000ff, opacity = 1): Line {
  const debugLineMaterial = new LineBasicMaterial({ color: color, toneMapped: false });

  debugLineMaterial.opacity = opacity;
  debugLineMaterial.transparent = opacity < 1;
  debugLineMaterial.depthTest = false;
  const linePoints: Vector3[] = [];
  lines.forEach((line) => {
    linePoints.push(line.start);
    linePoints.push(line.end);
  });
  const geometry = new BufferGeometry().setFromPoints(linePoints);
  const lineMesh = new Line(geometry, debugLineMaterial);
  lineMesh.renderOrder = 1;
  ThreeJsContext.getInstance().scene.add(lineMesh);
  return lineMesh;
}

export function createTextHelper(text: string, position: Vector3, color: ColorRepresentation = 0xffffff, fontSize = 1, direction = new Vector3(1, 0, 0)): Mesh {
  // Create:
  const textMesh = new TroikaText();
  ThreeJsContext.getInstance().scene.add(textMesh);
  textMesh.lookAt(direction);
  // Set properties to configure:
  textMesh.text = text;
  textMesh.fontSize = fontSize;
  textMesh.position.set(position.x, position.y, position.z);
  textMesh.color = color;
  textMesh.outlineColor = 0x000000;
  textMesh.outlineWidth = 0.05 * fontSize;
  textMesh.material.depthTest = false; // Isn't taken into account in Troikas shader



  // Update the rendering:
  textMesh.sync();
  return textMesh;
}

const sphereMaterialsByParams = new Map<string, MeshBasicMaterial>();

export function createSphereHelper(
  position: Vector3,
  color: ColorRepresentation = 0xf3a2b0,
  wireframe = true,
  radius = 0.07,
  parent?: Object3D
): Mesh {
  const hash = `${color.toString()}-${wireframe}`;
  let sphereMat: MeshBasicMaterial;
  if (!sphereMaterialsByParams.has(hash)) {
    sphereMat = new MeshBasicMaterial({
      color: color,
      wireframe: wireframe,
      toneMapped: false,
    });
    sphereMat.depthTest = false;

    sphereMaterialsByParams.set(hash, sphereMat);
  } else {
    sphereMat = sphereMaterialsByParams.get(hash)!;
  }

  const geom = new SphereGeometry(radius, 4, 4);
  const sphere = new Mesh(geom, sphereMat);
  sphere.renderOrder = 1;
  sphere.position.x = position.x;
  sphere.position.y = position.y;
  sphere.position.z = position.z;
  const parentNode = parent ? parent : ThreeJsContext.getInstance().scene;
  parentNode.add(sphere);
  return sphere;
}

export function scaleAlongWeldedNormals(mesh: Mesh, scale: number): void {
  const geometry = mesh.geometry;

  const positions = (geometry.attributes.position as any).array as Float32Array;
  const positionOffsets = new Float32Array(positions.length);
  const normals = (geometry.attributes.normal as any).array as Float32Array;

  for (let i = 0, il = positions.length; i < il; i += 3) {
    const currentPosition = new Vector3(positions[i], positions[i + 1], positions[i + 2]);

    const weldedNormal = new Vector3();
    let normalsAdded = 0;
    for (let j = 0; j < positions.length; j += 3) {
      const comparePosition = new Vector3(positions[j], positions[j + 1], positions[j + 2]);
      if (currentPosition.equals(comparePosition)) {
        weldedNormal.add(new Vector3(normals[j], normals[j + 1], normals[j + 2]));
        normalsAdded++;
      }
    }

    weldedNormal.divideScalar(normalsAdded);
    weldedNormal.multiplyScalar(scale);

    positionOffsets[i] = weldedNormal.x;
    positionOffsets[i + 1] = weldedNormal.y;
    positionOffsets[i + 2] = weldedNormal.z;
  }

  for (let i = 0, il = positions.length; i < il; i += 3) {
    positions[i] += positionOffsets[i];
    positions[i + 1] += positionOffsets[i + 1];
    positions[i + 2] += positionOffsets[i + 2];
  }
  geometry.attributes.position.needsUpdate = true;
}
