import Vue from "vue";
import VueRouter from "vue-router";
import ProjectSelection from "./pages/ProjectSelection.vue";
import Viewport from "./pages/Viewport.vue";
import Apartments from "./pages/Apartments.vue";
import AddMenu from "./pages/AddMenu.vue";
import AssetMenu from "./pages/AssetMenu.vue";
import CameraMenu from "./pages/CameraMenu.vue";
import CloseMenu from "./pages/CloseMenu.vue";
import AssetBrowser from "./pages/AssetBrowser.vue";
import SurfaceBrowser from "./pages/SurfaceBrowser.vue";
import ThreeJsContainer from "./pages/ThreeJsContainer.vue";
import FileUpload from "./pages/FileUpload.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: ThreeJsContainer,
      children: [
        {
          path: "",
          name: "projectSelection",
          component: ProjectSelection,
          children: [
            {
              path: ":project",
              name: "apartmentSelection",
              component: Apartments,
              children: [
                {
                  path: ":apartment",
                  component: Viewport,
                  children: [
                    {
                      path: "",
                      name: "apartment",
                      component: AddMenu,
                    },
                    {
                      path: "assets",
                      name: "assets",
                      component: AssetMenu,
                    },
                    {
                      path: "asset-work",
                      name: "assetWork",
                      components: {
                        default: CloseMenu,
                        footer: AssetBrowser,
                      },
                    },
                    {
                      path: "cameras",
                      name: "cameras",
                      component: CameraMenu,
                    },
                    {
                      path: "surfaces",
                      name: "surfaces",
                      components: {
                        default: CloseMenu,
                        footer: SurfaceBrowser,
                      },
                    },
                    {
                      path: "file-upload",
                      name: "fileUpload",
                      components: {
                        default: FileUpload,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export default router;
