































import Vue from "vue";

export default Vue.extend({
  name: "CameraMenu",
  methods: {
    placeCamera(): void {
      this.cameraDragController.placeNewCamera();
    },
    snapSelectedCameraToView(): void {
      this.cameraDragController.snapSelectedCameraToView();
    },
    removeSelectedCamera(): void {
      this.cameraDragController.removeSelectedCamera();
    },
  },
  mounted() {
    this.cameraDragController.activate();
  },
  beforeDestroy() {
    this.cameraDragController.deactivate();
  },
});
