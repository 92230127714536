import { Mesh, ColorRepresentation, Line3, Line } from "three";
import { createLinesHelper, createTextHelper } from "../ThreeJsHelpers";

export class MeshDebugLines {
  public readonly title: string;
  public readonly lines: Line3[];
  public readonly color: ColorRepresentation;
  public lineMesh?: Line;
  public textMesh?: Mesh;

  constructor(title: string, lines: Line3[], color: ColorRepresentation = 0x0000ff) {
    this.title = title;
    this.lines = lines;
    this.color = color;
  }
}

export class ObjectDebugger {
  public static getInstance(): ObjectDebugger {
    if (!ObjectDebugger.instance) {
      ObjectDebugger.instance = new ObjectDebugger();
    }
    return ObjectDebugger.instance;
  }

  public constructor() {
    ObjectDebugger.instance = this;
  }

  public enable(value: boolean): void {
    this.lineMeshes.forEach((openingDebugLines) => {
      if (!openingDebugLines.lineMesh) {
        openingDebugLines.lineMesh = createLinesHelper(openingDebugLines.lines, openingDebugLines.color);

        openingDebugLines.textMesh = createTextHelper(
          openingDebugLines.title,
          openingDebugLines.lines[0].start,
          openingDebugLines.color,
          0.2
        );
      }

      openingDebugLines.lineMesh.visible = value;
      openingDebugLines.textMesh && (openingDebugLines.textMesh.visible = value);
    });
  }

  private static instance: ObjectDebugger;

  public readonly lineMeshes: MeshDebugLines[] = [];
}
