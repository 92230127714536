






















































































import { setIsCurrentlyLoading } from "@/store";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "AddMenu",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    ...mapActions([setIsCurrentlyLoading]),
    async downloadScene() {
      this.setIsCurrentlyLoading(true);
      await this.ifcSceneExporter.downloadGltf(`${this.$route.params.project}_plan`);
      this.setIsCurrentlyLoading(false);
    },
  },
});
