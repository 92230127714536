import { Line3 } from "three";

declare module "three/src/math/Line3.js" {
  interface Line3 {
    /** Create a hash with a given precision */
    toHash: (this: Line3, reverse?: boolean, hashPrecision?: number) => string;

    fromHash: (this: Line3, hash: string) => Line3;

    hashEquals: (this: Line3, line: Line3, hashPrecision?: number) => boolean;
  }
}

Line3.prototype.toHash = function (this: Line3, reverse = false, hashPrecision?: number): string {
  return reverse
    ? `${this.end.toFixed(hashPrecision)}_${this.start.toFixed(hashPrecision)}`
    : `${this.start.toFixed(hashPrecision)}_${this.end.toFixed(hashPrecision)}`;
};

Line3.prototype.fromHash = function (this: Line3, hash: string): Line3 {
  this.start.fromFixed(hash.split("_")[0]);
  this.end.fromFixed(hash.split("_")[1]);
  return this;
};

Line3.prototype.hashEquals = function (this: Line3, line: Line3, hashPrecision?: number): boolean {
  return this.toHash(false, hashPrecision) === line.toHash(false, hashPrecision);
};
