
























import Vue from "vue";
import { backend } from "@/lib/backend";
import { mapGetters } from "vuex";
import LoadingCursor from "@/components/LoadingCursor.vue";
import ApartmentsTable from "@/components/ApartmentsTable.vue";
import { apartments, setApartments } from "@/store/apartments";

export default Vue.extend({
  components: { LoadingCursor, ApartmentsTable },
  name: "Apartments",
  data(): {
    buildingUnitMetadata: any;
    selectedUnitTitle: string;
    hasApartmentSvg: boolean;
  } {
    return {
      buildingUnitMetadata: null,
      selectedUnitTitle: "",
      hasApartmentSvg: false,
    };
  },
  async mounted() {
    this.$store.commit(setApartments, []);
    this.threeJsContext.loadProject(this.$route.params.project);
    this.buildingUnitMetadata = await backend.getBuildingUnitMetadata(this.$route.params.project);

    const hasBuilding = this.buildingUnitMetadata.buildings!.length > 0;
    const buildingId = hasBuilding && this.buildingUnitMetadata.buildings[0].publicId;

    if (buildingId) {
      const svgUrl = this.remoteCdnFileResolver(`building_${buildingId}.svg`);
      const response = await fetch(svgUrl);
      const svg = await response.text();
      this.hasApartmentSvg = !!svg;
    }
  },
  methods: {
    remoteCdnFileResolver: function (filename: string) {
      return `https://api.qistage.com/builder/projects/${this.$route.params.project}/files/${filename}`;
    },
    apartmentSelected: function (apartmentTitle: string) {
      this.selectedUnitTitle = apartmentTitle;
    },
    onChooseApartment: function () {
      if (this.selectedUnitTitle === "")
        return;

      this.$router.push(`/${this.$route.params.project}/${this.selectedUnitTitle}`);
    },
  },
  computed: {
    ...mapGetters([apartments]),
  },
});
