var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"asset-menu"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",attrs:{"disabled":!_vm.isAnAssetSelected},on:{"click":function($event){return _vm.removeAsset()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("Remove")])])]}}])},[_c('span',[_vm._v("Remove selected asset")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",attrs:{"disabled":!_vm.isAnAssetSelected},on:{"click":function($event){return _vm.replaceAsset()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("Replace")])])]}}])},[_c('span',[_vm._v("Replace selected asset")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.addNewAsset()}}},'button',attrs,false),on),[_c('div',{staticClass:"button-text"},[_vm._v("Add new")])])]}}])},[_c('span',[_vm._v("Add new asset")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }