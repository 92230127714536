

























import Vue from "vue";
import { backend, Project } from "@/lib/backend";
import LoadingCursor from "@/components/LoadingCursor.vue";
import BuildingPlansTable from "@/components/BuildingPlansTable.vue";

type ComponentData = {
  selectedProjectId?: string;
  projects: Project[];
};

export default Vue.extend({
  name: "ProjectSelection",
  components: { LoadingCursor, BuildingPlansTable },
  data(): ComponentData {
    return {
      selectedProjectId: undefined,
      projects: [],
    };
  },

  methods: {
    downloadGlb() {
      if (!this.selectedProjectId) return;
      window.open(backend.getSceneDownloadUrl(this.selectedProjectId), "_blank");
    },
  },

  async mounted() {
    this.projects = await backend.getProjects();
  },
});
