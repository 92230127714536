









import Vue from "vue";
import { mapGetters } from "vuex";
import LoadingCursor from "./components/LoadingCursor.vue";
import { isCurrentlyLoading } from "@/store";

console.log(`QiSpace Web Builder version: ${process.env.VUE_APP_VERSION}`);

export default Vue.extend({
  name: "App",
  components: {
    LoadingCursor,
  },
  computed: {
    ...mapGetters([isCurrentlyLoading]),
  },
  mounted() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
});
