import { Mesh, Vector3, Object3D, Euler, Material } from "three";

import { Opening, OPENING_TYPE } from "@/three/building-objects/Opening";
import { Room, ROOM_CATEGORY } from "./Room";
import { getObjectParameters, getObjectsByType, setObjectParameters, createObjectOfType } from "@/three/ThreeJsHelpers";
import { ThreeJsContext } from "../ThreeJsContext";
import store from "@/store";
import { PlanarSurface, SURFACE_TYPE, SURFACE_CATEGORY } from "../objects/PlanarSurface";

export class Apartment {
  private _id: string;
  private _originalId: string;

  public get hasEntrance(): boolean {
    return this.openings.some((opening) => opening.isEntrance);
  }

  public originalId(): string | undefined {
    return this._originalId;
  }

  public currentId(): string {
    return this._id;
  }

  public setCurrentId(id: string): void {
    this._originalId = this._id;
    this._id = id;

    const parameters = getObjectParameters(this.apartmentNode);
    parameters.set("apartmentId", this._id);
    parameters.set("originalId", this._originalId ? this._originalId : "");
    setObjectParameters(parameters, this.apartmentNode);
  }

  public resetId(): void {
    this._id = this._originalId;
    const parameters = getObjectParameters(this.apartmentNode);
    parameters.set("apartmentId", this._id ? this._id : "");
    setObjectParameters(parameters, this.apartmentNode);
  }

  private _name: string;
  private _originalName: string;

  public originalName(): string {
    return this._originalName;
  }

  public currentName(): string {
    return this._name;
  }

  public setCurrentName(name: string): void {
    this._originalName = this._name;
    this._name = name;

    const parameters = getObjectParameters(this.apartmentNode);
    parameters.set("apartmentName", this._name);
    parameters.set("originalName", this._originalName ? this._originalName : "");
    setObjectParameters(parameters, this.apartmentNode);
  }

  public resetName(): void {
    this._name = this._originalName;

    const parameters = getObjectParameters(this.apartmentNode);

    parameters.set("apartmentName", this._name ? this._name : "");
    setObjectParameters(parameters, this.apartmentNode);
  }

  public readonly apartmentNode: Object3D;
  public readonly spaceMesh: Mesh;
  public readonly hullMesh?: Mesh;
  public readonly rooms: Room[] = [];

  public readonly camerasNode: Object3D;
  public readonly metadataNode: Object3D;
  public readonly assetsNode: Object3D;
  public readonly surfacesNode: Object3D;
  public readonly openingsNode: Object3D;
  public readonly moldingsNode: Object3D;

  private _openings: Opening[] = [];

  public get openings(): Opening[] {
    return this._openings;
  }

  public set openings(openings: Opening[]) {
    this._openings = openings;
  }

  public readonly surfaces: PlanarSurface[] = [];

  constructor(apartmentNode: Object3D) {
    this.apartmentNode = apartmentNode;

    const apartmentNodeParameters = getObjectParameters(apartmentNode);

    this._id = apartmentNodeParameters.get("apartmentId")!;
    if (!this._id) console.error(`Apartment ${apartmentNodeParameters.get("globalId")!} has no apartmentId `);
    this._originalId = this._id;

    this._name = apartmentNodeParameters.get("apartmentName")!;
    this._name = this._name ? this._name : apartmentNodeParameters.get("Name")!;
    if (!this._name) {
      this._name = "Apartment " + this._id;
    }
    this._originalName = this._name;

    apartmentNodeParameters.set("originalName", this._originalName ? this._originalName : "");
    setObjectParameters(apartmentNodeParameters, this.apartmentNode);

    this.metadataNode = getObjectsByType("metadata", this.apartmentNode)[0];
    if (!this.metadataNode) this.metadataNode = createObjectOfType("metadata", this.apartmentNode);

    this.surfacesNode = getObjectsByType("apartmentSurfaces", this.apartmentNode)[0];
    if (!this.surfacesNode) this.surfacesNode = createObjectOfType("apartmentSurfaces", this.apartmentNode);

    this.removeSurfaces();

    this.camerasNode = getObjectsByType("cameras", this.apartmentNode)[0];
    if (!this.camerasNode) this.camerasNode = createObjectOfType("cameras", this.apartmentNode);

    this.openingsNode = getObjectsByType("openings", this.apartmentNode)[0];
    if (!this.openingsNode) this.openingsNode = createObjectOfType("openings", this.apartmentNode);

    this.moldingsNode = getObjectsByType("moldings", this.apartmentNode)[0];
    if (!this.moldingsNode) this.moldingsNode = createObjectOfType("moldings", this.apartmentNode);

    this.assetsNode = getObjectsByType("assets", this.apartmentNode)[0];
    if (!this.assetsNode) this.assetsNode = createObjectOfType("assets", this.apartmentNode);

    this.hullMesh = this.metadataNode.children.find(
      (apartment: Object3D) => getObjectParameters(apartment).get("type") === "apartmentShell"
    ) as Mesh;

    const spaceMesh = this.metadataNode.children.find(
      (apartment: Object3D) => getObjectParameters(apartment).get("type") === "apartmentSpace"
    );

    if (!spaceMesh) console.error(`No apartment space in apartment ${this._name} GlobalId: ${apartmentNodeParameters.get("globalId")!} apartmentId: ${this._id}`);

    const isRoom = (object: Object3D) => {
      const params = getObjectParameters(object);
      const type = params.get("type");
      return type === "roomSpace" || type === "balconySpace";
    };

    const roomMeshes = this.metadataNode.children.filter((apartment: Object3D) => isRoom(apartment));

    if (roomMeshes.length === 0) console.error(`No room spaces in apartment ${this._name}`);

    this.spaceMesh = spaceMesh as Mesh;
    if (!this.spaceMesh.material) {
      console.error(`Apartment ${this._name} space mesh has no material GlobalId: ${apartmentNodeParameters.get("globalId")!} apartmentId: ${this._id}`);
    }

    if (this.hullMesh && (!this.hullMesh.material && this.spaceMesh.material)) {
      this.hullMesh.material = this.spaceMesh.material;
    }

    this.createOpenings();

    this.rooms = roomMeshes.map((roomMesh) => new Room(roomMesh as Mesh, this));

    const allRoomSurfaces = this.rooms.flatMap((room) => room.planarSurfaces);
    this.openings.forEach((opening) => {
      let connectedRooms = opening.spaceIds.map((spaceId) => this.rooms.find((room) => room.roomId === spaceId));
      connectedRooms = connectedRooms.filter((room) => room) as Room[];

      const connectedSurfaces = connectedRooms.flatMap((room) => room!.planarSurfaces);
      let surfaces = connectedSurfaces.length ? connectedSurfaces : allRoomSurfaces;
      surfaces = surfaces.filter((surface) => surface && surface.material);

      opening.attachToHitSurfaces(surfaces);
    });

    this.openings.forEach((opening) => opening.updateTangentialSurfaces());
    this.openings.forEach((opening) => opening.updateLinkedSurfaces());

    this.rooms.forEach((room) => {
      let roomSurfacesNode: Object3D;

      roomSurfacesNode = new Object3D();

      const surfacesNodeParameters = new Map<string, string>();
      surfacesNodeParameters.set("type", "roomSurfaces");
      surfacesNodeParameters.set("roomSurfacesId", room.roomId);
      surfacesNodeParameters.set("category", ROOM_CATEGORY[room.category]);
      surfacesNodeParameters.set("name", `${room.name}_${room.longName}`);

      setObjectParameters(surfacesNodeParameters, roomSurfacesNode);

      this.surfacesNode.add(roomSurfacesNode);

      room.planarSurfaces.forEach((surface) => {
        if (!surface.parent) {
          roomSurfacesNode.add(surface);
          this.surfaces.push(surface);
        }
      });
    });

    this.showMetadata(false);
    this.showSurfaces(false);

    this.setOpeningSurfaceTraversalMetadata();
  }

  private setOpeningSurfaceTraversalMetadata(): void {
    this.openings.forEach((opening) => {
      let categories: SURFACE_CATEGORY[] = [];
      if (opening.openingType == OPENING_TYPE.window) {
        categories.push(SURFACE_CATEGORY.windowWall);
      }
      if (opening.openingsIsTraversable) {
        categories.push(SURFACE_CATEGORY.traversableSurface);
      }
      if ((opening.openingType == OPENING_TYPE.door || opening.openingType == OPENING_TYPE.opening) && opening.isEntrance) {
        categories.push(SURFACE_CATEGORY.mainDoorWall);
      }

      [...opening.linkedSurfacesByIds.values()].forEach((surface) => {
        surface.AddToSurfaceCategories(categories);
      });
    });

    const mainDoorSurfaces = this.surfaces.filter((surface) => surface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall));

    if (!mainDoorSurfaces.length) {
      console.error(`No entrance to apartment: ${this._name}`);
      return;
    }

    if (mainDoorSurfaces.length > 1) console.error(`More than one entrance to apartment: ${this._name}`);

    this.iterateOpeningRoomTraversal();

    const entranceRoom = mainDoorSurfaces[0].room;

    entranceRoom.assignMainRoomSurfaces();

    const allMainDoorSurfaces = this.surfaces.filter((surface) => surface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall));

    allMainDoorSurfaces.forEach((mainDoorSurface) => {
      const roomWalls = mainDoorSurface.room.planarSurfaces
        .filter((surface) => surface.surfaceType === SURFACE_TYPE.wall)
        .filter((surface) => !surface.hasSurfaceCategory(SURFACE_CATEGORY.mainDoorWall))
        .filter((surface) => surface != mainDoorSurface);

      let wallWithMostTraversableRooms: PlanarSurface | undefined;
      let biggestTraversableRoomsTotal = 1;

      let wallWithBiggestOverlap: PlanarSurface | undefined;
      let biggestOverlap = 0.001;

      let lineOfSightWall: PlanarSurface | undefined;
      let biggestLineOfSightOverlap = 0.001;


      const connectedWalls = mainDoorSurface.getConnectedSurfaces().filter((surface) => surface.surfaceType === SURFACE_TYPE.wall);
      roomWalls.forEach((wall) => {

        // Exclude walls that are direct neighbors for evaluation
        if (connectedWalls.includes(wall)) return;

        let wallTraversableRoomsTotal = 0;
        if (wall.linkedOpenings.length) {
          wall.linkedOpenings.forEach((opening) => {
            wallTraversableRoomsTotal = Math.max(wallTraversableRoomsTotal, opening.traversableRoomsTotal)
          });
        }

        if (wallTraversableRoomsTotal > biggestTraversableRoomsTotal) {
          wallWithMostTraversableRooms = wall;
          biggestTraversableRoomsTotal = wallTraversableRoomsTotal;
        }

        const overlap = mainDoorSurface.overlapToSurface(wall);

        if (overlap > biggestOverlap) {
          wallWithBiggestOverlap = wall;
          biggestOverlap = overlap;

          const hasLineOfSight = mainDoorSurface.hasLineOfSight(wall);

          if (hasLineOfSight) {
            if (overlap > biggestLineOfSightOverlap) {
              lineOfSightWall = wall;
              biggestLineOfSightOverlap = overlap;
            }
          }
        }
      });

      let oppositeWall: PlanarSurface | undefined = wallWithBiggestOverlap;

      if (wallWithMostTraversableRooms) {
        if (lineOfSightWall?.hasSurfaceCategory(SURFACE_CATEGORY.traversableSurface)) {
          oppositeWall = lineOfSightWall;
        } else {
          oppositeWall = wallWithMostTraversableRooms;
        }
      }

      if (!oppositeWall) {
        const room = mainDoorSurface.room;
        console.error(`No opposite wall found for main door surface: ${mainDoorSurface.guid} room: ${room.name} apartment: ${this.currentName()} `);
      } else {
        oppositeWall.AddToSurfaceCategories([SURFACE_CATEGORY.oppositeOfMainDoorWall])
      }
    });

    const assignNeighborCategory = (surface: PlanarSurface, category: SURFACE_CATEGORY.leftOfMainDoorWall | SURFACE_CATEGORY.rightOfMainDoorWall, categoryIndex = 0) => {

      if (categoryIndex > 1024) {
        console.error(`Surface category recursion too high of room: ${surface.room.roomId}`);
        return;
      }

      const ignoreCategories = [SURFACE_CATEGORY.leftOfMainDoorWall, SURFACE_CATEGORY.rightOfMainDoorWall, SURFACE_CATEGORY.oppositeOfMainDoorWall, SURFACE_CATEGORY.mainDoorWall];
      const isLeft = category == SURFACE_CATEGORY.leftOfMainDoorWall;

      const neighbors = (isLeft ? surface.getWallNeighborsOnLeftSide() : surface.getWallNeighborsOnRightSide())
        .filter((neighbor) => !neighbor.hasSurfaceCategories(ignoreCategories))
        .filter((neighbor) => neighbor !== surface);

      neighbors.forEach((neighbor) => {
        neighbor.AddToSurfaceCategories([category], categoryIndex);
        assignNeighborCategory(neighbor, category, categoryIndex + 1);
      });
    }

    allMainDoorSurfaces.forEach((mainDoorSurface) => {
      assignNeighborCategory(mainDoorSurface, SURFACE_CATEGORY.leftOfMainDoorWall);
    });

    allMainDoorSurfaces.forEach((mainDoorSurface) => {
      assignNeighborCategory(mainDoorSurface, SURFACE_CATEGORY.rightOfMainDoorWall);
    });

    const unassignedSurfaceWalls = this.surfaces
      .filter((surface) => surface.surfaceType === SURFACE_TYPE.wall)
      .filter((surface) => !surface.hasSurfaceCategory(SURFACE_CATEGORY.leftOfMainDoorWall) && !surface.getSurfaceCategories().length);

    unassignedSurfaceWalls.forEach((surface) => {
      surface.AddToSurfaceCategories([SURFACE_CATEGORY.pillarWall]);
    });
  }

  private iterateOpeningRoomTraversal(): void {
    const entranceOpening = this.openings.filter((opening) => opening.isEntrance)[0];
    entranceOpening.assignTraversableRooms();
  }

  private removeSurfaces(): void {
    // Dispose and destroy all children of the surfaceNode
    const toRemove: Object3D[] = [];
    this.surfacesNode.children.forEach((surfaceNode) => {
      surfaceNode.children.forEach((surface) => {
        (surface as Mesh)?.geometry?.dispose();
        ((surface as Mesh)?.material as Material)?.dispose();
        toRemove.push(surface);
      });
    });

    this.surfacesNode.children.forEach((surfaceNode) => {
      (surfaceNode as Mesh)?.geometry?.dispose();
      ((surfaceNode as Mesh)?.material as Material)?.dispose();
      toRemove.push(surfaceNode);
    });
    toRemove.forEach((surface) => surface.removeFromParent());
    this.surfacesNode.children = [];
  }

  public createSurfaceMoldings(): void {
    this.clearExistingMoldings();

    const accumulatedSurfaceIds: string[] = [];
    this.rooms.forEach((room) => {
      accumulatedSurfaceIds.concat(room.createSurfaceMoldings(accumulatedSurfaceIds));
    });
  }

  public showSurfaces(show = true): void {
    this.surfaces.forEach((surface) => (surface.visible = show));
  }

  public showMetadata(show = true): void {
    this.metadataNode && this.metadataNode.children.forEach((child) => (child.visible = show));
    this.showMoldingLines(show);
  }

  public showMoldingLines(show = true): void {
    this.moldingsNode.children.forEach((molding) => (molding.visible = show));
  }

  public getAllConnectedApartments(): Apartment[] {
    const openings = this.openings.filter((opening) => opening.type === "Opening") as Opening[];

    let allLinked = openings.flatMap((opening) => opening.getLinkedApartments()).filter((apt) => apt) as Apartment[];

    allLinked = [...new Set(allLinked)];

    const connectedApartments = allLinked.filter((apartment) => apartment._originalId !== this._originalId);

    return connectedApartments;
  }

  public getSurfaces(): PlanarSurface[] {
    return this.surfaces;
  }

  public showOpenings(show = true): void {
    this.openings.forEach((opening) => opening.openingPolygon && (opening.openingPolygon!.visible = show));
  }

  public showAssets(show = true): void {
    this.assetsNode.children.forEach((assetPlaceholder) => {
      if (assetPlaceholder.children.length !== 0) {
        assetPlaceholder.children[0].visible = show;
      } else {
        if (show) {
          const assetId = getObjectParameters(assetPlaceholder).get("assetId");

          const url = `${process.env.VUE_APP_ASSET_URL}/data/assets/${assetId}/models/${store.getters.lod}/content`;
          ThreeJsContext.getInstance()
            .placeGltf(url, new Vector3(), new Euler(), assetPlaceholder)
            .finally(() => {
              assetPlaceholder.children.forEach((child) => {
                child.position.copy(new Vector3());
                child.rotation.copy(new Euler());
              });
            });
        }
      }
    });
  }

  public showGizmos(show = true): void {
    this.openings.forEach((opening: Opening) => opening.showGizmos(show));
  }

  private createOpenings() {
    if (!this.openingsNode.children.length) return;

    const childrenReverse = [...this.openingsNode.children].reverse();
    childrenReverse.forEach((openingNode) => Opening.replaceNode(openingNode, this));
  }

  private clearExistingMoldings(): void {
    const toRemove: Object3D[] = [];
    this.moldingsNode.children.forEach((molding) => {
      (molding as Mesh)?.geometry?.dispose();
      ((molding as Mesh)?.material as Material)?.dispose();
      toRemove.push(molding);
    });
    toRemove.forEach((molding) => molding.removeFromParent());
    this.moldingsNode.children = [];
  }
}
