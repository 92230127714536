import { Vector2, Vector3, BufferGeometry, Float32BufferAttribute, Box2, Plane, Line3 } from "three";
import { Line2 } from "../math/Line2";
import { mergeLineChunks, mergePointChunks, differencePolygonHolePairs, triangulate, TriangulatedData, PolygonHolePair } from "../tools/PolygonTools";
import { PlanarSurface, SURFACE_TYPE, SURFACE_CATEGORY } from "./PlanarSurface";
import { Opening } from "../building-objects/Opening";
import { createLinesHelper } from "../ThreeJsHelpers";

enum HOLE_SIDE_SHAPE {
  left = "left",
  top = "top",
  right = "right",
  bottom = "bottom",
}

type indicesPointsUvs = [number[], Vector3[], Vector2[]];

const UP_2D = new Vector2(0, 1);
const RIGHT_2D = new Vector2(1, 0);
const DOWN_2D = new Vector2(0, -1);

export class SurfaceGeoCreator {
  private _localOutline: Line2[] = [];
  private _uvOffset: Vector2;

  private _indices: number[] = [];
  private _points: Vector3[] = [];
  private _rawUvs: Vector2[] = [];

  /* Uncorrected uvs. The uv coordinates in relation to surface origin. */
  public get rawUvs() {
    return this._rawUvs;
  }

  private _planarCorners: Vector3[] = [];
  public get planarCorners() {
    return this._planarCorners;
  }

  private _holes: Vector2[][] = [];
  public get holes() {
    return this._holes;
  }

  private _planes: (Plane | undefined)[] = [];
  public get planes() {
    return this._planes;
  }

  private _debug = false;

  private surface?: PlanarSurface;

  public constructor(localOutline: Line2[], uVOffset: Vector2, debug = false) {
    this._localOutline = localOutline;
    this._uvOffset = uVOffset;
    this._debug = debug;
  }

  /** Create a planar geometry from a surface. @returns The hashes of neighbors that need to be updated. */
  public createPlanarGeometry(surface: PlanarSurface): string[] {
    this.surface = surface;

    [this._holes, this._planes] = this._createHolesPlanesFromSurface(this.surface);

    const flushSides = surface.getLocalFlushSides();

    [this._indices, this._points, this._rawUvs] = this._createIndicesPositionsUvs(
      flushSides,
      this._localOutline,
      this._holes,
      this._uvOffset
    );

    let holeDepths: number[][] = [];

    const hasHoles = this._holes.length > 0;
    if (hasHoles) {
      holeDepths = this._createDepthChunks(this._holes, this._planes, surface);

      const isOneHoleWithNoDepth = this._holes.length == 1 && holeDepths.some((depths) => depths.length === 0);

      if (isOneHoleWithNoDepth) {
        const coincident = this._isHoleCoincident(this._holes, this._localOutline);
        if (coincident) {
          return [];
        }
      }
    }

    return this._addHoleSideShapes(surface, this._localOutline, this._holes, holeDepths);
  }

  /** Create holes and intersection planes from the openings of a surface. @returns The holes and planes of the given surface */
  private _createHolesPlanesFromSurface(surface: PlanarSurface): [Vector2[][], (Plane | undefined)[]] {
    let [holes, planes] = this._createHoleAndPlanesFromOpenings(surface);
    [holes, planes] = this._mergeHoles(holes, planes);

    holes = holes.concat(surface.primordialHoles);
    planes = [...planes];
    surface.primordialHoles.forEach((hole, index) => {
      planes.push(undefined);
    });
    return [holes, planes];
  }

  private _createIndicesPositionsUvs(
    flushSides: Line2[][],
    localOutline: Line2[],
    holes: Vector2[][],
    uvOffset: Vector2
  ): indicesPointsUvs {
    let planarCorners = this._mergeFlushSidesWithCorners(flushSides, localOutline);

    if (planarCorners.length < 3) return [[], [], []];

    // Save the corners of the planar geometry for later use, before assigning geometry that isn't planar.
    this._planarCorners = planarCorners.map((point) => new Vector3(point.x, point.y, 0)).reverse();

    // Because of risk of bisection of polygons by holes. A list of polygon and their respective holes is created.
    const polygonHolePairs: PolygonHolePair[] = differencePolygonHolePairs(holes, planarCorners);

    const [points2D, indices] = this._triangulatePolygonHolePairs(polygonHolePairs);

    const [points3D, uvs] = this._create3DCornersUVsForPlanar(points2D, uvOffset);
    return [indices, points3D, uvs];
  }

  /** Triangulate a list of polygons and their respective holes. @returns The triangulated points and indices */
  private _triangulatePolygonHolePairs(polygonHolePairs: PolygonHolePair[]): [Vector2[], number[]] {
    const points: Vector2[] = [];
    const indices: number[] = [];

    let currentOffset = 0;
    polygonHolePairs.forEach((pair) => {
      const triangulatedData: TriangulatedData = triangulate(pair.polygon, pair.holes, currentOffset, this.surface?.room.roomId);
      currentOffset = triangulatedData.offset;

      triangulatedData.corners.forEach((corner) => {
        points.push(corner);
      });

      triangulatedData.indices.forEach((index) => {
        indices.push(index);
      });
    });
    return [points, indices];
  }

  /** Create the 3D positions of planar surfaces as well as the UVs */
  private _create3DCornersUVsForPlanar(corners: Vector2[], uvOffset: Vector2): [Vector3[], Vector2[]] {
    const corners3D = corners.map((point) => new Vector3(point.x, point.y, 0));
    const uvs = corners.map((point) => new Vector2(point.x, point.y));
    return [corners3D, uvs];
  }
  /** Given holes in local 2D space, create the depths for each corner of how deeply each corner should penetrate beyond the given surface */
  private _createDepthChunks(holes2D: Vector2[][], planes: (Plane | undefined)[], surface: PlanarSurface): number[][] {
    const holeDepth = (holeCorner: Vector3, plane: Plane | undefined, surfaceType: SURFACE_TYPE) => {
      if (!plane) return 0;

      if (surfaceType === SURFACE_TYPE.floor) return 0;

      const planeDistance = plane.distanceToPoint(holeCorner);
      const multiplier = surfaceType === SURFACE_TYPE.ceiling ? -1 : -0.5;

      return planeDistance * multiplier;
    };

    const holesInLocalSpace = holes2D.map((hole) => hole.map((point) => new Vector3(point.x, point.y, 0)));
    const holesInWorldSpace = holesInLocalSpace.map((hole) => hole.map((point) => surface.localToWorld(point)));

    return holesInWorldSpace.map((hole, index) => hole.map((point) => holeDepth(point, planes[index], surface.surfaceType)));
  }

  /** Add hole side shape indices and positions to geometry. If the hole side shape is on a neighbor surface, add it to the neighbor surface. @returns The hashes of neighbors that need to be updated.*/
  private _addHoleSideShapes(
    surface: PlanarSurface,
    localOutline: Line2[],
    holes: Vector2[][],
    depthsPerHole: number[][]
  ): string[] {
    const neighborHashesToUpdate: string[] = [];

    const surfaceIsSlanted = surface.hasSurfaceCategory(SURFACE_CATEGORY.slanted);

    holes.forEach((hole, holeIndex) => {
      for (let i = 0; i < hole.length; i++) {
        const depths = depthsPerHole[holeIndex];
        const depthsAboveZero = depths.some((depth) => depth > 0.00065);
        if (!depthsAboveZero) continue;
        const start = hole[i];
        const end = hole[(i + 1) % hole.length];
        const lineCenter = start.clone().add(end).divideScalar(2);

        const intersectingEdge = Line2.getIntersectingLine(lineCenter, localOutline);
        const holeEdge = new Line2(start, end);

        if (surfaceIsSlanted || !intersectingEdge) {
          this._populateForSideShape(holeEdge, depths);
        } else {
          const surfaceAndHash = surface.getNeighborAndHashByLocalLine(intersectingEdge);

          if (surfaceAndHash) {
            const [neighborSurface, neighborHash] = surfaceAndHash;
            neighborSurface.appendFlushSide(holeEdge, depths, neighborHash, surface);
            neighborHashesToUpdate.push(neighborHash);
          }
        }
      }
    });
    return neighborHashesToUpdate;
  }

  /** Populate indices, corner positions, and uvs for opening side shapes of this surface. */
  private _populateForSideShape(line: Line2, depths: number[]): void {
    const side = this._holeSideFromLineDirection(line);

    const startDepth = depths[0];
    const endDepth = depths[1];

    const startUv = new Vector2(line.start.x, line.start.y);
    const endUv = new Vector2(line.end.x, line.end.y);

    const sideCorners: Vector3[] = [];
    const sideUvs: Vector2[] = [];

    sideCorners.push(new Vector3(line.start.x, line.start.y, 0));
    sideCorners.push(new Vector3(line.start.x, line.start.y, -startDepth));
    sideCorners.push(new Vector3(line.end.x, line.end.y, -endDepth));
    sideCorners.push(new Vector3(line.end.x, line.end.y, 0));

    if (side == HOLE_SIDE_SHAPE.top) {
      sideUvs.push(new Vector2(startUv.x, startUv.y));
      sideUvs.push(new Vector2(startUv.x, startUv.y + startDepth));
      sideUvs.push(new Vector2(endUv.x, endUv.y + endDepth));
      sideUvs.push(new Vector2(endUv.x, endUv.y));
    } else if (side == HOLE_SIDE_SHAPE.bottom) {
      sideUvs.push(new Vector2(startUv.x, startUv.y));
      sideUvs.push(new Vector2(startUv.x, startUv.y - startDepth));
      sideUvs.push(new Vector2(endUv.x, endUv.y - endDepth));
      sideUvs.push(new Vector2(endUv.x, endUv.y));
    } else if (side == HOLE_SIDE_SHAPE.right) {
      sideUvs.push(new Vector2(startUv.x, startUv.y));
      sideUvs.push(new Vector2(startUv.x - startDepth, startUv.y));
      sideUvs.push(new Vector2(endUv.x - endDepth, endUv.y));
      sideUvs.push(new Vector2(endUv.x, endUv.y));
    } else if (side == HOLE_SIDE_SHAPE.left) {
      sideUvs.push(new Vector2(startUv.x, startUv.y));
      sideUvs.push(new Vector2(startUv.x + startDepth, startUv.y));
      sideUvs.push(new Vector2(endUv.x + endDepth, endUv.y));
      sideUvs.push(new Vector2(endUv.x, endUv.y));
    }

    this._indices = this._concatQuadIndices(this._indices, this._points.length);
    this._points = this._points.concat(sideCorners);
    this._rawUvs = this._rawUvs.concat(sideUvs);
  }

  /** Detect if any holes are coincident with this surface. This means it covers the surface and has no depth*/
  private _isHoleCoincident(holes: Vector2[][], localOutline: Line2[], accuracy = 0.001) {
    if (holes.length != 1) return false;
    const hole = holes[0];

    const thisArea = localOutline.reduce((acc, line) => acc + line.distance(), 0);
    const holeArea = hole.reduce((acc, point, index) => {
      const nextPoint = hole[(index + 1) % hole.length];
      return acc + point.distanceTo(nextPoint);
    }, 0);

    if (Math.abs(thisArea - holeArea) > accuracy) return false;

    const linesCoincidingWithHole = localOutline
      .map((line) => {
        const closestDistanceToStart = hole.reduce((acc, point) => {
          return Math.min(acc, line.start.distanceTo(point));
        }, Number.MAX_VALUE);
        return closestDistanceToStart < accuracy;
      })
      .filter((x) => x);

    const holeCoincidesWithSurface = (linesCoincidingWithHole.length = localOutline.length);
    if (holeCoincidesWithSurface) return true;

    return false;
  }

  /** Given a BufferGeometry instance update it with the contained mesh data*/
  public setupGeometryBuffer(geometry: BufferGeometry): BufferGeometry {
    const geometryData = [this._indices, this._points, this._rawUvs] as indicesPointsUvs;
    return this._fillGeometryBuffer(geometry, geometryData, this._uvOffset);
  }

  /** Given a BufferGeometry instance and an instance of indices, points, and uvs, update the BufferGeometry instance with the given data */
  private _fillGeometryBuffer(geometry: BufferGeometry, geometryData: indicesPointsUvs, uvOffset: Vector2): BufferGeometry {
    const [indices, positions, uvs] = geometryData;
    const geometryPositions = new Float32Array(positions.length * 3);
    positions.forEach((point, index) => {
      geometryPositions[index * 3] = point.x;
      geometryPositions[index * 3 + 1] = point.y;
      geometryPositions[index * 3 + 2] = point.z;
    });

    const geometryUVs = new Float32Array(uvs.length * 2);
    uvs.forEach((uv, index) => {
      geometryUVs[index * 2] = uv.x + uvOffset.x;
      geometryUVs[index * 2 + 1] = -(uv.y + uvOffset.y);
    });

    geometry.setAttribute("position", new Float32BufferAttribute(geometryPositions, 3));
    geometry.setIndex(indices);
    geometry.setAttribute("uv", new Float32BufferAttribute(geometryUVs, 2));
    geometry.setAttribute("normal", new Float32BufferAttribute(new Float32Array(geometryPositions.length), 3));
    geometry.computeVertexNormals();

    return geometry;
  }

  /** Get the opening outlines and depth from a given Opening */
  private _openingOutlineAndPlane(opening: Opening, surface: PlanarSurface): [Vector2[], Plane] {
    const position = surface.worldToLocal(opening.position.clone());

    const outline: Vector2[] = opening.cornersOnSurface2D(surface);

    const linkedSurfaces = opening.getLinkedSurfaces();

    let surfacePlane = linkedSurfaces.find((otherSurf: PlanarSurface) => otherSurf.guid !== surface.guid)?.plane;
    surfacePlane = surfacePlane ? surfacePlane! : surface.plane!;

    if (linkedSurfaces.length < 2) {
      const planePoint = surfacePlane.projectPoint(position, new Vector3());
      const planePointButMovedAlongNormal = planePoint
        .clone()
        .add(surfacePlane.normal.clone().multiplyScalar(opening.scale.z * 2));
      surfacePlane = new Plane().setFromNormalAndCoplanarPoint(surfacePlane.normal, planePointButMovedAlongNormal);
    }

    return [outline, surfacePlane];
  }

  /** Given an array of Openings, return an array of holes and an array of depths */
  private _createHoleAndPlanesFromOpenings(surface: PlanarSurface): [Vector2[][], (Plane | undefined)[]] {
    const holes: Vector2[][] = [];
    const planes: Plane[] = [];

    surface.linkedOpenings
      .map((opening) => this._openingOutlineAndPlane(opening, surface))
      .forEach(([hole, plane]) => {
        holes.push(hole);
        planes.push(plane);
      });

    return [holes, planes];
  }

  private _mergeFailure(chunks: Line2[][], printPoints = false) {
    console.error(`Merged result has more than one path. Surface: ${this.surface!.room.roomId}`);
    if (!printPoints) return;
    chunks.forEach((chunk, chunkIndex) => {
      console.log(`Path: ${chunkIndex}`);
      chunk.forEach((line) => console.log(`X: ${line.start.x}, Y: ${line.start.y}`));
    });
  }

  /** Given side edges of openings that are flush with this geometry i.e. tangent to this surface. Merge the corners of this surface with the side edges of the openings. */
  private _mergeFlushSidesWithCorners(flushSides: Line2[][], outline: Line2[]): Vector2[] {
    if (flushSides.length === 0) return outline.map((line) => line.start.clone());
    const allOutlines = flushSides;
    allOutlines.unshift(outline);

    let mergedLines: Line2[][] = [];
    try {
      const decimate = true;
      mergedLines = mergeLineChunks(allOutlines, decimate);
      if (mergedLines.length > 1) this._mergeFailure(mergedLines);
    } catch (error) {
      const apartmentName = this.surface?.room.apartment.originalName();
      console.error(`Error flush side merge. Space id: ${this.surface?.room.roomId}. Original apartment name: ${apartmentName}.`, error);
      allOutlines.forEach((outline, index) => {
        const outline3D: Line3[] = outline.map((line) => this.surface!.edgeToWorldSpace(line));

        createLinesHelper(outline3D, 0xff0000 * 2.2);
      });
    }

    return mergedLines.length > 0 ? mergedLines[0].map((line) => line.start) : [];
  }

  /** Given two arrays of hole positions and hole depths. return equivalent merged arrays if holes touch. Otherwise return unchanged arrays */
  private _mergeHoles(holes: Vector2[][], planes: (Plane | undefined)[]): [Vector2[][], (Plane | undefined)[]] {
    if (!holes.length) return [[], []];

    holes = this._clampOutlines(holes);

    const mergedHoles = mergePointChunks(holes);
    const shouldNewMerge = mergedHoles.length != holes.length;
    if (shouldNewMerge) return this._getMergedHoles(holes, planes, mergedHoles);

    return [holes, planes];
  }

  private _getMergedHoles(
    holes: Vector2[][],
    planes: (Plane | undefined)[],
    mergedHoles: Vector2[][]
  ): [Vector2[][], (Plane | undefined)[]] {
    mergedHoles = mergedHoles.map((hole) => hole.reverse());

    const unmergedCenterPoints = holes.map((hole) =>
      hole.reduce((acc, point) => acc.add(point), new Vector2()).divideScalar(hole.length)
    );

    const mergedHoleBounds: Box2[] = mergedHoles.map((hole) => new Box2().setFromPoints(hole));
    const planeByIndex = new Map<number, Plane | undefined>();

    mergedHoleBounds.forEach((mergedBounds, mergedIndex) => {
      unmergedCenterPoints.forEach((centerPoint, centerIndex) => {
        mergedBounds.containsPoint(centerPoint) && planeByIndex.set(mergedIndex, planes[centerIndex]);
      });
    });

    const mergedPlanes: (Plane | undefined)[] = [];
    mergedHoleBounds.forEach((mergedBounds, mergedIndex) => {
      mergedPlanes.push(planeByIndex.get(mergedIndex) || undefined);
    });

    return [mergedHoles.map((x) => x), mergedPlanes];
  }

  /** Given a line, return on which side of an opening this line is in relation the an opening */
  private _holeSideFromLineDirection(line: Line2): HOLE_SIDE_SHAPE {
    const lineDirection = line.delta(new Vector2()).normalize();
    if (UP_2D.dot(lineDirection) > 0.5) {
      return HOLE_SIDE_SHAPE.left;
    }
    if (RIGHT_2D.dot(lineDirection) > 0.5) {
      return HOLE_SIDE_SHAPE.top;
    }
    if (DOWN_2D.dot(lineDirection) > 0.5) {
      return HOLE_SIDE_SHAPE.right;
    }
    return HOLE_SIDE_SHAPE.bottom;
  }

  /** Concatenate standard triangulated indices for a quad */
  private _concatQuadIndices(indices: number[], offset: number): number[] {
    let quadIndices = [3, 0, 1, 1, 2, 3];
    quadIndices = quadIndices.map((index) => offset + index);
    indices = indices.concat(quadIndices);
    return indices;
  }

  private _floatsAreClose(a: number, b: number, margin = 0.0004) {
    return Math.abs(a - b) < margin;
  }

  /** Clamp all x and Y numbers to similar values. Decreasing position uniqueness */
  private _clampOutlines(outlines: Vector2[][], margin = 0.0004): Vector2[][] {
    const xPoints = new Set<number>();
    const yPoints = new Set<number>();

    outlines.forEach((points) => {
      points.forEach((point) => {
        const closeX = [...xPoints].find((x) => this._floatsAreClose(x, point.x, margin));
        const closeY = [...yPoints].find((y) => this._floatsAreClose(y, point.y, margin));

        if (closeX) point.x = closeX;
        else xPoints.add(point.x);
        if (closeY) point.y = closeY;
        else yPoints.add(point.y);
      });
    });
    return outlines;
  }
}
