



































import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { Surface } from "@/interfaces/Surface";
import ThumbnailGallery from "../components/ThumbnailGallery.vue";
import { allSurfaces, currentSurfaceTypeId, setAllSurfaces, setCurrentSurfaceTypeId } from "@/store/surfaces";
import { backend } from "@/lib/backend";

export default Vue.extend({
  name: "SurfaceBrowser",
  components: {
    ThumbnailGallery,
  },
  data(): {
    surfaces: string[];
    selectedSurface: string;
    surfaceCategories: Record<string, number>;
    selectedCategory: string;
    filteredSurfaces: Surface[];
    currentSurfaces: Surface[];
    unsubscribeFromNewSurfaceId: any;
  } {
    return {
      surfaces: [],
      selectedSurface: "",
      surfaceCategories: {},
      selectedCategory: "",
      filteredSurfaces: [],
      currentSurfaces: [],
      unsubscribeFromNewSurfaceId: {},
    };
  },
  async mounted() {
    if (!this.allSurfaces.length) {
      const surfaces = await backend.getSurfaces();
      if (surfaces) {
        surfaces.unshift(this.createEmptySurface());
        this.setAllSurfaces(surfaces);
      }
    }

    this.selectSurfaceType("Wall");

    if (this.currentSurfaceTypeId) {
      this.showSurfaceById(this.currentSurfaceTypeId);
    }

    this.unsubscribeFromNewSurfaceId = this.$store.subscribe((mutation) => {
      if (mutation.type === setCurrentSurfaceTypeId && mutation.payload) {
        this.showSurfaceById(this.$store.getters.currentSurfaceTypeId);
      }
    });

    this.surfaceDragController.activate();
  },
  methods: {
    ...mapMutations([setCurrentSurfaceTypeId, setAllSurfaces]),
    createEmptySurface(): Surface {
      return {
        id: "-1",
        surface: ["Wall", "Ceiling", "Floor"],
      } as Surface;
    },
    selectSurfaceType(newSurface: string) {
      if (this.selectedSurface === newSurface) return;

      this.selectedSurface = newSurface;
      this.surfaceCategories = {};
      this.filteredSurfaces.splice(0);

      this.allSurfaces.forEach((surface: Surface) => {
        this.surfaces = this.surfaces.concat(surface.surface);

        if (surface.surface.includes(this.selectedSurface)) {
          surface.surface.forEach((s) => {
            if (this.surfaceCategories[s] === undefined) this.surfaceCategories[s] = 1;
            else this.surfaceCategories[s] += 1;
          });
          this.filteredSurfaces.push(surface);
        }
      });

      this.surfaces = [...new Set(this.surfaces)]; // Removes duplicates
    },
    selectCategory(category: string): void {
      if (this.selectedCategory === category) {
        return;
      }

      this.selectedCategory = category;

      this.currentSurfaces = this.filteredSurfaces.filter((surface: Surface) =>
        surface.surface.includes(this.selectedCategory)
      );
    },
    showSurfaceById(id: string) {
      const currentSurface: Surface = this.allSurfaces.find((surface: Surface) => surface.id === id);
      if (!currentSurface) {
        return;
      }

      this.selectSurfaceType(currentSurface.surface[0]);
      if (!currentSurface.surface.includes(this.selectedCategory)) {
        this.selectCategory(currentSurface.surface[0]);
      }
      this.selectSurface(id);
    },
    selectSurface(id: string) {
      if (this.currentSurfaceTypeId === id) {
        return;
      }
      this.setCurrentSurfaceTypeId(id);
      const selectedSurface = this.allSurfaces.find((surface: Surface) => surface.id === id);
      selectedSurface && this.surfaceDragController.setSelectedSurface(selectedSurface);
    },
    nodeIndex(el: Element) {
      let i = 0;
      while (el.previousElementSibling) {
        el = el.previousElementSibling;
        i++;
      }
      return i;
    },
  },
  beforeDestroy() {
    this.surfaceDragController.deactivate();
  },
  computed: {
    ...mapGetters([currentSurfaceTypeId, allSurfaces]),
  },
  destroyed() {
    this.unsubscribeFromNewSurfaceId();
  },
});
