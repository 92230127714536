































import { setIsCurrentlyLoading } from "@/store";
import Vue from "vue";
import { mapActions } from "vuex";
import { apartmentEventDispatcher } from "@/events/apartments";

export default Vue.extend({
  name: "Viewport",
  mounted() {
    apartmentEventDispatcher.showCurrentApartment();
  },
  methods: {
    ...mapActions([setIsCurrentlyLoading]),
    cancel() {
      apartmentEventDispatcher.hideAllApartments();
      this.$router.push(`/${this.$route.params.project}`);
    },
    async save() {
      this.setIsCurrentlyLoading(true);
      this.ifcSceneExporter.uploadCurrentSceneGltf(this.$route.params.project);
      this.setIsCurrentlyLoading(false);
    },
  },
});
