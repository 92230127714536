import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { ThreeJsContext } from "@/three/ThreeJsContext";
import { backend } from "@/lib/backend";
export class SceneExporter {
  private threeJsContext: ThreeJsContext;
  public debugMode = false;

  constructor(threeJsContext: ThreeJsContext, private filename: string = "testExport") {
    this.threeJsContext = threeJsContext;
  }

  public async downloadGltf(filename: string | undefined = undefined): Promise<void> {
    const blobAndFilename = await this.createBlob(filename);

    const link = document.createElement("a");
    link.style.display = "none";
    document.body.appendChild(link);
    link.href = URL.createObjectURL(blobAndFilename.blob);
    link.download = blobAndFilename.fullFilename;
    link.click();
    link.remove();
  }

  public async uploadCurrentSceneGltf(project: string, filename: string | undefined = undefined): Promise<void> {
    const blobAndFilename = await this.createBlob(filename || this.filename);

    const response = backend.postGlb(project, blobAndFilename.blob);
    console.log(response);
  }

  public async createBlob(filename: string | undefined): Promise<{ blob: Blob; fullFilename: string }> {
    const exporter = new GLTFExporter();

    const options = {
      onlyVisible: true,
      truncateDrawRange: false,
      forceIndices: true,
      binary: true,
    };

    return new Promise((resolve) => {
      const allApartments = this.threeJsContext.getApartments();

      allApartments.forEach((apartment) => {
        this.debugMode && apartment.showGizmos(false);
        apartment.createSurfaceMoldings();

        apartment.showOpenings(true);
        apartment.showAssets(true);
        apartment.showSurfaces(true);
        apartment.showMetadata(true);
      });

      const selectedApartments = this.threeJsContext.currentApartments;

      const ifcRoot = this.threeJsContext.ifcRoot;
      if (ifcRoot) {
        const parsePromise = exporter.parseAsync(ifcRoot, options);

        allApartments.forEach((apartment) => {
          apartment.showOpenings(false);
          apartment.showAssets(false);
          apartment.showSurfaces(false);
          apartment.showMetadata(false);
        });

        selectedApartments.forEach((apartment) => {
          apartment.showAssets(true);
          this.debugMode && apartment.showGizmos(true);
          apartment.showSurfaces(true);
        });

        parsePromise.then((result: any) => {
          if (result instanceof ArrayBuffer) {
            resolve({ blob: this.createGlbBuffer(result), fullFilename: (filename || this.filename) + ".glb" });
          } else {
            resolve({ blob: this.createGltfString(result), fullFilename: (filename || this.filename) + ".gltf" });
          }
        });
      }
    });
  }

  private createGltfString(result: any): Blob {
    const text = JSON.stringify(result, null, 2);
    return new Blob([text], { type: "text/plain" });
  }

  private createGlbBuffer(buffer: ArrayBuffer): Blob {
    return new Blob([buffer], { type: "application/octet-stream" });
  }
}
