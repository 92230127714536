import Vue from "vue";
import Vuex from "vuex";
import { AppParameters } from "@/interfaces/AppParameters";
import apartments, { ApartmentsState } from "./apartments";
import assets, { AssetsState } from "./assets";
import surfaces, { SurfacesState } from "./surfaces";
import waypoints, { WaypointsState } from "./waypoints";

export const lod = "lod";
export const debugMode = "debugMode";
export const uvDebug = "uvDebug";
export const threeJsReady = "threeJsReady";
export const isCurrentlyLoading = "isCurrentlyLoading";
export const setLod = "setLod";
export const setDebugMode = "setDebugMode";
export const setUvDebug = "setUvDebug";
export const setThreeJsReady = "setThreeJsReady";
export const setIsCurrentlyLoading = "setIsCurrentlyLoading";

Vue.use(Vuex);

const minLod = 0,
  maxLod = 2;

export interface RootState {
  appParameters: AppParameters;
  threeJsReady: boolean;
  isCurrentlyLoading: boolean;
  // modules...
  assets: AssetsState;
  apartments: ApartmentsState;
  surfaces: SurfacesState;
  waypoints: WaypointsState;
}

const store = new Vuex.Store<RootState>({
  modules: {
    apartments,
    assets,
    surfaces,
    waypoints,
  },
  // Vuex is not ready for full TypeScript support yet... The workarounds are non sensecal
  // https://stackoverflow.com/questions/71187431/how-to-correctly-type-vuex-modules-in-vue-3-and-typescript
  state: {
    appParameters: { lod: 1, debugMode: false, uvDebug: false },
    threeJsReady: false,
  } as RootState,
  getters: {
    [lod]: (state: RootState): number => {
      return state.appParameters.lod;
    },
    [debugMode]: (state: RootState): boolean => {
      return state.appParameters.debugMode;
    },
    [uvDebug]: (state: RootState): boolean => {
      return state.appParameters.uvDebug;
    },
    [threeJsReady]: (state: RootState): boolean => {
      return state.threeJsReady;
    },
    [isCurrentlyLoading]: (state: RootState): boolean => {
      return state.isCurrentlyLoading;
    },
  },
  mutations: {
    [setLod]: (state: RootState, payload: number): void => {
      payload = Math.min(Math.max(payload, minLod), maxLod);
      state.appParameters.lod = payload;
    },
    [setDebugMode]: (state: RootState, payload: boolean): void => {
      state.appParameters.debugMode = payload;
    },
    [setUvDebug]: (state: RootState, payload: boolean): void => {
      state.appParameters.uvDebug = payload;
    },
    [setThreeJsReady]: (state: RootState, payload: boolean): void => {
      state.threeJsReady = payload;
    },
    [setIsCurrentlyLoading]: (state: RootState, payload: boolean): void => {
      state.isCurrentlyLoading = payload;
    },
  },
  actions: {},
});

export default store;
declare global {
  interface Window {
    setDebugMode: (value: boolean) => string;
    setUvDebug: (value: boolean) => string;
    setAssetLod: (value: number) => string;
    getCurrentCameraTransform: () => any;
    setCurrentCameraTransform: (args: any) => void;
    inc: () => void;
    dec: () => void;
  }
}

window.setDebugMode = (value: boolean) => {
  store.commit(setDebugMode, value);
  return `Have set debug mode to ${value}`;
};

window.setUvDebug = (value: boolean) => {
  store.commit(setUvDebug, value);
  return `Have set uv debug to ${value}`;
};

window.setAssetLod = (value: number) => {
  value = Math.min(Math.max(value, minLod), maxLod);
  store.commit(setLod, value);
  return `Have set asset lod to ${value}`;
};
