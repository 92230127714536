var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"add-menu"},[_c('div',{staticClass:"rolled-out-buttons-container"},[_c('div',{class:_vm.isOpen ? 'open-list' : 'closed-list'},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.$router.push(("/" + (_vm.$route.params.project) + "/" + (_vm.$route.params.apartment) + "/file-upload"))}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-upload ")])],1)]}}])},[_c('span',[_vm._v("Upload scene")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.downloadScene()}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-download ")])],1)]}}])},[_c('span',[_vm._v("Download scene")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.$router.push(("/" + (_vm.$route.params.project) + "/" + (_vm.$route.params.apartment) + "/cameras"))}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-camera-outline ")])],1)]}}])},[_c('span',[_vm._v("Cameras")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",attrs:{"disabled":""}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-image-area ")])],1)]}}])},[_c('span',[_vm._v("Detailing")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.$router.push(("/" + (_vm.$route.params.project) + "/" + (_vm.$route.params.apartment) + "/assets"))}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-sofa-single-outline ")])],1)]}}])},[_c('span',[_vm._v("Furniture")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"button",on:{"click":function($event){return _vm.$router.push(("/" + (_vm.$route.params.project) + "/" + (_vm.$route.params.apartment) + "/surfaces"))}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-texture-box ")])],1)]}}])},[_c('span',[_vm._v("Surfaces")])])],1)]),_c('button',{staticClass:"button menu-button",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('div',{staticClass:"menu-plus-sign"},[_vm._v("+")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }