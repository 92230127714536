import { Mesh, Box3 } from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";

// TODO: Add namespace to extension methods
declare module "three/src/objects/Mesh.js" {
  interface Mesh {
    // TODO: Remove legacy user properties
    userData: {
      /** gizmo arrows that let the user move this mesh around the scene*/
      gizmo?: TransformControls;
      /** Is this mesh an IFC room space*/
      isRoomSpace?: boolean;
    };

    getCurrentWorldBounds: (this: Mesh) => Box3;
  }
}

Mesh.prototype.getCurrentWorldBounds = function (this: Mesh): Box3 {
  this.updateMatrixWorld();
  this.geometry.computeBoundingBox();
  const abb = this.geometry.boundingBox!.clone();
  abb.applyMatrix4(this.matrixWorld);

  return abb;
};
