





























import Vue from "vue";
import ThumbnailGallery from "../components/ThumbnailGallery.vue";
import { Asset } from "@/interfaces/Asset";
import { mapGetters, mapMutations } from "vuex";
import { allAssets, currentAssetTypeId, setAllAssets, setCurrentAssetTypeId } from "@/store/assets";
import { backend } from "@/lib/backend";

export default Vue.extend({
  name: "AssetBrowser",
  components: {
    ThumbnailGallery,
  },
  data(): {
    rooms: string[];
    selectedRoom: string;
    assetCategories: Record<string, number>;
    selectedCategory: string;
    filteredAssets: Asset[];
    currentAssets: Asset[];
    unsubscribeFromNewAssetId: any;
  } {
    return {
      rooms: [],
      selectedRoom: "",
      assetCategories: {},
      selectedCategory: "",
      filteredAssets: [],
      currentAssets: [],
      unsubscribeFromNewAssetId: {},
    };
  },
  async mounted() {
    if (!this.allAssets.length) {
      const assets = await backend.getAssets();
      assets && this.setAllAssets(assets);
    }

    this.selectRoom("Generic");

    if (this.currentAssetTypeId) {
      this.showAssetById(this.currentAssetTypeId);
    }

    this.unsubscribeFromNewAssetId = this.$store.subscribe((mutation) => {
      if (mutation.type === setCurrentAssetTypeId && mutation.payload) {
        this.showAssetById(this.$store.getters.currentAssetTypeId);
      }
    });
    this.assetDragController.activate();
  },
  methods: {
    ...mapMutations([setCurrentAssetTypeId, setAllAssets]),
    selectRoom(newRoom: string) {
      if (this.selectedRoom === newRoom) return;

      this.selectedRoom = newRoom;
      this.assetCategories = {};
      this.filteredAssets.splice(0);

      this.allAssets.forEach((asset: Asset) => {
        this.rooms = this.rooms.concat(asset.possibleRooms);

        if (asset.possibleRooms.includes(this.selectedRoom)) {
          asset.categories.forEach((category) => {
            if (this.assetCategories[category] === undefined) this.assetCategories[category] = 1;
            else this.assetCategories[category] += 1;
          });
          this.filteredAssets.push(asset);
        }
      });

      this.rooms = [...new Set(this.rooms)]; // Removes duplicates
    },
    selectCategory(category: string): void {
      if (this.selectedCategory === category) return;

      this.selectedCategory = category;

      this.currentAssets = this.filteredAssets.filter((asset: Asset) => asset.categories.includes(this.selectedCategory));
    },
    showAssetById(id: string) {
      const currentAsset = this.allAssets.find((asset: Asset) => asset.id === id);
      if (!currentAsset) return;

      this.selectRoom(currentAsset.possibleRooms[0]);
      this.selectCategory(currentAsset.categories[0]);
      this.selectAsset(id);
    },
    selectAsset(id: string) {
      if (this.currentAssetTypeId === id) return;
      this.setCurrentAssetTypeId(id);

      const selectedAsset = this.allAssets.find((asset: Asset) => asset.id === id);

      selectedAsset && this.assetDragController.replaceSelectedAsset(selectedAsset, this.currentAssetTypeId);
    },
    dragStart(event: DragEvent) {
      event.stopPropagation();

      const elements = event.composedPath() as any;
      if (elements?.length > 1) {
        const listIndex = this.nodeIndex(elements[1]);
        const asset = this.currentAssets[listIndex];
        asset && this.assetDragController.setSelectedAsset(asset);
      }
    },
    nodeIndex(el: Element) {
      let i = 0;
      while (el.previousElementSibling) {
        el = el.previousElementSibling;
        i++;
      }
      return i;
    },
  },
  computed: {
    ...mapGetters([currentAssetTypeId, allAssets]),
  },
  beforeDestroy() {
    this.assetDragController.deactivate();
  },
  destroyed() {
    this.unsubscribeFromNewAssetId();
  },
});
