import Vue from "vue";
import Vuetify from "vuetify";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/three";
import { initializeBackend } from "./lib/backend";
import Keycloak from "keycloak-js";
import createAxiosInstance from "./lib/axios";
import QiSpaceVueComponents from "@qispace/vue-components";
import QiSpaceVuePlayer from "@qispace/vue-floorplan-editor";
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false;

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});
Vue.use(QiSpaceVueComponents);
Vue.use(QiSpaceVuePlayer);

const projAuthSettings = {
  realm: process.env.VUE_APP_BASE_AUTH,
  url: "https://accounts.qispace.com/",
  clientId: "web-builder",
  "public-client": true,
  "confidential-port": 0,
};

const assetAuthSettings = {
  realm: process.env.VUE_APP_ASSET_AUTH,
  url: "https://accounts.qispace.com/",
  clientId: "web-builder",
  "public-client": true,
  "confidential-port": 0,
};

const projectKeycloak = new Keycloak(projAuthSettings);
const assetKeycloak = new Keycloak(assetAuthSettings);

projectKeycloak
  .init({ onLoad: "login-required" })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      const projectAxios = createAxiosInstance(process.env.VUE_APP_BASE_URL, projectKeycloak);
      const assetAxios = createAxiosInstance(process.env.VUE_APP_ASSET_URL, assetKeycloak);
      initializeBackend(projectAxios, assetAxios);

      new Vue({
        store,
        vuetify,
        router,
        render: (h) => h(App),
      }).$mount("#app");
    }
  })
  .catch((error) => {
    console.error("Authentication Failed", error);
  });
