import { Vector2, MathUtils, Matrix3 } from "three";

const _startP = /*@__PURE__*/ new Vector2();
const _startEnd = /*@__PURE__*/ new Vector2();

const red = 0xff0000 * 2.2;
const green = 0x00ff00 * 2.2;
const blue = 0x0000ff * 2.2;

const debugColors = [red, green, blue];
let currentColorIndex = 0;
let currentOffset = 0;

export const MERGE_TOLERANCE = 0.0000001;

export class Line2 {
  public start: Vector2;
  public end: Vector2;

  constructor(start = new Vector2(), end = new Vector2()) {
    this.start = start;
    this.end = end;
  }

  public static convertPointsToLines(points: Vector2[]): Line2[] {
    points = points.filter((point, i) => {
      const nextIndex = (i + 1) % points.length;
      const sqDistance = point.distanceToSquared(points[nextIndex]);
      return sqDistance > MERGE_TOLERANCE;
    });

    let lines: Line2[] = [];
    for (let i = 0; i < points.length; i++) {
      const start = points[i];
      const end = points[(i + 1) % points.length]; // Wrap around to the first point

      lines.push(new Line2(start, end));
    }

    return lines;
  }

  public static convertLinesToPoints(lines: Line2[]): Vector2[] {
    return lines.map((line) => line.start);
  }

  public static getIntersectingLine(point: Vector2, lines: Line2[]): Line2 | undefined {
    return lines.find((edge) => {
      return edge.intersectsPoint(point);
    });
  }

  public intersectsPoint(point: Vector2): boolean {
    const startToCorner = new Vector2().subVectors(this.start, point).length();
    const cornerToEnd = new Vector2().subVectors(point, this.end).length();
    const startToEnd = new Vector2().subVectors(this.start, this.end).length();

    const difference = Math.abs(startToCorner + cornerToEnd - startToEnd);
    return difference < MERGE_TOLERANCE;
  }

  private _isNearlyCollinear(otherLine: Line2, epsilon: number, index: number): boolean {
    let vectorA = this.end.clone().sub(this.start);
    vectorA.normalize();

    let vectorB = otherLine.end.clone().sub(otherLine.start);
    vectorB.normalize();

    // Calculate dot product
    const dot = vectorA.dot(vectorB);
    // Check if the dot product is close to 1, indicating nearly collinear
    return Math.abs(dot - 1) < epsilon;
  }

  public static adjustSegmentsBasedOnAngle(lines: Line2[], epsilon: number): Line2[] {
    lines.forEach((currentLine, i) => {
      const nextIndex = (i + 1) % lines.length;
      const nextLine = lines[nextIndex];

      if (currentLine._isNearlyCollinear(nextLine, epsilon, i)) {
        // If nearly collinear, adjust the end point of the current line
        currentLine.end = nextLine.start.clone();
      }
    });

    // Eliminate redundant segments created by adjustments
    lines = lines.filter((line, i) => {
      const nextIndex = (i + 1) % lines.length;
      const sqDistance = line.start.distanceToSquared(lines[nextIndex].end);
      return sqDistance > MERGE_TOLERANCE;
    });

    return lines;
  }

  public static offsetBounds(point: Vector2, bounds: Line2[], offsetFromOutside = false): Vector2 | undefined {
    if (!bounds || !bounds.length) return;

    let closestDistance = Number.MAX_VALUE;
    let offset = new Vector2();
    let isWithinBounds = offsetFromOutside;
    const scratchVector = new Vector2();

    bounds.forEach((edge) => {
      let edgeDx = edge.start.x - edge.end.x;
      let edgeDy = edge.start.y - edge.end.y;

      const intersection = edge.closestPointToPoint(point);

      const distance = point.distanceToSquared(intersection);
      if (distance < closestDistance) {
        closestDistance = distance;
        offset = scratchVector.subVectors(point, intersection);
      }

      if (Math.abs(edgeDy) > Number.EPSILON) {
        let high: Vector2;
        let low: Vector2;

        if (edgeDy < 0) {
          // not parallel. Flip high and low point
          high = edge.start;
          edgeDx = -edgeDx;
          low = edge.end;
          edgeDy = -edgeDy;
        } else {
          high = edge.end;
          low = edge.start;
        }

        if (point.y < high.y || point.y > low.y) return;

        if (point.y !== high.y) {
          const perpendicularEdge = edgeDy * (point.x - high.x) - edgeDx * (point.y - high.y);

          if (perpendicularEdge < 0) return;
          isWithinBounds = !isWithinBounds; // true intersection left of point
        }
      }
    });

    return isWithinBounds ? undefined : offset;
  }

  set(start: Vector2, end: Vector2): Line2 {
    this.start.copy(start);
    this.end.copy(end);

    return this;
  }

  copy(line: Line2): Line2 {
    this.start.copy(line.start);
    this.end.copy(line.end);

    return this;
  }

  getCenter(target: Vector2): Vector2 {
    return target.addVectors(this.start, this.end).multiplyScalar(0.5);
  }

  delta(target: Vector2): Vector2 {
    return target.subVectors(this.end, this.start);
  }

  distanceSq(): number {
    return this.start.distanceToSquared(this.end);
  }

  distance(): number {
    return this.start.distanceTo(this.end);
  }

  at(t: number, target: Vector2) {
    return this.delta(target).multiplyScalar(t).add(this.start);
  }

  closestPointToPointParameter(point: Vector2, clampToLine: boolean): number {
    _startP.subVectors(point, this.start);
    _startEnd.subVectors(this.end, this.start);

    const startEnd2 = _startEnd.dot(_startEnd);
    const startEnd_startP = _startEnd.dot(_startP);

    let t = startEnd_startP / startEnd2;

    if (clampToLine) {
      t = MathUtils.clamp(t, 0, 1);
    }

    return t;
  }

  closestPointToPoint(point: Vector2, clampToLine = true, target = new Vector2()): Vector2 {
    const t = this.closestPointToPointParameter(point, clampToLine);

    return this.delta(target).multiplyScalar(t).add(this.start);
  }

  applyMatrix4(matrix: Matrix3): Line2 {
    this.start.applyMatrix3(matrix);
    this.end.applyMatrix3(matrix);

    return this;
  }

  equals(line: Line2) {
    return line.start.equals(this.start) && line.end.equals(this.end);
  }

  clone() {
    return new Line2().copy(this);
  }
}
