



















import Vue from "vue";
import { mapGetters } from "vuex";
import { apartments } from "@/store/apartments";

export default Vue.extend({
    name: "ApartmentsTable",
    data(): {
        selectedUnitTitle: string;
    } {
        return {
            selectedUnitTitle: "",
        };
    },
    computed: {
        ...mapGetters([apartments]),
    },
    methods: {
        apartmentSelected: function (apartmentTitle: string) {
            this.selectedUnitTitle = apartmentTitle;
            this.$emit("selectApartment", apartmentTitle);
        },
    },
});
