import { PerspectiveCamera, Raycaster, Vector2, Vector3, Object3D, Intersection, Mesh } from "three";
import { ThreeJsContext } from "../ThreeJsContext";
import { WaypointGizmo } from "../helpers/WaypointGizmo";
import { PlanarSurface, SURFACE_TYPE } from "../objects/PlanarSurface";

export class WaypointDragController {
  public onSelectOn: (() => void) | undefined;
  public onSelectOff: (() => void) | undefined;
  private enabled = false;
  private raycaster = new Raycaster();
  private draggableWaypoints: WaypointGizmo[] = [];
  private selectedCameraWaypoint: WaypointGizmo | undefined;
  private hoveredCameraWaypoint: WaypointGizmo | undefined;
  private draggedCameraWaypoint: WaypointGizmo | undefined;
  private intersections: Intersection<Object3D>[] = [];
  private pointer: Vector2 = new Vector2();
  private threeJsContext: ThreeJsContext;
  private isClick = false;
  private onPointerMoveFactory: (event: PointerEvent) => void;
  private onPointerDownFactory: (event: PointerEvent) => void;
  private onPointerUpFactory: (event: PointerEvent) => void;
  private onPointerCancelFactory: (event: PointerEvent) => void;

  constructor(threeJsContext: ThreeJsContext) {
    this.threeJsContext = threeJsContext;
    this.onPointerMoveFactory = (event: PointerEvent) => {
      this.onPointerMove(event);
    };
    this.onPointerDownFactory = (event: PointerEvent) => {
      this.onPointerDown(event);
    };
    this.onPointerUpFactory = (event: PointerEvent) => {
      this.onPointerUp(event);
      this.onPointerCancel();
    };
    this.onPointerCancelFactory = () => {
      this.onPointerCancel();
    };
  }

  public unselectCameraWaypoint() {
    this.selectedCameraWaypoint?.setSelectColoration(false);
    this.selectedCameraWaypoint = undefined;
  }

  public placeNewCamera(position: Vector3 | undefined = undefined) {
    const currentApartments = this.threeJsContext.currentApartments;
    // if (currentApartment) {
    //   const camera = currentApartment.placeCameraInApartment(position);
    //   const waypointGizmo = new WaypointGizmo(camera, this.threeJsContext);
    //   this.draggableWaypoints.push(waypointGizmo);
    //   this.selectOn(waypointGizmo);
    //   this.dragStart(waypointGizmo);
    //   this.threeJsContext.domElement.style.cursor = "move";
    // }
  }

  public snapSelectedCameraToView() {
    const selectedCamera = this.selectedCameraWaypoint?.camera;
    if (selectedCamera) {
      selectedCamera.position.copy(this.threeJsContext.camera.position);
      selectedCamera.rotation.copy(this.threeJsContext.camera.rotation);
      //this.selectedCameraWaypoint?.placeUnderCamera(this.threeJsContext.currentApartments);
    }
  }

  public removeSelectedCamera() {
    if (this.selectedCameraWaypoint) {
      this.draggableWaypoints.splice(this.draggableWaypoints.indexOf(this.selectedCameraWaypoint), 1);
      this.selectedCameraWaypoint.camera.removeFromParent();
      this.selectedCameraWaypoint.dispose();
      this.hoveredCameraWaypoint = undefined;
      this.selectedCameraWaypoint = undefined;
    }
  }

  public activate() {
    const currentApts = this.threeJsContext.currentApartments;
    if (currentApts) {
      this.draggableWaypoints.length = 0;
      // currentApt.camerasNode.children.forEach((child) => {
      //   const camera = child as PerspectiveCamera;
      //   this.draggableWaypoints.push(new WaypointGizmo(camera, this.threeJsContext));
      // });
    }
    this.threeJsContext.domElement.addEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.addEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.addEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.addEventListener("pointerleave", this.onPointerCancelFactory);
    this.enabled = true;
  }

  public deactivate() {
    this.threeJsContext.domElement.style.cursor = "auto";
    this.draggableWaypoints.forEach((child) => child.dispose());
    this.draggableWaypoints.length = 0;
    this.threeJsContext.domElement.removeEventListener("pointermove", this.onPointerMoveFactory);
    this.threeJsContext.domElement.removeEventListener("pointerdown", this.onPointerDownFactory);
    this.threeJsContext.domElement.removeEventListener("pointerup", this.onPointerUpFactory);
    this.threeJsContext.domElement.removeEventListener("pointerleave", this.onPointerCancelFactory);
    this.enabled = false;
  }

  private updatePointer(event: PointerEvent): Vector2 {
    const rect = this.threeJsContext.domElement.getBoundingClientRect();

    const oldX = this.pointer.x;
    const oldY = this.pointer.y;

    this.pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.pointer.y = (-(event.clientY - rect.top) / rect.height) * 2 + 1;

    return new Vector2(this.pointer.x - oldX, this.pointer.y - oldY);
  }

  private hoverOn(hovered: WaypointGizmo): void {
    this.threeJsContext.domElement.style.cursor = "pointer";

    if (this.selectedCameraWaypoint != hovered) {
      hovered.setHoverColoration(true);
    }

    this.hoveredCameraWaypoint = hovered;
  }

  private hoverOff(): void {
    this.threeJsContext.domElement.style.cursor = "auto";
    if (this.selectedCameraWaypoint !== this.hoveredCameraWaypoint) {
      this.hoveredCameraWaypoint?.setHoverColoration(false);
    }
    this.hoveredCameraWaypoint = undefined;
  }

  private selectOn(gizmo: WaypointGizmo): void {
    this.selectedCameraWaypoint?.setSelectColoration(false);
    gizmo.setSelectColoration(true);
    this.selectedCameraWaypoint = gizmo;
    this.onSelectOn && this.onSelectOn();
  }

  private selectOff(): void {
    this.onSelectOff && this.onSelectOff();
  }

  private dragStart(gizmo: WaypointGizmo): void {
    this.draggedCameraWaypoint = gizmo;
    this.threeJsContext.orbitControls.enabled = false;
  }
  private dragEnd(): void {
    this.threeJsContext.orbitControls.enabled = true;
    this.draggedCameraWaypoint = undefined;
    this.threeJsContext.domElement.style.cursor = this.hoveredCameraWaypoint ? "pointer" : "auto";
  }

  private onPointerDown(event: PointerEvent): void {
    if (!this.enabled || event.button !== 0) return;
    this.isClick = true;
    this.updatePointer(event);
    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.raycaster.intersectObjects(this.getIntersectableMeshes(), false, this.intersections);

    if (this.intersections.length > 0) {
      const object = this.intersections[0]?.object;
      const gizmo = object?.type === "WaypointGizmo" ? (object as WaypointGizmo) : undefined;
      if (gizmo) {
        this.selectedCameraWaypoint !== gizmo && this.selectOn(gizmo);
        this.dragStart(gizmo);
      }
    }
  }

  private onPointerUp(event: PointerEvent): void {
    if (!this.enabled || event.button !== 0) return;
    if (!this.isClick) return;
    this.updatePointer(event);
    this.intersections.length = 0;
    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.raycaster.intersectObjects(this.getIntersectableMeshes(), false, this.intersections);

    if (this.intersections.length > 0) {
      const object = this.intersections[0]?.object;
      if (object?.type !== "WaypointGizmo") {
        this.selectOff();
      }
    }
  }

  private onPointerMove(event: PointerEvent): void {
    if (!this.enabled) return;

    const delta = this.updatePointer(event);
    if (Math.abs(delta.x) > 0.003 || Math.abs(delta.y) > 0.003) this.isClick = false;

    if (this.draggedCameraWaypoint) {
      const apts = this.threeJsContext.currentApartments;
      const surfaces = apts.flatMap((apt) => apt.surfaces);
      const floors = surfaces.filter((surface) => surface.surfaceType === SURFACE_TYPE.floor);
      this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
      this.intersections.length = 0;
      this.raycaster.intersectObjects(floors, false, this.intersections);

      if (this.intersections.length > 0 && this.draggedCameraWaypoint) {
        this.threeJsContext.domElement.style.cursor = "move";
        this.draggedCameraWaypoint.moveTo(this.intersections[0].point);
      }
      return;
    }

    this.raycaster.setFromCamera(this.pointer, this.threeJsContext.camera);
    this.intersections.length = 0;
    this.raycaster.intersectObjects(this.getIntersectableMeshes(), false, this.intersections);
    if (this.intersections.length > 0) {
      const object = this.intersections[0]?.object;
      const gizmo = object?.type === "WaypointGizmo" ? (object as WaypointGizmo) : undefined;

      if (this.hoveredCameraWaypoint !== gizmo) {
        this.hoverOff();
        gizmo && this.hoverOn(gizmo);
      }
    }
  }

  private onPointerCancel() {
    if (!this.enabled) return;
    if (this.draggedCameraWaypoint) {
      this.dragEnd();
    }
  }

  private getIntersectableMeshes(): Mesh[] {
    const apts = this.threeJsContext.currentApartments;
    const surfaces = apts.flatMap((apt) => apt.surfaces);
    const floors = surfaces.filter((surface) => surface.surfaceType === SURFACE_TYPE.floor).map((surface) => surface);

    return (this.draggableWaypoints as Mesh[]).concat(floors);
  }
}
