import { Vector3, Triangle, Matrix4 } from "three";

export class IndexedTriangle extends Triangle {
  public aIndex: number;
  public bIndex: number;
  public cIndex: number;

  constructor(a: Vector3, b: Vector3, c: Vector3, aIndex = 0, bIndex = 1, cIndex = 2) {
    super(a, b, c);

    this.aIndex = aIndex;
    this.bIndex = bIndex;
    this.cIndex = cIndex;
  }

  public getIndices(): number[] {
    return [this.aIndex, this.bIndex, this.cIndex];
  }

  public getIndicesString(): string {
    return `${this.aIndex},${this.bIndex},${this.cIndex}`;
  }

  public static fromPositionsAndIndices(positions: Float32Array, indices: number[]): IndexedTriangle {
    const aIndex = indices[0];
    const bIndex = indices[1];
    const cIndex = indices[2];

    const a = new Vector3().fromArray(positions, aIndex * 3);
    const b = new Vector3().fromArray(positions, bIndex * 3);
    const c = new Vector3().fromArray(positions, cIndex * 3);

    return new IndexedTriangle(a, b, c, aIndex, bIndex, cIndex);
  }

  public fromPositionsAndIndices(positions: Float32Array, indices: number[]): IndexedTriangle {
    this.aIndex = indices[0];
    this.bIndex = indices[1];
    this.cIndex = indices[2];

    this.a.fromArray(positions, this.aIndex * 3);
    this.b.fromArray(positions, this.bIndex * 3);
    this.c.fromArray(positions, this.cIndex * 3);

    return this;
  }

  public fromPositionsAndIndex(positions: Float32Array, aIndex: number, bIndex: number, cIndex: number): IndexedTriangle {
    this.aIndex = aIndex;
    this.bIndex = bIndex;
    this.cIndex = cIndex;

    this.a.fromArray(positions, aIndex * 3);
    this.b.fromArray(positions, bIndex * 3);
    this.c.fromArray(positions, cIndex * 3);

    return this;
  }

  public applyMatrix4(matrix: Matrix4): void {
    this.a.applyMatrix4(matrix);
    this.b.applyMatrix4(matrix);
    this.c.applyMatrix4(matrix);
  }

  public clone(): any {
    const newTriangle = new IndexedTriangle(new Vector3(), new Vector3(), new Vector3(), 0, 0, 0);
    newTriangle.copy(this);
    return newTriangle;
  }

  public isNeighbor(triangle: IndexedTriangle): boolean {
    if (this.aIndex === triangle.aIndex || this.aIndex === triangle.bIndex || this.aIndex === triangle.cIndex) {
      return true;
    } else if (this.bIndex === triangle.aIndex || this.bIndex === triangle.bIndex || this.bIndex === triangle.cIndex) {
      return true;
    } else if (this.cIndex === triangle.aIndex || this.cIndex === triangle.bIndex || this.cIndex === triangle.cIndex) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line
  public copy(triangle: IndexedTriangle) {
    this.a.copy(triangle.a);
    this.b.copy(triangle.b);
    this.c.copy(triangle.c);

    this.aIndex = triangle.aIndex;
    this.bIndex = triangle.bIndex;
    this.cIndex = triangle.cIndex;

    return this;
  }
}
