import { Vector3, BufferGeometry, Box3 } from "three";

declare module "three/src/core/BufferGeometry.js" {
  interface BufferGeometry {
    getCurrentWorldBounds: (this: BufferGeometry) => Box3;
  }
}

BufferGeometry.prototype.getCurrentWorldBounds = function (this: BufferGeometry): Box3 {
  let output: Box3 = new Box3(new Vector3(), new Vector3());

  if (this.boundingBox === null) {
    this.boundingBox = new Box3();
  }

  if (!this.boundingBox) this.computeBoundingBox();

  output = this.boundingBox!.clone();
  return output;
};
