import { Module } from "vuex";
import { RootState } from ".";

export const isAWaypointSelected = "isAWaypointSelected";
export const setIsAWaypointSelected = "setIsAWaypointSelected";

export interface WaypointsState {
  isAWaypointSelected: boolean;
}

const waypoints: Module<WaypointsState, RootState> = {
  state: {
    isAWaypointSelected: false,
  },
  getters: {
    [isAWaypointSelected]: (state: WaypointsState): boolean => {
      return state.isAWaypointSelected;
    },
  },
  mutations: {
    [setIsAWaypointSelected]: (state: WaypointsState, payload: boolean): void => {
      state.isAWaypointSelected = payload;
    },
  },
  actions: {},
  modules: {},
};

export default waypoints;
